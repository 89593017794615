import type { ChartOptions } from 'chart.js';
import { defaultTheme } from 'styles/theme';
import { formatGraphAxisValues } from 'utils/formatGraphAxisValues';

export const options: ChartOptions<'line'> = {
  aspectRatio: 2.7,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'point',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        circular: true,
        display: true,
        drawTicks: true,
        lineWidth: 1,
        tickBorderDash: [0, 1],
        color: defaultTheme.mainTheme.color.blue20,
      },
      border: {
        display: true,
        color: defaultTheme.mainTheme.color.gray40,
        width: 1,
        dash: [0, 4, 4],
        dashOffset: 1,
      },
      ticks: {
        color: defaultTheme.mainTheme.color.gray40,
        font: {
          family: defaultTheme.font.family,
          size: 12,
          weight: '500',
        },
      },
    },
    y: {
      grid: {
        circular: true,
        display: true,
        drawTicks: true,
        lineWidth: 1,
        tickBorderDash: [0, 1, 2],
        color: defaultTheme.mainTheme.color.blue20,
      },
      border: {
        display: true,
        width: 0,
        dash: [0, 4, 4],
        dashOffset: 1,
      },
      ticks: {
        maxTicksLimit: 6,
        display: true,
        backdropColor: defaultTheme.mainTheme.color.blue50,
        callback(value) {
          return formatGraphAxisValues(value);
        },
        color: defaultTheme.mainTheme.color.gray40,
        font: {
          family: defaultTheme.font.family,
          size: 12,
          weight: '500',
        },
      },
    },
  },
  elements: {
    point: {
      radius: 5,
      borderWidth: 2,
      borderColor: '#fff',
      hoverRadius: 6,
      hoverBorderWidth: 2,
      hoverBorderColor: '#fff',
    },
  },
};
