import * as yup from 'yup';

const formErrorTexts = {
  password: {
    min: 'The password must be more than 7 characters',
    matches: 'Password must contain at least 8 symbols with one lowercase letter, one uppercase letter and one number',
    required: 'Enter your password',
  },
  repeatPassword: {
    notOneOf: 'The new password should not be similar to the old one',
    oneOf: 'Passwords must match',
    required: 'Repeat your new password',
  },
  email: {
    email: 'Invalid email',
    required: 'Enter your email',
  },
  firstName: {
    matches: 'Invalid first name',
    required: 'Enter your first name',
  },
  lastName: {
    matches: 'Invalid second name',
    required: 'Enter your second name',
  },
  code: {
    min: 'The code must be 4 numerals long',
    matches: 'The code must consist only of numerals',
    required: 'Enter code',
  },
  companyName: {
    required: 'Enter company name',
  },
};

export const signInSchema = yup.object().shape({
  password: yup.string().required(formErrorTexts.password.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
  agreement: yup.boolean(),
});

export const signUpSchema = yup.object().shape({
  firstName: yup.string()
    .matches(/[a-zA-Z]/, formErrorTexts.firstName.matches)
    .required(formErrorTexts.firstName.required),
  lastName: yup.string()
    .matches(/[a-zA-Z]/, formErrorTexts.lastName.matches)
    .required(formErrorTexts.lastName.required),
  password: yup.string()
    .min(8, formErrorTexts.password.min)
    .matches(/[A-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9]{8,}$/, formErrorTexts.password.matches)
    .required(formErrorTexts.password.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
  isHasCompanyName: yup.boolean(),
  companyName: yup.string().when(
    'isHasCompanyName', {
      is: true,
      then(schema) { return schema.required(formErrorTexts.companyName.required); },
    },
  ),
});

export const changeInfoSchema = yup.object().shape({
  firstName: yup.string()
    .matches(/[a-zA-Z]/, formErrorTexts.firstName.matches)
    .required(formErrorTexts.firstName.required),
  lastName: yup.string()
    .matches(/[a-zA-Z]/, formErrorTexts.lastName.matches)
    .required(formErrorTexts.lastName.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
});

export const forgotPassSchema = yup.object().shape({
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
});

export const resetPassSchema = yup.object().shape({
  code: yup.string()
    .matches(/[0-9]/, formErrorTexts.code.matches)
    .min(4, formErrorTexts.code.min)
    .max(4, formErrorTexts.code.min)
    .required(formErrorTexts.code.required),
  email: yup.string().email(formErrorTexts.email.email).required(formErrorTexts.email.required),
});

export const changePassByCodeSchema = yup.object().shape({
  token: yup.string().required(),
  password: yup
    .string()
    .required(formErrorTexts.password.required)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_]{8,}$/, formErrorTexts.password.matches)
    .min(8, formErrorTexts.password.min),
  repeatPassword: yup
    .string()
    .oneOf([yup.ref('password')], formErrorTexts.repeatPassword.oneOf)
    .required(formErrorTexts.repeatPassword.required),
});

export const changeOldPasswordSchema = yup.object().shape({
  currentPassword: yup
    .string()
    .required(formErrorTexts.password.required)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_]{8,}$/, formErrorTexts.password.matches)
    .min(8, formErrorTexts.password.min),
  newPassword: yup
    .string()
    .required(formErrorTexts.password.required)
    .matches(/[a-zA-Z]/, formErrorTexts.password.matches)
    .matches(/[0-9]/, formErrorTexts.password.matches)
    .matches(/^[a-zA-Z0-9-_]{8,}$/, formErrorTexts.password.matches)
    .min(8, formErrorTexts.password.min)
    .notOneOf([yup.ref('currentPassword')], formErrorTexts.repeatPassword.notOneOf),
  repeatNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], formErrorTexts.repeatPassword.oneOf)
    .required(formErrorTexts.repeatPassword.required),
});
