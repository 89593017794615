import { NameFilterENUM, TypeFilterENUM } from 'types/filtersType';
import CustomModal from 'components/CustomModal/CustomModal';
import Button from 'components/Button';
import useCheckMatchApplyedAndSavedFilters from 'utils/useCheckMatchApplyedAndSavedFilters';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setAppliedFilters,
  setRequestSentFilters,
} from 'store/filter/filterSlice';

import type { SectionFilterType } from '../FilterSection/FilterSection';
import FilterSection from '../FilterSection/FilterSection';
import PriceRangeFields from '../PriceRange/components/PriceRangeFields/PriceRangeFields';
import DateRangeFields from '../DateRange/DateRangeFields/DateRangeFields';
import StyledModalBody from './FiltersModal.styles';

const mainSkillsArray: SectionFilterType[] = [
  {
    title: 'Include Skills',
    section: NameFilterENUM.SKILLS,
    type: TypeFilterENUM.ONLY,
    hintText: 'When using the include filter, a selection of data will be displayed only for the selected skills; when using exclude, the selected skills will be excluded from the selection.',
  },
  {
    title: 'Exclude Skills',
    section: NameFilterENUM.SKILLS,
    type: TypeFilterENUM.EXCLUDE,
  },
  {
    title: 'Include Regions',
    section: NameFilterENUM.REGIONS,
    type: TypeFilterENUM.ONLY,
    hintText: ' When using include, statistics for countries included in this region (or selected countries) will be displayed in the selection; when using exclude for regions, all countries included in this region will be removed from the selection (or selected countries are excluded) !!! Be careful, filters may be mutually exclusive !!!',
  },
  {
    title: 'Exclude Regions',
    section: NameFilterENUM.REGIONS,
    type: TypeFilterENUM.EXCLUDE,
  },
  {
    title: 'Include Countries',
    section: NameFilterENUM.COUNTRIES,
    type: TypeFilterENUM.ONLY,
  },
  {
    title: 'Exclude Countries',
    section: NameFilterENUM.COUNTRIES,
    type: TypeFilterENUM.EXCLUDE,
  },
];

type PropsType = {
  open: boolean;
  onClose: () => void;
};

const FiltersModal:React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();

  const skillsFilters = mainSkillsArray.slice(0, 2);
  const regionsFilters = mainSkillsArray.slice(2, 4);
  const countriesFilters = mainSkillsArray.slice(4, 6);
  const isApplied = useAppSelector((state) => state.filterSlice.isApplied);
  const checkMatchApplyedAndSavedFilters = useCheckMatchApplyedAndSavedFilters();
  const handleApplyFilters = async () => {
    checkMatchApplyedAndSavedFilters();
    dispatch(setAppliedFilters());
    dispatch(setRequestSentFilters(true));
  };
  // TODO where and how we should to clear filters?
  return (
    <CustomModal
      open={props.open}
      onClose={props.onClose}
      isBorderRounded
    >
      <StyledModalBody>
        <div className="filters-modal__header-wrapper">
          <h3 className="filters-modal__title">Filters</h3>
          <div className="filters-modal__buttons-wrapper">
          <Button
            variant="contained"
            className="filters-modal__apply-btn"
            disabled={isApplied}
            onClick={handleApplyFilters}
          >
            Apply filters
          </Button>

          <Button
            variant="outlined"
            className="filters-modal__close-btn"
            onClick={props.onClose}
          >
            Close
          </Button>
          </div>
        </div>

      <div className="filters-modal__filters-wrapper">
        <FilterSection title="Skills" filtersArr={skillsFilters} />
        <FilterSection title="Regions" filtersArr={regionsFilters} />
        <FilterSection title="Countries" filtersArr={countriesFilters} />
        <DateRangeFields />
        <PriceRangeFields />
      </div>

      </StyledModalBody>
    </CustomModal>
  );
};

export default FiltersModal;
