import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  .sign-in {
    &__title {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__form-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__remember-me-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__button-area {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }

    &__last-section {
      width: 100%;
      display: flex;
      column-gap: 8px;
      align-items: center;
    }

  }

`;
