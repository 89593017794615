import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  margin-top: 46px;

  .foreground-area {
    display: none;
    z-index: 300;

    @media (max-width: 1115px) {
      display: flow;
    }
  }

  .filters-area {
    justify-content: space-between;
  }
`;
