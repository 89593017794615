import styled from 'styled-components';

export default styled.div`
  .loading-section {
    height: 335.5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
