import styled, { css } from 'styled-components';

export default styled.div<{ ispressed?: boolean }>`

  .MuiButton-root {
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: none;
    text-transform: capitalize;
    padding: 8px 20px;
    min-width: 0;

    :hover {
      box-shadow: none;
    }
  }

  .MuiLoadingButton-loadingIndicatorCenter {
    position: relative;
    left: auto;
    color: ${({ theme }) => theme.mainTheme.color.background};
  }

  .MuiButton-outlinedPrimary {
    ${({ ispressed, theme }) => css`
      border-color: ${ispressed ? theme.mainTheme.color.blue50 : theme.mainTheme.color.blue30};
      color: ${ispressed ? theme.mainTheme.color.blue50 : theme.mainTheme.color.gray50};
      background-color: ${ispressed && theme.mainTheme.color.blue20};
      pointer-events: ${ispressed && 'none'};
    `}
  }

  .MuiButton-outlinedError {
    color: ${({ theme }) => theme.mainTheme.color.darkRed}
  }

  .MuiButton-containedPrimary {
    background-color: ${({ theme }) => theme.mainTheme.color.blue50};
    border: 1px solid #00000000;
  }

  .MuiButton-containedPrimary.Mui-disabled {
    background-color: ${({ theme }) => theme.mainTheme.color.gray30};
    color: ${({ theme }) => theme.mainTheme.color.background};
  }

  .MuiButton-textPrimary {
    padding: 0;
    color: ${({ theme }) => theme.mainTheme.color.blue50};
  }

  > span {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
`;
