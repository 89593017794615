import type { InexButtonsItemType } from 'types/commonTypes';

import ToggleButton from '../ToggleButton';
import StyledSection from './ChartControlButtons.styles';
import HideButton from '../HideButton/HideButton';

export type PropsType = {
  indexButtonArray: InexButtonsItemType[];
  indexButtonPressed: number;
  updateIndexButtonPressed: (index: number) => void;
  isChartDataVisible?: boolean;
  isNeedHideButton?: boolean;
  toggleChartDataVisible?: (value: boolean) => void;
  isDoughnutChartButtons?: boolean;
};

const ChartControlButtons: React.FC<PropsType> = (props) => {
  return (
    <StyledSection
      isDoughnutChartButtons={props.isDoughnutChartButtons}
    >
      <div className="buttons-section__index-buttons">
        {props.indexButtonArray.map((item, index) => (
          <ToggleButton
            key={index}
            isPressed={props.indexButtonPressed === index}
            title={item.title}
            onClick={() => props.updateIndexButtonPressed(index)}
          />
        ))}
      </div>
      {props.isNeedHideButton && (
        <HideButton
          isVisible={props.isChartDataVisible}
          toggleVisible={props.toggleChartDataVisible}
        />
      )}
    </StyledSection>
  );
};

export default ChartControlButtons;
