import FixedBudgetSection from '../FixedBudgetSection/FixedBudgetSection';
import FromMinPricesSection from '../FromMinPricesSection/FromMinPricesSection';
import PriceRangesSection from '../PriceRangesSection/PriceRangesSection';
import UpToPricesSection from '../UpToPricesSection/UpToPricesSection';
import StyledSection from './PricesSection.styles';

const PricesSection = () => {
  return (
    <StyledSection>
      <div className="divider">
        <div className="divider__line" />
        <p className="divider__text">Prices and budget</p>
        <div className="divider__line" />
      </div>
      <div className="prices-and-budget">
        <div className="grid-with-dif-height">
          <div className="first">
            <UpToPricesSection />
          </div>

          <div className="second">
            <PriceRangesSection />
          </div>

          <div className="third">
            <FromMinPricesSection />

          </div>

          <div className="fourth">
            <FixedBudgetSection />
          </div>
        </div>
      </div>
    </StyledSection>
  );
};

export default PricesSection;
