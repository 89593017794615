import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { handleError } from 'utils/errorHandler';
import userThunk from 'store/user/userThunk';
import ToggleButton from 'pages/Dashboard/components/ToggleButton';
import CrownIcon from 'components/CrownIconWrapper/CrownIconWrapper';
import Button from 'components/Button/Button';
import AuthContainer from 'components/AuthContainer';
import StyledProfile from './Profile.styles';
import GeneralSection from './components/GeneralSection';
import PasswordSection from './components/PasswordSection';

const buttonsArray = [
  {
    id: 1,
    title: 'General',
    section: <GeneralSection />,
  },
  {
    id: 2,
    title: 'Change Password',
    section: <PasswordSection />,
  },
];

const Profile: React.FC = () => {
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const onClickLogout = () => {
    try {
      dispatch(userThunk.logout()).unwrap();
    } catch (error) {
      handleError(error, {});
    }
  };

  return (
    <AuthContainer>
      <StyledProfile>
        <h1>
          Profile Settings
          <CrownIcon className="section__header-crown" />
        </h1>

        <div className="section__buttons-area">
          {buttonsArray.map((item, index) => (
            <ToggleButton
              key={index}
              isPressed={indexButtonPressed === index}
              title={item.title}
              onClick={() => setIndexButtonPressed(index)}
            />
          ))}
        </div>

        {buttonsArray[indexButtonPressed].section}

        <Button
          loading={isLoading}
          variant="contained"
          onClick={onClickLogout}
        >
          Logout
        </Button>
      </StyledProfile>
    </AuthContainer>
  );
};

export default Profile;
