import { useEffect, useMemo, useState } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { CircularProgress } from '@mui/material';

import { TIME_TO_REFETCH } from 'constants/mainConstants';
import useDebounce from 'utils/useDebounce';
import { useIsComponentMount } from 'utils/useIsComponentMount';
import storageUtil from 'utils/storageHelper';
import { handleError } from 'utils/errorHandler';
import replaceIncorrectSymbol from 'utils/replaceIncorrectSymbol';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import {
  resetFilters,
  setIsApplyFilters,
  setPagination,
  setRequestSentFilters,
  setSearch,
} from 'store/filter/filterSlice';
import { useLazyGetJobsQuery } from 'api/services/jobEndpoints';

import FiltersActions from 'pages/Dashboard/components/FiltersActions';
import AuthContainer from 'components/AuthContainer';
import Input from 'components/Input';
import FilterContainer from 'components/FilterContainer';
import JobModal from './components/JobModal/JobModal';
import MainContainer from './components/MainContainer/MainContainer';
import StyledJobs from './Jobs.styles';

const Jobs: React.FC = () => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState<number | null>(null);
  const filters = useAppSelector(appliedFiltersSelector);
  const {
    isRequestSent,
    pagination,
    search,
  } = useAppSelector((state) => state.filterSlice);
  const dispatch = useAppDispatch();
  const currentSearch = useDebounce(search);
  const isComponentMount = useIsComponentMount();

  const [triggerGetJobs, {
    data,
    currentData,
    error,
    isError,
    isLoading,
  }] = useLazyGetJobsQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(true);
      await triggerGetJobs({ filters, search: currentSearch, pagination });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page]);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetJobs({ filters, search: currentSearch });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isRequestSent, triggerGetJobs]);

  useEffect(() => {
    if (!isComponentMount) { return; }

    if (pagination.page !== 1) {
      dispatch(setPagination({ ...pagination, page: 1 }));
    } else {
      (async () => {
        setLoadingSentRequest(true);
        await triggerGetJobs({ filters, search: currentSearch, pagination });
        setLoadingSentRequest(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch]);

  const updatedData = useMemo(() => {
    return data?.data.map((jobItem) => {
      return {
        ...jobItem,
        jobTitle: replaceIncorrectSymbol(jobItem.jobTitle),
        category: {
          ...jobItem.category,
          categoryTitle: replaceIncorrectSymbol(jobItem?.category?.categoryTitle || ''),
        },
        skills: jobItem.skills
          ? jobItem.skills.map((skill) => {
            return {
              ...skill,
              skillId: replaceIncorrectSymbol(skill.skillId),
            };
          }) : [],
      };
    });
  }, [data]);

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  const clearSearch = () => {
    dispatch(setSearch(''));
  };

  const onResetFilters = () => {
    storageUtil.dateRangeFrom.remove();
    storageUtil.dateRangeTo.remove();
    storageUtil.priceRangeFrom.remove();
    storageUtil.priceRangeTo.remove();
    storageUtil.fixedBudget.remove();
    storageUtil.mainFilters.remove();
    dispatch(resetFilters());
    dispatch(setIsApplyFilters(false));
  };

  const toggleJobModal = (state: number | null) => {
    setSelectedJobId(state);
  };

  return (
    <AuthContainer>
      <StyledJobs>
        <div className="jobs-header">
          <h1>All Jobs</h1>

          <Input
            nameInput="search"
            placeholder="Type to Search..."
            startAdornment={<SearchRoundedIcon />}
            value={search}
            className="jobs-header__input"
            onChangeValue={onChangeSearch}
          />

          <FilterContainer className="jobs-header__filters-area" />
        </div>

        <FiltersActions />

        {isLoading || (isLoadingSentRequest && !currentData) ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (

          <MainContainer
            currentSearch={currentSearch}
            data={!error ? updatedData : []}
            meta={data?.meta}
            clearSearch={clearSearch}
            onResetFilters={onResetFilters}
            toggleJobModal={toggleJobModal}
          />
        )}
      </StyledJobs>

      <JobModal
        jobId={selectedJobId}
        handleClose={() => toggleJobModal(null)}
      />
    </AuthContainer>
  );
};

export default Jobs;
