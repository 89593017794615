import { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import type { ChartDataset } from 'chart.js';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { handleError } from 'utils/errorHandler';
import { useLazyGetRelatedSkillsQuery } from 'api/services/chartEndpoints/relatedSkillsEndpoints';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { SkillsLimitENUM } from 'types/filtersType';
import { TIME_TO_REFETCH } from 'constants/mainConstants';

import Paper from 'components/Paper';
import DoughnutChart from 'components/DoughnutChart';
import EmptyState from 'components/EmptyState/EmptyState';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './RelatedSkillsSection.styles';

const buttonsArray = [
  {
    title: 'Top 30',
    value: SkillsLimitENUM.THIRTY,
  },
  {
    title: 'Top 20',
    value: SkillsLimitENUM.TWENTY,
  },
  {
    title: 'Top 10',
    value: SkillsLimitENUM.TEN,
  },
];

type PropsType = {
  chartColors: string[];
};

const RelatedSkillsSection: React.FC<PropsType> = ({ chartColors }) => {
  const dispatch = useAppDispatch();
  const relatedSkill = useAppSelector((state) => state.filterSlice.relatedSkill);
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const [isChartDataVisible, setIsChartDataVisible] = useState<boolean>(true);
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);

  const [triggerGetRelatedSkillsQuery, {
    data,
    error,
    isError,
    isLoading,
    currentData,
  }] = useLazyGetRelatedSkillsQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    if (!relatedSkill) { return; }

    (async () => {
      setLoadingSentRequest(!currentData);
      await triggerGetRelatedSkillsQuery({ skill: relatedSkill, filters });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedSkill]);

  useEffect(() => {
    if (!relatedSkill) { return; }

    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetRelatedSkillsQuery({ skill: relatedSkill, filters });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    isRequestSent,
    triggerGetRelatedSkillsQuery,
  ]);

  const limitedData = useMemo(() => {
    const labels = data?.labels?.slice(0, buttonsArray[indexButtonPressed].value);
    const newDataInddatasets =
      data?.datasets[0].data.slice(0, buttonsArray[indexButtonPressed].value);
    return {
      labels,
      datasets: [
        {
          data: newDataInddatasets,
        },
      ] as ChartDataset[],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexButtonPressed, data]);

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  const toggleChartDataVisible = () => {
    setIsChartDataVisible(!isChartDataVisible);
  };

  if (!relatedSkill) {
    return (
      <Paper title={relatedSkill ? `Related to ${relatedSkill} skills` : 'Related skills'}>
        <StyledSection>
          <div className="without-content-section">
            <h4>
              Click on a skill on the Pie on the left to see the details by specific technology
            </h4>
          </div>
        </StyledSection>
      </Paper>
    );
  }

  return (
    <Paper
      title={relatedSkill ? `Related to ${relatedSkill} skills` : 'Related skills'}
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          isNeedHideButton
          isChartDataVisible={isChartDataVisible}
          updateIndexButtonPressed={updateIndexButtonPressed}
          toggleChartDataVisible={toggleChartDataVisible}
          isDoughnutChartButtons
        />
      )}
    >
      <StyledSection>
        {isLoading || isLoadingSentRequest ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <div className="graph-section">
            {data?.datasets?.[0]?.data ? (
              <DoughnutChart
                legendId="legend-related-skills-doughnut"
                countId="count-related-skills-doughnut"
                labels={limitedData?.labels}
                datasets={limitedData?.datasets}
                isChartDataVisible={isChartDataVisible}
                datasetsColors={chartColors}
              />
            ) : (
              <EmptyState />
            )}
          </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default RelatedSkillsSection;
