import { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import type { ChartDataset, Point } from 'chart.js';
import { CircularProgress } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { useLazyGetPricesRangesQuery } from 'api/services/chartEndpoints/pricesEndpoints';
import { handleError } from 'utils/errorHandler';
import { TIME_TO_REFETCH } from 'constants/mainConstants';

import Paper from 'components/Paper';
import EmptyState from 'components/EmptyState/EmptyState';
import getPriceBarOptions from '../PricesSection/pricesBarOptions';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './PriceRangesSection.styles';

const buttonsArray = [
  {
    title: 'linear',
    value: false,
  },
  {
    title: 'logarithmic',
    value: true,
  },
];

const PriceRangesSection = () => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);
  const dispatch = useAppDispatch();

  const [triggerGetPricesRangesQuery, {
    data,
    error,
    isError,
    isLoading,
  }] = useLazyGetPricesRangesQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(true);
      await triggerGetPricesRangesQuery({ filters });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetPricesRangesQuery({ filters });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isRequestSent, triggerGetPricesRangesQuery]);

  const options = useMemo(() => {
    return getPriceBarOptions(buttonsArray[indexButtonPressed].value);
  }, [indexButtonPressed]);

  const newDatasets = useMemo(() => {
    if (data?.datasets) {
      return data.datasets as ChartDataset<'bar', (number | Point | null)[]>[];
    }
    return [];
  }, [data?.datasets]);

  const isNotEmpty =
    useMemo(() => newDatasets?.[0]?.data?.filter(
      (item) => item && item as number > 0,
    ).length, [newDatasets]);

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  return (
    <Paper
      title="Price ranges"
      subTitle="Different ranges"
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          updateIndexButtonPressed={updateIndexButtonPressed}
        />
      )}
    >
      <StyledSection>
        { isLoading || isLoadingSentRequest ? (
            <div className="loading-section">
              <CircularProgress />
            </div>
        ) : (
            <div className="chart-section">
              { isNotEmpty ? (
                  <div className="chart-section__chart">
                    <Bar options={options} data={{ labels: data?.labels, datasets: newDatasets }} />
                  </div>
              ) : (
              <EmptyState />
              )}
            </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default PriceRangesSection;
