import type { FC } from 'react';
import type { IIcon } from '../../types/commonTypes';

const GraphicIcon: FC<IIcon> = ({ className }) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="currentColor" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M16 0.5H2C0.9 0.5 0 1.4 0 2.5V16.5C0 17.6 0.9 18.5 2 18.5H16C17.1 18.5 18 17.6 18 16.5V2.5C18 1.4 17.1 0.5 16 0.5ZM6 14.5H4V7.5H6V14.5ZM10 14.5H8V4.5H10V14.5ZM14 14.5H12V10.5H14V14.5Z" />
    </svg>
  );
};

export default GraphicIcon;
