import styled from 'styled-components';

export default styled.div`
  display: flex;
  gap: 16px;

  .filters-container {
    display: flex;
    gap: 16px;

    &__show-btn {
      white-space: nowrap;
    }

    &__filters-btn {
      & > .MuiButton-root {
        display: flex;
        gap: 3px;
      }
    }
  }
`;
