import styled from 'styled-components';

type PropsType = {
  isDoughnutChartButtons?: boolean;
};

export default styled.div<PropsType>`
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-end;
  width: auto;

  & .buttons-section__index-buttons {
    display: flex;
    border: 0.5px solid ${({ theme }) => theme.mainTheme.color.gray20};
    border-radius: 9px;
    max-height: 24px;
    align-items: center;
    margin-top: 4.5px;
  }
`;
