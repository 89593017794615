import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';

import SignInPage from 'pages/SignInPage';
import SignUpPage from 'pages/SignUpPage';
import ForgotPassPage from 'pages/ForgotPassPage';
import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';
import Jobs from 'pages/Jobs';
import ForgotPassword from 'pages/ForgotPassPage/components/ForgotPassword';
import ResetPassword from 'pages/ForgotPassPage/components/ResetPassword';
import ChangePassword from 'pages/ForgotPassPage/components/ChangePassword';
import PrivateRoute from 'pages/PrivateRoute';

import GraphicIcon from 'assets/svg/GraphicIcon';

export enum PathENUM {
  DASHBOARD = '/',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = 'reset-password',
  CHANGE_PASSWORD = 'change-password',
  JOBS = '/jobs',
  PROFILE = '/profile',
}

export const navigateMenuPathsArray = [
  {
    path: PathENUM.DASHBOARD,
    Icon: GraphicIcon,
    title: 'Dashboard',
  },
  {
    path: PathENUM.JOBS,
    Icon: WorkIcon,
    title: 'All jobs',
  },
  {
    path: PathENUM.PROFILE,
    Icon: AccountCircleIcon,
    title: 'Profile',
  },
];

export const arrRoutes = [
  {
    path: PathENUM.DASHBOARD,
    element:
      <PrivateRoute isNeedAuth>
        <Dashboard />
      </PrivateRoute>,
  },
  {
    path: PathENUM.SIGN_IN,
    element:
      <PrivateRoute isNeedAuth={false}>
        <SignInPage />
      </PrivateRoute>,
  },
  {
    path: PathENUM.SIGN_UP,
    element:
      <PrivateRoute isNeedAuth={false}>
        <SignUpPage />
      </PrivateRoute>,
  },
  {
    path: PathENUM.FORGOT_PASSWORD,
    element:
      <PrivateRoute isNeedAuth={false}>
        <ForgotPassPage />
      </PrivateRoute>,
    children: [
      {
        path: '',
        element:
            <ForgotPassword />,
      },
      {
        path: PathENUM.RESET_PASSWORD,
        element:
            <ResetPassword />,
      },
      {
        path: PathENUM.CHANGE_PASSWORD,
        element:
            <ChangePassword />,
      },
    ],
  },
  {
    path: PathENUM.JOBS,
    element:
      <PrivateRoute isNeedAuth>
        <Jobs />
      </PrivateRoute>,
  },
  {
    path: PathENUM.PROFILE,
    element:
      <PrivateRoute isNeedAuth>
        <Profile />
      </PrivateRoute>,
  },
];
