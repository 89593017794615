import { MethodENUM, type SkillType } from 'types/apiTypes/statisticApiTypes';
import { baseApi } from '../baseApiBuilder';

enum FilterApiPathENUM {
  GET_SKILLS = '/api/v1/feed/statistics/skill-list',
  GET_REGIONS_AND_COUNTRIES = '/api/v1/feed/statistics/countries-and-regions-list',
}

const filterApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query<string[], { desiredRow?: string }>({
      query: (body) => ({
        url: FilterApiPathENUM.GET_SKILLS,
        method: MethodENUM.GET,
        params: body?.desiredRow && {
          desiredRow: body?.desiredRow,
        },
      }),
      providesTags: ['Skills'],
      transformResponse: (response: SkillType[]) => {
        return response.map((element) => element.label);
      },
    }),

    getRegionsAndCounties: builder.query<string[], { location: 'Regions' | 'Countries' }>({
      query: () => ({
        url: FilterApiPathENUM.GET_REGIONS_AND_COUNTRIES,
        method: MethodENUM.GET,
      }),
      providesTags: (_r, _m, arg) => {
        return [{ type: 'LocationList', id: arg.location }];
      },
      transformResponse: (response: Record<string, string[]>, _m, arg) => {
        if (arg.location === 'Countries') {
          return Object.values(response).flat(Infinity) as string[];
        }
        return Object.keys(response) as string[];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetRegionsAndCountiesQuery,
  useLazyGetSkillsQuery,
} = filterApi;
