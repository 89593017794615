import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import type { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import type { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import type { ChartDataset } from 'chart.js';

import instance from 'api';
import { sleep } from 'utils/sleepUtils';
import type { JobType } from 'types/jobTypes';
import type { PaginationType } from 'types/filtersType';

type BaseResponseType = {
  data: unknown;
  message: string;
  meta: PaginationType;
} & AxiosResponse;

const mockData: Record<string, {
  labels: string[];
  datasets: ChartDataset[];
} | {data: JobType[]}> = {};

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > => async ({ url, method, data, params, headers }) => {
    try {
      if (Object.keys(mockData).includes(url)) {
        const mockResults = mockData[url];
        if (mockResults) {
          await sleep(2000);
          return {
            data: mockResults,
          } as unknown as MaybePromise<QueryReturnValue<unknown, unknown, object>>;
        }
      }

      const result: BaseResponseType = await instance({
        url,
        method,
        data,
        params,
        headers,
      });

      return { data: result.data, meta: result.meta };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
