import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';

import { getCurrentTimezone } from 'utils/timeHelper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { handleError } from 'utils/errorHandler';

import EmptyState from 'components/EmptyState/EmptyState';
import Paper from 'components/Paper';
import { TileLinePeriodENUM } from 'types/filtersType';
import { useTimeLine } from './useTimeLine';
import { options } from './optionsBar';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './TimeLineSection.styles';

const buttonsArray = [
  {
    title: 'by hour',
    value: TileLinePeriodENUM.HOUR,
  },
  {
    title: 'by day of the week',
    value: TileLinePeriodENUM.DAY,
  },
];

const TimeLineSection: React.FC = () => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const {
    error,
    isError,
    isLoading,
    dataset,
    currentData,
    labels,
    triggerGetTimeLine,
  } = useTimeLine(buttonsArray[indexButtonPressed].value);

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(!currentData);
      await triggerGetTimeLine(filters);
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexButtonPressed, currentData]);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetTimeLine(filters);
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, indexButtonPressed, isRequestSent, triggerGetTimeLine]);

  const currentTimezone = useMemo(() => getCurrentTimezone(), []);

  const isNotEmpty =
    useMemo(() => dataset?.[0]?.data?.filter(
      (item) => item && item as number > 0,
    ).length, [dataset]);

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  return (
    <Paper
      title="Timeline"
      timezone={currentTimezone}
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          updateIndexButtonPressed={updateIndexButtonPressed}
        />
      )}
    >
      <StyledSection>

        {isLoading || isLoadingSentRequest ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <div className="chart-container">
            { isNotEmpty ? (
            <Bar options={options} data={{ labels, datasets: dataset }} />
            ) : (
            <EmptyState />
            )}
          </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default TimeLineSection;
