import React from 'react';
import type { CheckboxProps } from '@mui/material';
import { Checkbox as MCheckbox } from '@mui/material';
import StyledFormControlLabel from './Checkbox.styles';

interface IProps extends CheckboxProps {
  title?: string;
}

const Checkbox: React.FC<IProps> = ({ title, ...props }) => {
  return (
    <StyledFormControlLabel
      control={<MCheckbox disableRipple {...props} />}
      label={title}
      className="button-text"
     />
  );
};

export default Checkbox;
