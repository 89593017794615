import React from 'react';
import { Outlet } from 'react-router-dom';

import UnAuthContainer from 'components/UnAuthContainer';

const ForgotPassPage: React.FC = () => {
  return (
    <UnAuthContainer withOutRightSection>
      <Outlet />
    </UnAuthContainer>
  );
};

export default ForgotPassPage;
