import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosQuery';

export const baseApi = createApi({
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: false,
  refetchOnFocus: false,
  reducerPath: 'statisticApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'MostPopularSkills',
    'TrendsByWeek',
    'TrendsByMonth',
    'TimeLineByHour',
    'TimeLineByWeek',
    'Location',
    'Skills',
    'LocationList',
    'LocationCounties',
    'UpToPrices',
    'FromMinPrices',
    'PricesRanges',
    'FixedBudget',
    'RelatedSkills',
    'Jobs',
  ],
  endpoints: () => ({}),
});
