import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import type { UserSliceType } from './userSliceType';
import thunk from './userThunk';

const initialState: UserSliceType = {
  user: {
    userId: 0,
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    lastActivity: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: null,
    status: '',
  },

  isLoading: false,
};

const fulfilledThunks = isAnyOf(
  thunk.loginByPassEmail.fulfilled,
  thunk.signUp.fulfilled,
  thunk.getMe.fulfilled,
  thunk.changeInfo.fulfilled,
);

const pendingThunks = isAnyOf(
  thunk.loginByPassEmail.pending,
  thunk.signUp.pending,
  thunk.changeInfo.pending,
  thunk.forgotPassword.pending,
  thunk.checkCode.pending,
  thunk.resetPassword.pending,
  thunk.logout.pending,
);

const rejectedThunks = isAnyOf(
  thunk.loginByPassEmail.rejected,
  thunk.resetPassword.rejected,
  thunk.signUp.rejected,
  thunk.changeInfo.rejected,
  thunk.forgotPassword.fulfilled,
  thunk.forgotPassword.rejected,
  thunk.checkCode.fulfilled,
  thunk.checkCode.rejected,
  thunk.resetPassword.fulfilled,
  thunk.logout.rejected,
);

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    logout: () => {
      sessionStorage.clear();
      localStorage.clear();
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(thunk.logout.fulfilled, () => {
      return initialState;
    });

    builder.addMatcher(fulfilledThunks, (state, { payload }) => {
      if (!payload) {
        return;
      }
      state.user = payload;
      state.isLoading = false;
    });

    builder.addMatcher(rejectedThunks, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(pendingThunks, (state) => {
      state.isLoading = true;
    });
  },
});

export const { logout } = userSlice.actions;

export default userSlice.reducer;
