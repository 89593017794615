export const isEqualArrays = (arrayA: string[], arrayB: string[]) => {
  let isEqual = true;

  if (arrayA.length !== arrayB.length) {
    return false;
  }

  arrayA.forEach((itemA) => {
    if (!arrayB.includes(itemA)) {
      isEqual = false;
      return itemA;
    }
  });

  return isEqual;
};

export const removeDuplicatePrimitiveValues = (arr: string | number []) => {
  const isArray = Array.isArray(arr);

  if (!isArray) {
    return;
  }

  const arrCollection = new Set(arr);
  const newArr = Array.from(arrCollection);

  return newArr;
};
