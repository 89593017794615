import React, { useMemo, useState, useEffect } from 'react';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import { ONLY_NUMBERS_REGEX } from 'constants/mainConstants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { savedPriceRangeSelector } from 'store/filter/filterSelector';
import {
  setPriceRangeFilters,
} from 'store/filter/filterSlice';
import { setPriceRangeToStorage } from 'utils/storageFiltersHelper';
import useCheckMatchApplyedAndSavedFilters from 'utils/useCheckMatchApplyedAndSavedFilters';
import { PriceRangeNameENUM } from 'types/filtersType';

import Input from 'components/Input/Input';
import StyledPriceRangeContainer from './PriceRangeFields.styles';

const PriceRangeContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkMatchApplyedAndSavedFilters = useCheckMatchApplyedAndSavedFilters();

  const {
    from: rangeFrom,
    to: rangeTo,
    fixedBudget,
  } = useAppSelector(savedPriceRangeSelector);

  const [inputsArray, setInputsArray] = useState({
    from: rangeFrom || null,
    to: rangeTo || null,
    fixedBudget: fixedBudget || null,
  });

  const handleChangePriceRange = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: PriceRangeNameENUM,
  ) => {
    const value = event.target.value;

    if (value.length >= 11) {
      return;
    }
    const formattedValue = value.replace(ONLY_NUMBERS_REGEX, '');
    setInputsArray({ ...inputsArray, [name]: formattedValue || null });
  };

  // TODO add clearing fields to something place?
  const handleClearRange = () => {
    setInputsArray({
      from: null,
      to: null,
      fixedBudget: null,
    });

    setPriceRangeToStorage({ key: PriceRangeNameENUM.FROM, value: null });
    setPriceRangeToStorage({ key: PriceRangeNameENUM.TO, value: null });
    setPriceRangeToStorage({ key: PriceRangeNameENUM.FIXED_BUDGET, value: null });

    dispatch(setPriceRangeFilters({
      from: null,
      to: null,
      fixedBudget: null,
    }));
    const isChangedPrices = rangeFrom !== null ||
      rangeTo !== null ||
      fixedBudget !== null;
    if (isChangedPrices) {
      checkMatchApplyedAndSavedFilters();
    }
  };

  const isChangedDates = useMemo(() => {
    let isFromToChanged = true;
    if (inputsArray.to && inputsArray.from) {
      isFromToChanged &&= +inputsArray.from <= +inputsArray.to;
    }
    return (inputsArray.from !== rangeFrom ||
      inputsArray.to !== rangeTo ||
      inputsArray.fixedBudget !== fixedBudget) && isFromToChanged;
  }, [fixedBudget, inputsArray, rangeFrom, rangeTo]);

  useEffect(() => {
    const handleSaveRange = () => {
      dispatch(setPriceRangeFilters({
        from: inputsArray.from,
        to: inputsArray.to,
        fixedBudget: inputsArray.fixedBudget,
      }));
      checkMatchApplyedAndSavedFilters();

      setPriceRangeToStorage({ key: PriceRangeNameENUM.FROM, value: inputsArray.from });
      setPriceRangeToStorage({ key: PriceRangeNameENUM.TO, value: inputsArray.to });
      setPriceRangeToStorage({
        key: PriceRangeNameENUM.FIXED_BUDGET,
        value: inputsArray.fixedBudget,
      });
    };

    if (!isChangedDates) {
      return;
    }

    handleSaveRange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedDates]);

  return (
    <StyledPriceRangeContainer>
      <h3>Price Range</h3>
      <Input
        type="text"
        value={inputsArray.from || ''}
        onChangeValue={(e) => handleChangePriceRange(e, PriceRangeNameENUM.FROM)}
        nameInput="rangeFrom"
        placeholder="From"
        startAdornment={<AttachMoneyIcon />}
        isDisabled={!!inputsArray.fixedBudget}
      />

      <Input
        type="text"
        value={inputsArray.to || ''}
        onChangeValue={(e) => handleChangePriceRange(e, PriceRangeNameENUM.TO)}
        nameInput="rangeTo"
        placeholder="To"
        startAdornment={<AttachMoneyIcon />}
        isDisabled={!!inputsArray.fixedBudget}
      />

      <Input
        type="text"
        value={inputsArray.fixedBudget || ''}
        onChangeValue={(e) => handleChangePriceRange(e, PriceRangeNameENUM.FIXED_BUDGET)}
        nameInput="fixedBudget"
        placeholder="Fixed budget"
        startAdornment={<AttachMoneyIcon />}
        isDisabled={!!inputsArray.to || !!inputsArray.from}
      />
    </StyledPriceRangeContainer>
  );
};

export default PriceRangeContainer;
