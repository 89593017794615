import type { ChartDataset } from 'chart.js';

import {
  convertGradientLineGraphParamsToDataset,
  convertPriceBarGraphParamsToDataset,
} from 'utils/setParamsHelper';
import { baseApi } from 'api/baseApiBuilder';
import type { FilterDisplayType } from 'types/filtersType';
import type { ChartType } from '../../../types/apiTypes/statisticApiTypes';
import { MethodENUM, StatisticApiPathENUM } from '../../../types/apiTypes/statisticApiTypes';

const pricesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUpToPrices: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_UP_TO_PRICES,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['UpToPrices'],
      transformResponse: (response)
      : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
        return convertGradientLineGraphParamsToDataset(response as { [s: number]: number });
      },
    }),

    getFromMinPrices: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_FROM_MIN_PRICES,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['FromMinPrices'],
      transformResponse: (response)
      : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
        return convertGradientLineGraphParamsToDataset(response as { [s: number]: number });
      },
    }),

    getPricesRanges: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_PRICES_RANGES,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['PricesRanges'],
      transformResponse: (response)
      : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
        return convertPriceBarGraphParamsToDataset(response as { [s: string]: number });
      },
    }),

    getFixedBudget: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_FIXED_BUDGET,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['FixedBudget'],
      transformResponse: (response)
      : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
        return convertPriceBarGraphParamsToDataset(response as { [s: string]: number });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetUpToPricesQuery,
  useLazyGetFromMinPricesQuery,
  useLazyGetPricesRangesQuery,
  useLazyGetFixedBudgetQuery,
} = pricesApi;
