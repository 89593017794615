import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 1130px;
  display: flex;
  gap: 16px;

  @media (max-width: 815px) {
    flex-direction: column;
  }
`;
