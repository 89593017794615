import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import type { AxiosError } from 'axios';
import Switch from '@mui/joy/Switch';

import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import { signUpSchema } from 'types/validationSchemes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { PathENUM } from 'routes';

import UnAuthContainer from 'components/UnAuthContainer';
import Input from 'components/Input';
import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';
import NavToSection from 'components/NavToSection';

import StyledSignUp from './SignUpPage.styles';

const SignUpPage: React.FC = () => {
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      isHasCompanyName: false,
      companyName: '',
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(userThunks.signUp({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          companyName: values.companyName ? values.companyName : undefined,
        })).unwrap();
        navigate(PathENUM.DASHBOARD);
      } catch (err) {
        handleError(err, {
          errors: {
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('isHasCompanyName', (event.target.checked));
  };

  const isUnFilledFields = useMemo(() => {
    return !formik.values.email ||
      !formik.values.password ||
      !formik.values.firstName ||
      !formik.values.lastName ||
      (formik.values.isHasCompanyName && !formik.values.companyName);
  }, [formik.values]);

  return (
    <UnAuthContainer>
      <StyledSignUp>
        <div className="sign-up__title">
          <h1>Sign Up</h1>

          <div>
            <p className="paragraph">Welcome!</p>

            <p className="paragraph">Please, enter necessary information below to start using the app</p>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} className="sign-up__form-section">
          <Input
            label="First Name"
            nameInput="firstName"
            onChangeValue={formik.handleChange}
            value={formik.values.firstName}
            errorText={formik.errors.firstName}
            isTouched={formik.touched.firstName}
            onBlur={formik.handleBlur}
          />

          <Input
            label="Second Name"
            nameInput="lastName"
            onChangeValue={formik.handleChange}
            value={formik.values.lastName}
            errorText={formik.errors.lastName}
            isTouched={formik.touched.lastName}
            onBlur={formik.handleBlur}
          />

          <Input
            label="Email"
            nameInput="email"
            onChangeValue={formik.handleChange}
            value={formik.values.email}
            errorText={formik.errors.email}
            isTouched={formik.touched.email}
            onBlur={formik.handleBlur}
          />

          <PasswordInput
            label="Password"
            nameInput="password"
            onChangeValue={formik.handleChange}
            value={formik.values.password}
            errorText={formik.errors.password}
            isTouched={formik.touched.password}
            onBlur={formik.handleBlur}
          />

          <div className="form-section__company-name">
            <div className="company-name__switch-area">
              <p className="paragraph">
                Are you registering on behalf of a company?
              </p>
              <Switch
                className="company-name__switch"
                onChange={handleSwitchChange}
                slotProps={{
                  track: {
                    children: (
                      <>
                        <span
                          className="switch__text switch__text--left"
                        >
                          Yes
                        </span>
                        <span
                          className="switch__text switch__text--right"
                        >
                          No
                        </span>
                      </>
                    ),
                  },
                }}
                sx={{
                  '--Switch-thumbSize': '27px',
                  '--Switch-trackWidth': '64px',
                  '--Switch-trackHeight': '31px',
                }}
              />
            </div>
            {formik.values.isHasCompanyName && (
              <Input
                className="company-name__input"
                label="Company Name"
                nameInput="companyName"
                onChangeValue={formik.handleChange}
                value={formik.values.companyName}
                errorText={formik.values.isHasCompanyName ? formik.errors.companyName : ''}
                isTouched={formik.touched.companyName}
                onBlur={formik.handleBlur}
                autoFocus
              />
            )}
          </div>

          <div className="sign-up__form-section__button-area">
            <Button
              loading={isLoading}
              variant="contained"
              disabled={!formik.isValid || isUnFilledFields}
              type="submit"
            >
              Sign Up
            </Button>
          </div>
        </form>

        <NavToSection
          title="Do you have an account?"
          titleButton="Sign In"
          path={PathENUM.SIGN_IN}
        />
      </StyledSignUp>

    </UnAuthContainer>
  );
};

export default SignUpPage;
