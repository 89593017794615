import axios from 'axios';

import storageUtil from 'utils/storageHelper';
import config from '../config';
import { authAPI } from './services/userApi';

const instance = axios.create({
  baseURL: config.domainName,
});

instance.interceptors.request.use((config) => {
  const token = storageUtil.token.get();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

let refreshPromise: Promise<boolean> | null = null;

instance.interceptors.response.use(
  ({ data }) => data,
  async (err) => {
    if (
      err?.response?.data.message === 'Expired token' ||
      err?.response?.data.message === 'Invalid token'
    ) {
      if (!refreshPromise) {
        refreshPromise = refreshToken();
      }
      const isReseted = await refreshPromise;

      if (!isReseted) {
        throw err;
      }
      const request = err.config;

      return instance(request);
    }

    return Promise.reject(err);
  },
);

const refreshToken = async () => {
  try {
    const res = await authAPI.refreshToken({
      token: storageUtil.refreshToken.get(),
      deviceId: storageUtil.deviceId.get(),
    });
    storageUtil.token.set({
      value: res?.data?.data?.tokens.authorization,
    });
    storageUtil.refreshToken.set({
      value: res?.data?.data?.tokens.refresh,
    });

    return true;
  } catch (err) {
    storageUtil.logout();
    return false;
  } finally {
    refreshPromise = null;
  }
};

export default instance;
