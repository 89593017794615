import React, { useCallback, useMemo, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setDateRangeFilters } from 'store/filter/filterSlice';
import { savedDateRangeSelector } from 'store/filter/filterSelector';
import { setDateRangeToStorage } from 'utils/storageFiltersHelper';
import useCheckMatchApplyedAndSavedFilters from 'utils/useCheckMatchApplyedAndSavedFilters';
import { DateRangeNameENUM } from 'types/filtersType';

import StyledDateRangeFields from './DateRangeFields.styles';
import type { DateInputType } from '../DateRangeTypes';
import { DEFAULT_FORMAT } from '../DateRangeTypes';
import DateInput from '../components/DateInput/DateInput';
import { REGEX_ALL_DAY } from '../components/DateInput/DateInput.constants';

dayjs.extend(customParseFormat);

dayjs.extend(customParseFormat);

const DateRangeFields: React.FC = () => {
  const [isDatePickerOpened, setDatePickerOpened] = useState<DateInputType | null>(null);
  const dispatch = useAppDispatch();
  const {
    from: dateFrom,
    to: dateTo,
  } = useAppSelector(savedDateRangeSelector);
  const [inputsArray, setInputsArray] = useState({
    from: dateFrom || null,
    to: dateTo || null,
  });
  const checkMatchApplyedAndSavedFilters = useCheckMatchApplyedAndSavedFilters();

  const handleChangeDate = (name: DateRangeNameENUM, value: string | null) => {
    setInputsArray({ ...inputsArray, [name]: value || null });
  };

  const handleCloseDatePickerAfterClick = () => {
    setDatePickerOpened(null);
  };

  const onOpenDatePicker = (inputName: DateInputType) => {
    setDatePickerOpened(isDatePickerOpened === inputName ? null : inputName);
  };

  const handleClearDateRange = () => {
    setInputsArray({
      from: null,
      to: null,
    });
    setDateRangeToStorage({ key: DateRangeNameENUM.FROM, value: null });
    setDateRangeToStorage({ key: DateRangeNameENUM.TO, value: null });

    dispatch(setDateRangeFilters({ from: null, to: null }));

    const isChangedDates = dateFrom !== null ||
      dateTo !== null;
    if (isChangedDates) {
      checkMatchApplyedAndSavedFilters();
    }
  };

  const isChangedDates = useMemo(() => {
    let isFromChanged = (inputsArray.from === null || REGEX_ALL_DAY.test(inputsArray.from || ''));
    let isToChanged = (inputsArray.to === null || REGEX_ALL_DAY.test(inputsArray.to || ''));

    if (inputsArray.from !== null) {
      isFromChanged &&= dayjs(inputsArray.from, DEFAULT_FORMAT).toDate() <= dayjs().toDate();
      if (isToChanged && inputsArray.to !== null) {
        isFromChanged &&= dayjs(inputsArray.from, DEFAULT_FORMAT).toDate() <=
          dayjs(inputsArray.to, DEFAULT_FORMAT).toDate();
      }
    }

    if (inputsArray.to !== null) {
      isToChanged &&= dayjs(inputsArray.to, DEFAULT_FORMAT).toDate() <= dayjs().toDate();
      if (isFromChanged && inputsArray.from !== null) {
        isToChanged &&= dayjs(inputsArray.to, DEFAULT_FORMAT).toDate() >=
          dayjs(inputsArray.from, DEFAULT_FORMAT).toDate();
      }
    }

    return (
      inputsArray.from !== dateFrom || inputsArray.to !== dateTo
    ) && isFromChanged && isToChanged;
  }, [dateFrom, dateTo, inputsArray]);

  const getAnotherDate = useCallback((name: DateRangeNameENUM) => {
    if (name === DateRangeNameENUM.FROM) {
      return inputsArray[DateRangeNameENUM.TO]
        ? dayjs(inputsArray[DateRangeNameENUM.TO], DEFAULT_FORMAT).toDate()
        : null;
    }
    return inputsArray[DateRangeNameENUM.FROM]
      ? dayjs(inputsArray[DateRangeNameENUM.FROM], DEFAULT_FORMAT).toDate()
      : null;
  }, [inputsArray]);

  useEffect(() => {
    const handleSaveDateRange = () => {
      dispatch(setDateRangeFilters({ from: inputsArray.from, to: inputsArray.to }));
      checkMatchApplyedAndSavedFilters();
      setDateRangeToStorage({ key: DateRangeNameENUM.FROM, value: inputsArray.from });
      setDateRangeToStorage({ key: DateRangeNameENUM.TO, value: inputsArray.to });
    };

    if (!isChangedDates) {
      return;
    }

    handleSaveDateRange();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedDates]);

  return (
    <StyledDateRangeFields>
      <h3>Date Range</h3>
      <DateInput
        value={inputsArray.from}
        nameInput="dateFrom"
        placeholder="From"
        nameDate={DateRangeNameENUM.FROM}
        isDatePickerOpened={isDatePickerOpened}
        onClick={onOpenDatePicker}
        anotherDates={getAnotherDate(DateRangeNameENUM.FROM)}
        onChangeDate={handleChangeDate}
        onCloseDatePicker={handleCloseDatePickerAfterClick}
      />

      <DateInput
        value={inputsArray.to}
        nameInput="dateTo"
        placeholder="To"
        nameDate={DateRangeNameENUM.TO}
        isDatePickerOpened={isDatePickerOpened}
        onClick={onOpenDatePicker}
        anotherDates={getAnotherDate(DateRangeNameENUM.TO)}
        onChangeDate={handleChangeDate}
        onCloseDatePicker={handleCloseDatePickerAfterClick}
      />
    </StyledDateRangeFields>
  );
};

export default DateRangeFields;
