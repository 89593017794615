import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidV4 } from 'uuid';

import storageUtil from 'utils/storageHelper';
import { authAPI, userAPI } from 'api/services/userApi';
import type {
  PatchUserType,
  SendCommentType,
  UpdateUserPassType,
} from 'types/apiTypes/userApiTypes';
import type {
  ChangeInfoThunkType,
  ChangePassThunkType,
  CheckCodeType,
  ForgotPasswordType,
  ResetPasswordType,
  SignInThunkType,
  SignUpThunkType,
} from './userSliceType';

const getMe = createAsyncThunk(
  'user/login-by-token',
  async (_, { rejectWithValue }) => {
    try {
      const res = await authAPI.getMe();
      return res.data?.user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const loginByPassEmail = createAsyncThunk(
  'user/sign-in',
  async (values: SignInThunkType, { rejectWithValue }) => {
    try {
      const currentValues = { ...values, deviceId: uuidV4() };
      delete currentValues.agreement;
      const res = await authAPI.signIn(currentValues);
      storageUtil.token.set({
        value: res.data?.tokens.authorization,
        saveToLocal: values.agreement,
      });
      storageUtil.refreshToken.set({
        value: res.data?.tokens.refresh,
        saveToLocal: values.agreement,
      });
      storageUtil.deviceId.set({
        value: currentValues.deviceId,
        saveToLocal: values.agreement,
      });
      return res.data?.user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const signUp = createAsyncThunk(
  'user/sign-up',
  async (values: SignUpThunkType, { rejectWithValue }) => {
    try {
      const currentValues = { ...values, deviceId: uuidV4() };
      const res = await authAPI.signUp(currentValues);
      storageUtil.token.set({
        value: res.data?.tokens.authorization,
      });
      storageUtil.refreshToken.set({
        value: res.data?.tokens.refresh,
      });
      storageUtil.deviceId.set({
        value: currentValues.deviceId,
      });
      return res.data?.user;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const forgotPassword = createAsyncThunk(
  'user/forgot-password',
  async (values: ForgotPasswordType, { rejectWithValue }) => {
    try {
      const res = await authAPI.forgotPassword(values);
      return res.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const checkCode = createAsyncThunk(
  'user/check-code',
  async (values: CheckCodeType, { rejectWithValue }) => {
    try {
      const res = await authAPI.checkCode(values);
      return res.data?.token;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const resetPassword = createAsyncThunk(
  'user/reset-password',
  async (values: ResetPasswordType, { rejectWithValue }) => {
    try {
      const res = await authAPI.resetPassword(values);
      return res.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const changeInfo = createAsyncThunk(
  'user/changeInfo',
  async (values: ChangeInfoThunkType, { rejectWithValue }) => {
    try {
      const currentValues: PatchUserType = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
      };
      const res = await userAPI.patchUserInfo({ id: values.id, info: currentValues });
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const changePass = createAsyncThunk(
  'user/changePass',
  async (values: ChangePassThunkType, { rejectWithValue }) => {
    try {
      const currentValues: UpdateUserPassType = {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        repeatNewPassword: values.repeatNewPassword,
      };
      const res = await userAPI.changeUserPass({ data: currentValues });
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const logout = createAsyncThunk(
  'user/logout',
  async (values, { rejectWithValue }) => {
    try {
      const deviceId = storageUtil.deviceId.get();
      const res = await authAPI.logout({ deviceId });
      storageUtil.logout();
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const sendComment = createAsyncThunk(
  'user/send-comment',
  async (values: SendCommentType, { rejectWithValue }) => {
    try {
      const res = await userAPI.sendComment(values);
      return res.message;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export default {
  loginByPassEmail,
  signUp,
  forgotPassword,
  checkCode,
  resetPassword,
  getMe,
  logout,
  changeInfo,
  changePass,
  sendComment,
};
