import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material';

import type { NameFilterENUM, TypeFilterENUM } from 'types/filtersType';
import { handleError } from 'utils/errorHandler';
import storageUtil from 'utils/storageHelper';
import {
  setAppliedMainFilters,
  setAppliedDateRangeFilters,
  setAppliedPriceRangeFilters,
} from 'store/filter/filterSlice';
import { useAppDispatch } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import RouterPages from 'pages/RouterPages';
import StyledApp from './App.styles';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useAppDispatch();
  useEffect(() => {
    (async () => {
      const token = storageUtil.token.get();
      if (token) {
        try {
          await dispatch(userThunks.getMe()).unwrap();
        } catch (err) {
          handleError(err, {});
        }
      }
      setIsLoading(false);
    })();
    const storageMainFilters = storageUtil.mainFilters.get();
    let parsedStorageMainFilters: Record<NameFilterENUM, Record<TypeFilterENUM, string[]>> = {
      selectedSkills: {
        only: [],
        exclude: [],
      },
      selectedRegions: {
        only: [],
        exclude: [],
      },
      selectedCountries: {
        only: [],
        exclude: [],
      },
    };
    if (storageMainFilters) {
      parsedStorageMainFilters = JSON.parse(storageMainFilters);
      Object.keys(parsedStorageMainFilters).forEach((section) => {
        Object.keys(parsedStorageMainFilters[section as NameFilterENUM]).forEach((type) => {
          dispatch(setAppliedMainFilters({
            section: section as NameFilterENUM,
            type: type as TypeFilterENUM,
            value: parsedStorageMainFilters[section as NameFilterENUM][type as TypeFilterENUM],
          }));
        });
      });
    }

    const storageDateFrom = storageUtil.dateRangeFrom.get();
    const storageDateTo = storageUtil.dateRangeTo.get();

    dispatch(setAppliedDateRangeFilters({
      from: storageDateFrom || null,
      to: storageDateTo || null,
    }));

    const storageRangeFrom = storageUtil.priceRangeFrom.get();
    const storageRangeTo = storageUtil.priceRangeTo.get();
    const storageRangeFixedBudget = storageUtil.fixedBudget.get();

    dispatch(setAppliedPriceRangeFilters({
      from: storageRangeFrom || null,
      to: storageRangeTo || null,
      fixedBudget: storageRangeFixedBudget || null,
    }));
  }, [dispatch]);

  if (isLoading) {
    return (
      <StyledApp>
        <CircularProgress />
      </StyledApp>
    );
  }

  return (
    <StyledApp>
      <div className="app__container">
        <RouterPages />
      </div>

      <ToastContainer
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        closeButton={false}
        bodyClassName="paragraph"
        className="toast-container"
      />
    </StyledApp>
  );
};

export default App;
