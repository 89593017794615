import type { AxiosResponse } from 'axios';
import config from 'config';
import axios from 'axios';

import type {
  ForgotPasswordType,
  CheckCodeType,
  ResetPasswordType,
} from 'store/user/userSliceType';
import type {
  CheckCodeDataType,
  EmptyDataType,
  PatchUserType,
  RefreshTokenType,
  SendCommentType,
  SignInType,
  SignUpType,
  UpdateUserPassType,
  UserDataType,
  UserPatchDataType,
} from '../../types/apiTypes/userApiTypes';
import instance from '../index';

enum AuthApiPathENUM {
  LOGIN_BY_TOKEN = '/api/v1/auth/me',
  LOG_OUT = '/api/v1/auth/log-out',
  REFRESH_TOKEN = '/api/v1/auth/refresh-token',
  SIGN_IN = '/api/v1/auth/sign-in',
  SIGN_UP = '/api/v1/auth/sign-up',
  FORGOT_PASSWORD = '/api/v1/auth/forgot-password',
  CHECK_CODE = '/api/v1/auth/check-reset-password-code',
  RESET_PASSWORD = '/api/v1/auth/reset-password',
}

export const authAPI = {
  signIn: async (info: SignInType): Promise<UserDataType> => {
    return instance.post(AuthApiPathENUM.SIGN_IN, info);
  },

  getMe: async (): Promise<UserDataType> => {
    return instance.get(AuthApiPathENUM.LOGIN_BY_TOKEN);
  },

  refreshToken: async (
    tokenInfo: RefreshTokenType,
  ): Promise<AxiosResponse<UserDataType>> => {
    return axios.post(`${config.domainName}${AuthApiPathENUM.REFRESH_TOKEN}`, tokenInfo);
  },

  signUp: async (info: SignUpType): Promise<UserDataType> => {
    return instance.post(AuthApiPathENUM.SIGN_UP, info);
  },

  forgotPassword: async (info: ForgotPasswordType):
    Promise<EmptyDataType> => {
    return instance.post(AuthApiPathENUM.FORGOT_PASSWORD, info);
  },

  checkCode: async (info: CheckCodeType): Promise<CheckCodeDataType> => {
    return instance.post(AuthApiPathENUM.CHECK_CODE, info);
  },

  resetPassword: async (info: ResetPasswordType): Promise<EmptyDataType> => {
    return instance.post(AuthApiPathENUM.RESET_PASSWORD, info);
  },

  logout: async (options: { deviceId: string }): Promise<UserPatchDataType> => {
    return instance.post(`${AuthApiPathENUM.LOG_OUT}`, options);
  },
};

enum UserApiPathENUM {
  PATCH_USER = '/api/v1/user',
  UPDATE_USER_PASS = '/api/v1/user/update-user/password',
  SEND_COMMENT = '/api/v1/user/request-full-version',
}

export const userAPI = {
  patchUserInfo: async (options: {
    id: number;
    info: PatchUserType;
  }): Promise<UserPatchDataType> => {
    return instance.patch(`${UserApiPathENUM.PATCH_USER}/${options.id}`, options.info);
  },

  changeUserPass: async (options: {
    data: UpdateUserPassType;
  }): Promise<UserPatchDataType> => {
    return instance.patch(`${UserApiPathENUM.UPDATE_USER_PASS}`, options.data);
  },

  sendComment: async (info: SendCommentType): Promise<EmptyDataType> => {
    return instance.post(UserApiPathENUM.SEND_COMMENT, info);
  },
};
