import React, { useEffect } from 'react';
import { Scrollbar } from 'react-scrollbars-custom';
import type { Chart, ChartConfiguration, LegendItem } from 'chart.js';

import StyledLegend from './CustomLegend.styles';
import CustomLegendItem from './CustomLegendItem';

type PropsType = {
  id: string;
  chart?: Chart;
  legendArray?: LegendItem[];
  permanentTrackY?: boolean;
  isChartDataVisible?: boolean;
};

const updateCustomLegendDataVisibility = (chart: Chart) => {
  if (!chart) { return; }
  const { type } = chart.config as ChartConfiguration;
  const visibleItems: boolean[] = [];

  if (type === 'pie' || type === 'doughnut') {
    chart.data.datasets[0].data.forEach((item, index) => {
      const isVisible = chart?.getDataVisibility(index);
      visibleItems.push(isVisible);
    });
  } else {
    chart.data.datasets.forEach((item, index) => {
      const isVisible = chart?.isDatasetVisible(index);
      visibleItems.push(isVisible);
    });
  }

  const indexesOfVisibleItems = visibleItems.reduce((acc: number[], value, index) => {
    if (value) {
      acc.push(index);
    }
    return acc;
  }, []);

  if (type === 'pie' || type === 'doughnut') {
    if (!indexesOfVisibleItems.length) {
      chart.data.datasets[0].data.forEach((item, index) => {
        chart.toggleDataVisibility(index);
      });
    } else {
      indexesOfVisibleItems.forEach((item) => {
        chart.toggleDataVisibility(item);
      });
    }
  } else if (!indexesOfVisibleItems.length) {
    chart.data.datasets.forEach((item, index) => {
      chart?.setDatasetVisibility(
        index,
        true,
      );
    });
  } else {
    indexesOfVisibleItems.forEach((item) => {
      chart?.setDatasetVisibility(
        item,
        false,
      );
    });
  }

  chart.update();
};

const CustomLegend: React.FC<PropsType> = (props) => {
  useEffect(() => {
    if (!props.chart) { return; }

    updateCustomLegendDataVisibility(props.chart);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isChartDataVisible]);

  const onClickLI = (chart: Chart | undefined, item: LegendItem) => {
    if (!chart) { return; }
    const { type } = chart.config as ChartConfiguration;
    if (type === 'pie' || type === 'doughnut') {
      // Pie and doughnut charts only have a single dataset and visibility is per item
      chart.toggleDataVisibility(item.index!);
    } else {
      if (typeof item.datasetIndex !== 'number') { return; }
      const isVisible = chart.isDatasetVisible(item.datasetIndex!);

      chart.setDatasetVisibility(
        item.datasetIndex,
        !isVisible,
      );
    }
    chart.update();
  };

  const onClickULItem = (item: LegendItem) => {
    onClickLI(props.chart, item);
  };

  return (
    <StyledLegend id={props.id}>
      <Scrollbar className="scroll-position" permanentTrackY={props.permanentTrackY}>
        <ul className="legend__ul">
          {props.legendArray && props.legendArray.map((item, index) => (
            <CustomLegendItem
              key={index}
              item={item}
              onClickItem={onClickULItem}
            />
          ))}
        </ul>
      </Scrollbar>
    </StyledLegend>
  );
};

export default CustomLegend;
