import React, { useState } from 'react';

import type { NameFilterENUM, TypeFilterENUM } from 'types/filtersType';
import IncludeSection from './components/IncludeSection';
import ListSection from './components/ListSection';
import StyledContainer from './FilterSection.styles';

export type SectionFilterType = {
  title: string;
  section: NameFilterENUM;
  type: TypeFilterENUM;
  hintText?: string;
};

type PropsType = {
  title: string;
  filtersArr: SectionFilterType[];
};

const FilterSection: React.FC<PropsType> = (props) => {
  const [section, setSection] = useState<SectionFilterType>(props.filtersArr[0]);

  const onSelectFilterBy = (section: SectionFilterType) => {
    setSection(section);
  };

  return (
    <StyledContainer>
      <h3>{props.title}</h3>
      <IncludeSection
        onClick={onSelectFilterBy}
        filtersArr={props.filtersArr}
        selectedSectionType={section.type}
      />
      {Object.keys(section).length !== 0 && <ListSection mainSectionInfo={section} />}
    </StyledContainer>
  );
};

export default FilterSection;
