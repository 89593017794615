import styled from 'styled-components';

export default styled.div`
  position: relative;
  font-family: ${({ theme }) => theme.font.family};
  padding: 12px 20px;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.mainTheme.color.blue10};

  .info-area {
    /* width: 100%; */
    left: 15px;
    right: 10px;
    position: absolute;
    
    /* margin-bottom: -17px; */
    display: flex;
    justify-content: space-between;
    color: ${({ theme }) => theme.mainTheme.color.blue30};
  }

  .MuiSlider-root {
    height: 4px;
    padding: 34px 0 4px 0;
  }

  .MuiSlider-rail {
    background-color: ${({ theme }) => theme.mainTheme.color.blue30};
    top: auto;
  }

  .MuiSlider-track {
    height: 4px;
    border: 0;
    top: auto;
  }

  .MuiSlider-valueLabel {
    top: 0;
    background-color: ${({ theme }) => theme.mainTheme.color.blue10};
    color: ${({ theme }) => theme.mainTheme.color.blue50};
    padding: 0 4px 7px;
    font-family: ${(p) => p.theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    :before {
      display: none;
    };
    * {
      background: none;
      color: ${({ theme }) => theme.mainTheme.color.blue50};
    }
  }

  .MuiSlider-thumb {
    top: auto;
    height: 16px;
    width: 16px;
    background-color: ${({ theme }) => theme.mainTheme.color.blue50};
    border: 2px solid ${({ theme }) => theme.mainTheme.color.background};

    &:focus, &:hover, &.Mui-active, &.Mui-focusVisible {
      box-shadow: inherit;
    };
    :before {
      display: none;
    };
  };
`;
