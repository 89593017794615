import React, { useState } from 'react';

import InfoIcon from 'assets/svg/InfoIcon';
import StyledIncludeItem from './IncludeItem.styles';
import type { SectionFilterType } from '../../FilterSection';

type PropsType = {
  title: string;
  itemType: SectionFilterType;
  hintText?: string;
  isInactive: boolean;
  isHasInfoIcon: boolean;
  className: string;
  isSelected: boolean;
  onClick: (item: SectionFilterType) => void;
};

const IncludeItem: React.FC<PropsType> = (props) => {
  const [isHintVisible, setHintVisible] = useState(false);

  const handleOnClick = (item: SectionFilterType) => {
    if (props.isInactive) { return; }

    props.onClick(item);
  };

  return (
    <StyledIncludeItem
      className={props.className}
      isInactive={props.isInactive}
      isSelected={props.isSelected}
      onClick={() => handleOnClick(props.itemType)}
    >
      <div className="include-item__title">
        <h4>{props.title}</h4>
      </div>
      {props.isHasInfoIcon && (
        <InfoIcon
          className="include-item__info-icon"
          onMouseEnter={() => setHintVisible(true)}
          onMouseLeave={() => setHintVisible(false)}
        />
      )}
      {props.isHasInfoIcon && isHintVisible && (
        <div
          className="include-item__hint"
        >
          {props.hintText}
        </div>
      )}
    </StyledIncludeItem>
  );
};

export default IncludeItem;
