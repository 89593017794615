import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

import type { PathENUM } from 'routes';
import { useGetLocationPath } from 'utils/checkLocationHelper';
import CrownIconWrapper from 'components/CrownIconWrapper/CrownIconWrapper';

type PropsType = {
  path: PathENUM;
  Icon: React.ElementType;
  title: string;
};

const NavElement: React.FC<PropsType> = ({ path, Icon, title }) => {
  const currentPath = useGetLocationPath();
  const isSelectedPath = path === currentPath;

  return (
    <Link to={path}
      className={clsx('auth-container__header__container__navigation-area__element', {
        'auth-container__header__container__navigation-area__element--selected': isSelectedPath,
      })}

    >
      <Icon className={clsx({ 'selected-icon': isSelectedPath })} />

      <h3 className={clsx({ 'selected-text': isSelectedPath })}>
        {title}
        {title === 'Profile' && <CrownIconWrapper className="auth-container__header__container__navigation-area__element__crown" />}
      </h3>
    </Link>
  );
};

export default NavElement;
