import React, { useEffect } from 'react';

import { navigateMenuPathsArray } from 'routes';
import { useLazyGetSkillsQuery, useLazyGetRegionsAndCountiesQuery } from 'api/services/filterEndpoints';
import DashboardIcon from 'assets/svg/DashboardIcon';

import NavElement from './NavElement';
import StyledAuthContainer from './AuthContainer.styles';

type PropsType = React.PropsWithChildren;

const AuthContainer: React.FC<PropsType> = ({ children }) => {
  const [triggerSkills] = useLazyGetSkillsQuery();
  const [triggerGetRegions] = useLazyGetRegionsAndCountiesQuery();

  useEffect(() => {
    triggerSkills({});
    triggerGetRegions({ location: 'Countries' });
    triggerGetRegions({ location: 'Regions' });
  }, [triggerGetRegions, triggerSkills]);

  return (
    <StyledAuthContainer>
      <div className="auth-container__header">
        <div className="auth-container__header__container">
          <div className="auth-container__header__container__navigation-area">
            <div className="auth-container__header__container__navigation-area__element">
              <DashboardIcon className="auth-container__header__container__navigation-area__element__icon" />
              <p className="auth-container__header__container__navigation-area__element__text">UpStat</p>
            </div>

            {navigateMenuPathsArray.map(({ path, Icon, title }, index) => (
              <NavElement
                key={index}
                path={path}
                Icon={Icon}
                title={title}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="auth-container__main-container">{children}</div>
    </StyledAuthContainer>
  );
};

export default AuthContainer;
