import { useAppDispatch } from 'store/hooks';
import storageUtil from 'utils/storageHelper';
import {
  resetFilters,
  setRequestSentFilters,
} from 'store/filter/filterSlice';

import Button from 'components/Button/Button';
import StyledEmptyState from './EmptyState.styles';

const texts = {
  title: 'No data for your asking',
  description: 'Please, try something else or check later',
  button: 'Reset Filters',
  resetSearchButton: 'Clear Search',
};

type PropsType = {
  currentSearch?: string;
  clearSearch?: () => void;
};

const EmptyState: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();

  const handleResetFilters = () => {
    if (props.currentSearch && props.clearSearch) {
      props.clearSearch();
      return;
    }

    storageUtil.dateRangeFrom.remove();
    storageUtil.dateRangeTo.remove();
    storageUtil.priceRangeFrom.remove();
    storageUtil.priceRangeTo.remove();
    storageUtil.fixedBudget.remove();
    storageUtil.mainFilters.remove();
    dispatch(resetFilters());
    dispatch(setRequestSentFilters(true));
  };

  return (
    <StyledEmptyState>
      <h3>{texts.title}</h3>
      <h4>{texts.description}</h4>
      <Button
        variant="outlined"
        onClick={handleResetFilters}
        className="empty-state-button"
      >
        {props.currentSearch ? texts.resetSearchButton : texts.button}
      </Button>
    </StyledEmptyState>
  );
};

export default EmptyState;
