import styled, { css } from 'styled-components';

type PropsType = {
  isInactive: boolean;
  isSelected: boolean;
};

export default styled.div<PropsType>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.5px;
  position: relative;
  cursor: pointer;
  width: 100%;
  gap: 8px;
  background-color: ${(props) => (props.isSelected ? props.theme.mainTheme.color.blue20 : 'white')};
  border-radius: 8px;

  :hover {
    ${(props) => !props.isInactive && css`
      background-color: ${({ theme }) => theme.mainTheme.color.blue20};
    `}
  }

  ${(props) => props.isInactive && css`
    color: ${({ theme }) => theme.mainTheme.color.gray30};
    pointer-events: none;
  `}

  .include-item {
    &__title {
      display: flex;
      align-items: center;
      column-gap: 8px;
    }

    &__info-icon {
      ${(props) => props.isInactive && css`
        pointer-events: auto;
        cursor: default;
      `}
    }

    &__hint {
      position: absolute;
      top: 37px;
      left: 0;
      max-width: 528px;
      width: max-content;
      background-color: ${({ theme }) => theme.mainTheme.color.blue20};
      border-radius: ${({ theme }) => theme.borderRadius};
      padding: 24px;
      font-size: 12px;
      color: black;
      font-weight: 300;
      z-index: 9;
      border: 1px solid #d8d8d8;
    }
  }

  @media (max-width: 1215px) {
    .include-item__hint {
      max-width: 547px;
    }
  }

  @media (max-width: 1115px) {
    .include-item__hint {
      max-width: calc(100vw - 100% - 48px - 15px);
    }
  }
`;
