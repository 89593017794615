import { useEffect, useMemo, useState } from 'react';
import { Line } from 'react-chartjs-2';
import type { ChartDataset, Point } from 'chart.js';

import { setGradientColorToLineGraph } from 'utils/setParamsHelper';

import Slider from 'components/Slider';
import EmptyState from 'components/EmptyState/EmptyState';
import StyledSection from './PriceLineGraphWithSlider.styles';

import options from '../PricesSection/pricesLineOptions';

type PropsType = {
  labels?: string[];
  datasets?: ChartDataset[];
};

const minDistance = 5;

const PriceLineGraphWithSlider: React.FC<PropsType> = ({ labels, datasets }) => {
  const [displayedRange, setDisplayedRange] = useState({
    labels, datasets,
  });

  const newDatasets = useMemo(() => {
    if (datasets) {
      const dataset = datasets.map((item) => {
        return setGradientColorToLineGraph(item as ChartDataset<'line', (number | Point | null)[]>);
      });

      return dataset;
    }

    return [];
  }, [datasets]);

  useEffect(() => {
    setDisplayedRange({ labels, datasets: newDatasets });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, datasets]);

  const [range, setRange] = useState([
    Number(labels && labels[0]) || 0,
    Number(labels && labels[labels.length - 1]) || 1000,
  ]);

  const handleChangeRange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
  ) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRange([Math.min(newValue[0], range[1] - minDistance), range[1]]);
    } else {
      setRange([range[0], Math.max(newValue[1], range[0] + minDistance)]);
    }
  };

  const handleEndOfChange = () => {
    const stepList = labels?.map((item) => Number(item));
    const startItem = stepList?.filter((item) => item >= range[0])[0];
    const upToMaxValueList = stepList?.filter((item) => item <= range[1]);

    if (!startItem || !upToMaxValueList) { return; }

    const endItem = upToMaxValueList[upToMaxValueList.length - 1];
    const endRange = labels?.indexOf(String(endItem));
    let startRange = labels?.indexOf(String(startItem));

    if (startRange && endRange && (endRange - startRange) === 1) {
      startRange -= 1;
    }

    const rangedData = {
      priceLabels: labels?.slice(startRange, (Number(endRange) + 1)),
      datasets: [
        {
          ...newDatasets[0],
          data: newDatasets[0].data.slice(startRange, (Number(endRange) + 1)),
        },
      ],
    };

    setDisplayedRange({ labels: rangedData.priceLabels, datasets: rangedData.datasets });
  };

  useEffect(() => {
    if (!labels || !datasets) { return; }
    handleEndOfChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels, datasets]);

  return (
    <StyledSection>
      { displayedRange.datasets?.[0]?.data?.length ? (
        <>
          <Slider
            min={Number(labels && labels[0]) || 0}
            max={Number(labels && labels[labels.length - 1]) || 1000}
            value={range}
            onChange={handleChangeRange}
            onChangeCommitted={handleEndOfChange}
          />
          <div className="chart-container">
            <Line
              options={options}
              data={{
                labels: displayedRange.labels,
                datasets: displayedRange.datasets as ChartDataset<'line', (number | Point | null)[]>[] || newDatasets,
              }}
            />
          </div>
        </>
      ) : (
      <EmptyState />
      )}
    </StyledSection>
  );
};

export default PriceLineGraphWithSlider;
