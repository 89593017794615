import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .section__buttons-area {
    display: flex;
    column-gap: 12px;
    padding-top: 4px;
  }

  .section__header-crown {
    width: 30px;
    height: 22px;
    margin-left: 13px;
  }
`;
