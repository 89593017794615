import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress } from '@mui/material';
import { Scrollbar } from 'react-scrollbars-custom';

import { NameFilterENUM } from 'types/filtersType';
import { isEqualArrays } from 'utils/arrayHelper';
import useDebounce from 'utils/useDebounce';
import useCheckMatchApplyedAndSavedFilters from 'utils/useCheckMatchApplyedAndSavedFilters';
import { setMainFiltersToStorage } from 'utils/storageFiltersHelper';
import { savedFiltersSelector } from 'store/filter/filterSelector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setMainFilters } from 'store/filter/filterSlice';

import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Input from 'components/Input';
import StyledIncludeSection from './ListSection.styles';
import type { SectionFilterType } from '../../FilterSection';
import useInitialList from './useInitialList';

type PropsType = {
  mainSectionInfo: SectionFilterType;
};

const ListSection: React.FC<PropsType> = ({ mainSectionInfo }) => {
  const [firstRender, setFirstRender] = useState(true);
  const [search, setSearch] = useState('');
  const currentSearch = useDebounce(search);
  const { data, isLoading, trigger } = useInitialList(mainSectionInfo.section);
  const filters = useAppSelector(savedFiltersSelector);
  const dispatch = useAppDispatch();
  const [selectedItems, setSelectedItems] = useState<string[]>(
    filters[mainSectionInfo.section][mainSectionInfo.type],
  );
  const isInactiveSave = useMemo(() => (isEqualArrays(
    filters[mainSectionInfo.section][mainSectionInfo.type],
    selectedItems,
  )), [filters, mainSectionInfo.section, mainSectionInfo.type, selectedItems]);
  const checkMatchApplyedAndSavedFilters = useCheckMatchApplyedAndSavedFilters();

  const findItem = useCallback((item: string) => {
    if (mainSectionInfo.section !== NameFilterENUM.SKILLS) {
      return item.toLowerCase().includes(search.toLowerCase());
    }
    return item.toLowerCase().includes(currentSearch.toLowerCase());
  }, [currentSearch, mainSectionInfo.section, search]);

  const currentArray = useMemo(() => {
    if (mainSectionInfo.section !== NameFilterENUM.SKILLS) {
      return data?.filter(findItem);
    }
    return data;
  }, [data, findItem, mainSectionInfo.section]);

  useEffect(() => {
    if (mainSectionInfo.section === NameFilterENUM.SKILLS && !firstRender) {
      trigger({ desiredRow: currentSearch });
    }
    setFirstRender(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSearch, trigger]);

  const isNothingFound = !currentArray?.length && Boolean(search.length);

  const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const onClearSearchClick = () => {
    setSearch('');
  };

  useEffect(() => {
    if (isInactiveSave) {
      return;
    }

    dispatch(setMainFilters({
      section: mainSectionInfo.section,
      type: mainSectionInfo.type,
      value: selectedItems,
    }));

    setMainFiltersToStorage({
      section: mainSectionInfo.section,
      type: mainSectionInfo.type,
      resultArray: selectedItems,
    });

    const saveChangesBeforeCloseModal = () => {
      return new Promise<void>((resolve) => {
        checkMatchApplyedAndSavedFilters();
        resolve();
      });
    };

    saveChangesBeforeCloseModal();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  const onChangeCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newArrayItems = [...selectedItems];
    const findIndex = newArrayItems.indexOf(event.target.name);
    if (findIndex !== -1) {
      newArrayItems.splice(findIndex, 1);
      setSelectedItems(newArrayItems);
    } else {
      setSelectedItems([...newArrayItems, event.target.name]);
    }
  };

  return (
    <StyledIncludeSection>
      <div className="list-section__container">
        <div>
          <Input
            nameInput="search"
            placeholder="Type to Search..."
            startAdornment={<SearchIcon />}
            value={search}
            onChangeValue={onChangeSearch}
          />

          <div className="list-section__container__list">
            {isNothingFound && (
              <div className="list-section__container__list__not-found-section">
                <h4>Nothing found</h4>

                <Button variant="text" onClick={onClearSearchClick}>Clear Search</Button>
              </div>
            )}

            {isLoading ? (
              <CircularProgress />
            ) : (
              Boolean(currentArray?.length) && (
                <Scrollbar className="scroll-position">
                  <div className="list-section__container__list-area">
                    {currentArray?.map((item, index) => (
                      <Checkbox
                        key={index}
                        name={item}
                        title={item}
                        checked={selectedItems.includes(item)}
                        onChange={onChangeCheckbox}
                        className="list-section__container__list-area__item"
                      />
                    ))}
                  </div>
                </Scrollbar>
              )
            )}
          </div>
        </div>
      </div>
    </StyledIncludeSection>
  );
};

export default ListSection;
