import type { ChartDataset } from 'chart.js';

import { convertToDoughnutData } from 'utils/setParamsHelper';
import type { FilterDisplayType } from 'types/filtersType';
import { baseApi } from 'api/baseApiBuilder';
import type { ChartType, ResponseMostPopularType } from '../../../types/apiTypes/statisticApiTypes';
import { MethodENUM, StatisticApiPathENUM } from '../../../types/apiTypes/statisticApiTypes';

const mostPopularSkillsEndpoints = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMostPopularSkills: builder.query<ChartType, {
      filters?: FilterDisplayType;
    }>({
      query: (body) => ({
        url: StatisticApiPathENUM.POPULAR_SKILLS_FOR_PERIOD,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['MostPopularSkills'],
      transformResponse: (response: ResponseMostPopularType[])
        : { labels: string[]; datasets: ChartDataset[] } => {
        return convertToDoughnutData(response);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetMostPopularSkillsQuery,
} = mostPopularSkillsEndpoints;
