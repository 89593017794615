import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;

  .loading-section {
    min-height: calc(100vh - 300px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jobs {
    &-header {
      display: grid;
      grid-template-columns: max-content max-content 1fr;
      gap: 20px;
      align-items: center;

      &__input {
        max-width: 530px;
      }

      &__filters-area {
        justify-self: end;
      }
    }

  }
`;
