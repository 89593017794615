import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  .sign-up {
    &__title {
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__form-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__remember-me-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__button-area {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
      }
    }

    &__last-section {
      width: 100%;
      display: flex;
      column-gap: 8px;
      align-items: center;
    }
  }

  .form-section__company-name {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .company-name__switch-area {
    display: flex;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .switch__text {
    font-size: 13px;
  }

  .switch__text--left {
    margin-left: 8px;
  }

  .switch__text--right {
    margin-right: 8px;
  }

  .MuiSwitch-thumb {
    box-shadow: none;
    transition: left 0.1s linear;
  }

  .MuiSwitch-colorPrimary  {
    & > .Mui-checked {
      background-color: ${({ theme }) => theme.mainTheme.color.blue50};
    }
  }

  .MuiSwitch-track {
    background-color: ${({ theme }) => theme.mainTheme.color.gray30};
  }

  .form-section__company-name {
    & .Mui-disabled {
      & .MuiOutlinedInput-notchedOutline {
        border-color: rgb(238, 242, 246);
      }
    }
  }
`;
