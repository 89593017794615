import { useMemo } from 'react';

import { jobsApi } from 'api/services/jobEndpoints';
import type { JobListItemType } from 'types/jobTypes';
import type { PaginationType } from 'types/filtersType';
import { setPagination } from 'store/filter/filterSlice';
import { useAppSelector, useAppDispatch } from 'store/hooks';

import Pagination from 'components/Pagination';
import EmptyState from 'components/EmptyState/EmptyState';
import JobItem from '../JobItem/JobItem';
import StyledMainContainer from './MainContainer.styles';

type PropsType = {
  currentSearch: string;
  clearSearch: () => void;
  onResetFilters: () => void;
  toggleJobModal: (jobId: number | null) => void;
  data?: JobListItemType[];
  meta?: PaginationType;
  className?: string;
};

const MainContainer: React.FC<PropsType> = (props) => {
  const pagination = useAppSelector((state) => state.filterSlice.pagination);
  const dispatch = useAppDispatch();

  const isEmpty = useMemo(() => {
    return !props.data?.length;
  }, [props.data]);

  const handleChangePage = (page: number) => {
    dispatch(setPagination({
      ...pagination,
      page,
    }));
    dispatch(jobsApi.util.resetApiState());
  };

  return (
    <StyledMainContainer className={props.className}>
      {isEmpty ? (
        <div className="empty-container">
          <EmptyState
            currentSearch={props.currentSearch}
            clearSearch={props.clearSearch}
          />
        </div>
      ) : (
        <>
          <div className="jobs-list">
            {props.data?.map((job) => (
              <JobItem
                key={job.feedItemId}
                job={job}
                onClick={props.toggleJobModal}
              />
            ))}
          </div>

          <Pagination
            onPageChange={handleChangePage}
            totalCount={pagination.totalPages}
            currentPage={pagination.page}
            pageSize={pagination.perPage}
          />
        </>
      )}
    </StyledMainContainer>
  );
};

export default MainContainer;
