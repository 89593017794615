import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import type { PathENUM } from 'routes';

import Button from 'components/Button';

import StyledNavToSection from './NavToSection.styles';

type PropsType = {
  title: string;
  titleButton: string;
  path: PathENUM;
};

const NavToSection: FC<PropsType> = ({ title, titleButton, path }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(path);
  };

  return (
    <StyledNavToSection>
      <p className="paragraph">{title}</p>

      <Button variant="text" onClick={handleClick}>{titleButton}</Button>
    </StyledNavToSection>
  );
};

export default NavToSection;
