import type { FC } from 'react';
import type { IIcon } from '../../types/commonTypes';

const FunnelIcon: FC<IIcon> = ({ className }) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M14 2H2V3.2759C2 3.74377 2.16403 4.19684 2.46356 4.55627L6.53644 9.44373C6.83597 9.80316 7 10.2562 7 10.7241V15L9 14V10.7241C9 10.2562 9.16403 9.80316 9.46356 9.44373L13.5364 4.55627C13.836 4.19684 14 3.74377 14 3.2759V2Z" fill="white" />
    </svg>
  );
};

export default FunnelIcon;
