import styled from 'styled-components';

export default styled.div`
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  .auth-container {
    &__header {
      z-index: 2;
      width: 100%;
      border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.blue20};

      &__container {
        min-height: 80px;
        width: 100%;
        padding: 20px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__navigation-area {
          display: grid;
          grid-template-columns: repeat(3, max-content) auto;
          column-gap: 32px;
          width: 100%;
          position: relative;

          &__element {
            text-decoration: none;
            display: flex;
            column-gap: 8px;
            align-items: center;
            position: relative;
            color: ${({ theme }) => theme.mainTheme.color.gray50};

            &--selected {
              :before {
                content: "";
                background-color: red;
                position: absolute;
                bottom: -24px;
                height: 2px;
                box-sizing: border-box;
                min-width: 1px;
                width: -webkit-fill-available;
                background: ${({ theme }) => theme.mainTheme.color.blue50};
                border-radius: 20px 20px 0 0;
              }
            }

            svg {
              color: ${({ theme }) => theme.mainTheme.color.gray30};
            }

            &__crown {
              margin-left: 5px;
            }

            &__text {
              font-weight: 700;
              font-size: 24px;
              line-height: 32.69px;
              color: ${({ theme }) => theme.mainTheme.color.gray50};
            }

            .selected-text {
              color: ${({ theme }) => theme.mainTheme.color.blue50};
            }

            .selected-icon {
              fill: ${({ theme }) => theme.mainTheme.color.blue50};
              color: ${({ theme }) => theme.mainTheme.color.blue50};
            }

            &:last-of-type {
              justify-self: end;
            }

            @media (max-width: 1380px) {
              h3 {
                display: none;
              }
            }

            @media (max-width: 1115px) {
              h3 {
                display: inline;
              }
            }
          }

        }

        .header-filter {
          @media (max-width: 1115px) {
            display: none;
          }
        }
      }
    }

    &__main-container {
      min-height: calc(100vh - 81px);
      height: 100%;
      width: 100%;
      padding: 22px 40px 40px 40px;
      background-color: ${({ theme }) => theme.mainTheme.color.blue10};
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 1115px) {
        padding: 48px 24px 40px 24px;
      }
    }
  }

`;
