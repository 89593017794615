import styled from 'styled-components';

export default styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.mainTheme.color.blue20};
  padding: 8px 12px;
  font-weight: 200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  .banner-version-link{
    color: ${({ theme }) => theme.mainTheme.color.blue50} !important;
    text-decoration: none;
  }

  .banner-version-close-button {
    padding: 0 8px;
  }
`;
