import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 560px;
  display: flex;
  column-gap: 16px;

  @media (max-width: 815px) {
    max-width: 100%;
  }

  .new-password-part {
    width: 100%;
    padding: 16px;

    &__form-section {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__button-area {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;
        column-gap: 8px;
      }
    }
  }
`;
