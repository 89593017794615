import styled from 'styled-components';

export default styled.div`
  color: ${({ theme }) => theme.mainTheme.color.gray30};
  display: flex;
  justify-content: space-between;

  .pagination-container {
    display: flex;
    list-style-type: none;
    gap: 8px;

    .pagination-item {
      border-radius: 8px;
      height: 38px;
      min-width: 38px;
      padding: 10px;
      text-align: center;
      display: flex;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;

      :hover {
        background-color: ${({ theme }) => theme.mainTheme.color.background};
        color: ${({ theme }) => theme.mainTheme.color.gray50};
        border: 1px solid ${({ theme }) => theme.mainTheme.color.gray30};
        cursor: pointer;
      }
    }

    .dots
    {
      :hover {
        color: ${({ theme }) => theme.mainTheme.color.gray30};
        border: none;
        background-color: transparent;
        cursor: default;
      }
    }

    .selected {
      background-color: ${({ theme }) => theme.mainTheme.color.blue20};
      border: 2px solid ${({ theme }) => theme.mainTheme.color.blue50};
      color: ${({ theme }) => theme.mainTheme.color.gray50};
    }

    .chevron {
      padding: 0;
      margin: 0;
    }

    &__chevrons-area {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }

  .pagination-chevron {
    color: ${({ theme }) => theme.mainTheme.color.gray50};
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
    }
  }

  .disabled {
    display: flex;
    align-items: center;
    pointer-events: none;
    color: ${({ theme }) => theme.mainTheme.color.gray30};

    :hover {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
`;
