import React from 'react';

import StyledGeneralSection from './GeneralSection.styles';
import CommentSection from './components/CommentSection';
import InfoSection from './components/InfoSection/InfoSection';

const GeneralSection: React.FC = () => {
  return (
    <StyledGeneralSection>
      <InfoSection />
      <CommentSection />
    </StyledGeneralSection>
  );
};

export default GeneralSection;
