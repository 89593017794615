import styled from 'styled-components';

export default styled.div<{isCollapsed: boolean}>`
  grid-column: 2;
  display: grid;
  grid-template-rows: repeat(2, auto);
  row-gap: 8px;

  .filter-collapse {
    &__collapse-btn {
      grid-row: 2;

      &__icon {
        transform: rotate(${(p) => (p.isCollapsed ? '0' : '180deg')});
        transition: all 200ms ease-in-out;
      }
    }
  }

  .MuiIconButton-root {
    padding: 0;
  }

`;
