import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import type {
  PaginationType,
  DateRangeNameENUM,
  NameFilterENUM,
  PriceRangeNameENUM,
  TypeFilterENUM,
} from 'types/filtersType';
import { jobsApi } from '../../api/services/jobEndpoints';
import type { FilterSliceType } from './filterSliceType';

export const initialStateFilters: FilterSliceType = {
  filters: {
    selectedSkills: {
      only: {
        saved: [],
        applied: [],
      },
      exclude: {
        saved: [],
        applied: [],
      },
    },
    selectedRegions: {
      only: {
        saved: [],
        applied: [],
      },
      exclude: {
        saved: [],
        applied: [],
      },
    },
    selectedCountries: {
      only: {
        saved: [],
        applied: [],
      },
      exclude: {
        saved: [],
        applied: [],
      },
    },
    dateRange: {
      from: {
        saved: null,
        applied: null,
      },
      to: {
        saved: null,
        applied: null,
      },
    },
    priceRange: {
      from: {
        saved: null,
        applied: null,
      },
      to: {
        saved: null,
        applied: null,
      },
      fixedBudget: {
        saved: null,
        applied: null,
      },
    },
  },

  pagination: {
    totalPages: 100,
    page: 1,
    perPage: 20,
  },

  search: '',

  relatedSkill: null,

  isApplied: true,

  isRequestSent: false,

  canResetFilters: false,
};

export const filterSlice = createSlice({
  name: 'filterSlice',
  initialState: initialStateFilters,
  reducers: {
    resetFilters: () => {
      return initialStateFilters;
    },
    setMainFilters: (
      state,
      action: PayloadAction<{
        section: NameFilterENUM;
        type: TypeFilterENUM;
        value: string[];
      }>,
    ) => {
      if (!action) {
        return;
      }
      state.filters[action.payload.section][action.payload.type].saved = action.payload.value;
    },
    setAppliedMainFilters: (
      state,
      action: PayloadAction<{
        section: NameFilterENUM;
        type: TypeFilterENUM;
        value: string[];
      }>,
    ) => {
      if (!action) {
        return;
      }
      state.filters[action.payload.section][action.payload.type].applied = action.payload.value;
      state.filters[action.payload.section][action.payload.type].saved = action.payload.value;
    },
    setAppliedFilters: (
      state,
    ) => {
      state.filters.selectedSkills.only.applied = state.filters.selectedSkills.only.saved;
      state.filters.selectedSkills.exclude.applied = state.filters.selectedSkills.exclude.saved;
      state.filters.selectedRegions.only.applied = state.filters.selectedRegions.only.saved;
      state.filters.selectedRegions.exclude.applied = state.filters.selectedRegions.exclude.saved;
      state.filters.selectedCountries.only.applied = state.filters.selectedCountries.only.saved;
      state.filters.selectedCountries.exclude.applied =
        state.filters.selectedCountries.exclude.saved;

      state.filters.dateRange.from.applied = state.filters.dateRange.from.saved;
      state.filters.dateRange.to.applied = state.filters.dateRange.to.saved;

      state.filters.priceRange.from.applied = state.filters.priceRange.from.saved;
      state.filters.priceRange.to.applied = state.filters.priceRange.to.saved;
      state.filters.priceRange.fixedBudget.applied = state.filters.priceRange.fixedBudget.saved;
    },
    setDateRange: (
      state,
      action: PayloadAction<{
        name: DateRangeNameENUM;
        value: string | null;
      }>,
    ) => {
      state.filters.dateRange[action.payload.name].saved = action.payload.value;
    },
    setAppliedDateRange: (
      state,
      action: PayloadAction<{
        name: DateRangeNameENUM;
        value: string | null;
      }>,
    ) => {
      state.filters.dateRange[action.payload.name].applied = action.payload.value;
      state.filters.dateRange[action.payload.name].saved = action.payload.value;
    },
    setDateRangeFilters: (
      state,
      action: PayloadAction<{ from: string | null; to: string | null }>,
    ) => {
      state.filters.dateRange.from.saved = action.payload.from;
      state.filters.dateRange.to.saved = action.payload.to;
    },
    setAppliedDateRangeFilters: (
      state,
      action: PayloadAction<{ from: string | null; to: string | null }>,
    ) => {
      state.filters.dateRange.from.applied = action.payload.from;
      state.filters.dateRange.to.applied = action.payload.to;
      state.filters.dateRange.from.saved = action.payload.from;
      state.filters.dateRange.to.saved = action.payload.to;
    },
    setPriceRange: (
      state,
      action: PayloadAction<{
        name: PriceRangeNameENUM;
        value: string | null;
      }>,
    ) => {
      state.filters.priceRange[action.payload.name].saved = action.payload.value;
    },
    setAppliedPriceRange: (
      state,
      action: PayloadAction<{
        name: PriceRangeNameENUM;
        value: string | null;
      }>,
    ) => {
      state.filters.priceRange[action.payload.name].applied = action.payload.value;
      state.filters.priceRange[action.payload.name].saved = action.payload.value;
    },
    setPriceRangeFilters: (
      state,
      action: PayloadAction<{
        from: string | null;
        to: string | null;
        fixedBudget: string | null;
      }>,
    ) => {
      state.filters.priceRange.from.saved = action.payload.from;
      state.filters.priceRange.to.saved = action.payload.to;
      state.filters.priceRange.fixedBudget.saved = action.payload.fixedBudget;
    },
    setAppliedPriceRangeFilters: (
      state,
      action: PayloadAction<{
        from: string | null;
        to: string | null;
        fixedBudget: string | null;
      }>,
    ) => {
      state.filters.priceRange.from.applied = action.payload.from;
      state.filters.priceRange.to.applied = action.payload.to;
      state.filters.priceRange.fixedBudget.applied = action.payload.fixedBudget;
      state.filters.priceRange.from.saved = action.payload.from;
      state.filters.priceRange.to.saved = action.payload.to;
      state.filters.priceRange.fixedBudget.saved = action.payload.fixedBudget;
    },
    setRelatedSkill: (state, action: PayloadAction<string>) => {
      state.relatedSkill = action.payload;
    },
    setIsApplyFilters: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isApplied = action.payload;
    },
    setRequestSentFilters: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isRequestSent = action.payload;
    },
    setPagination: (
      state,
      action: PayloadAction<PaginationType>,
    ) => {
      state.pagination = action.payload;
    },
    setCanResetFilters: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.canResetFilters = action.payload;
    },
    setSearch: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(jobsApi.endpoints.getJobs.matchFulfilled, (state, { payload }) => {
      state.pagination = {
        ...state.pagination,
        perPage: payload.meta.perPage,
        totalPages: payload.meta.totalPages,
      };
    });
  },
});

export const {
  resetFilters,
  setMainFilters,
  setAppliedMainFilters,
  setDateRange,
  setAppliedDateRange,
  setDateRangeFilters,
  setAppliedDateRangeFilters,
  setPriceRange,
  setAppliedPriceRange,
  setPriceRangeFilters,
  setAppliedPriceRangeFilters,
  setAppliedFilters,
  setRelatedSkill,
  setIsApplyFilters,
  setRequestSentFilters,
  setPagination,
  setCanResetFilters,
  setSearch,
} = filterSlice.actions;

export default filterSlice.reducer;
