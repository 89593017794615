import styled from 'styled-components';

export default styled.div`
  .include-section {
    width: 224px;
    background-color: ${({ theme }) => theme.mainTheme.color.background};
    display: flex;
    width: 100%;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.mainTheme.color.blue20};
    border-radius: 9px;
  }
`;
