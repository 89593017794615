import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24.52px;
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }
`;
