import type { ChartDataset } from 'chart.js';

export type ResponseMostPopularType = {
  popularity_rank: number;
  related_feed_items: number;
  skill: string;
};

export type MostPopularDataType = {
  data: ResponseMostPopularType[];
  message: string;
  meta: unknown;
};

export enum LocationENUM {
  REGION = 'byRegion',
  COUNTRY = 'byCountry',
}

export type LocationType = Record<LocationENUM, Record<string, number>>;

export type LocationDataType = {
  data: LocationType;
  message: string;
  meta: unknown;
};

export type ChartType = {
  labels: string[];
  datasets: ChartDataset[];
};

export type SkillType = {
  label: string;
  value: number;
};

export type FilterDataType = {
  data: SkillType[] | null;
  message: string;
  meta: unknown;
};

export enum MethodENUM {
  GET = 'get',
  POST = 'post',
}

export enum StatisticApiPathENUM {
  GET_SKILLS = '/api/v1/feed/statistics/skill-list',
  GET_REGIONS_AND_COUNTRIES = '/api/v1/feed/statistics/countries-and-regions-list',
  POPULAR_SKILLS_FOR_PERIOD = '/api/v2/statistics/most-popular-skills',
  GET_TRENDS = '/api/v2/statistics/trends',
  GET_LOCATION = '/api/v2/statistics/jobs-by-country',
  GET_TIME_LINE_HOUR = '/api/v2/statistics/timeline/by-hour',
  GET_TIME_LINE_DOW = '/api/v2/statistics/timeline/by-day-of-week',
  GET_UP_TO_PRICES = '/api/v2/statistics/rate/by-max',
  GET_FROM_MIN_PRICES = '/api/v2/statistics/rate/by-min',
  GET_PRICES_RANGES = '/api/v2/statistics/rate/by-ranges',
  GET_FIXED_BUDGET = '/api/v2/statistics/rate/by-fixed-budget',
}
