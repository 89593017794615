export default (value: number) => {
  if (value < 1000) {
    return value.toString();
  }
  return `${(value / 1000)}k`;
};

export const formatGraphAxisValues = (value: string | number) => {
  if (value === 0) {
    return '';
  }
  if (+value < 1000) {
    return value;
  }
  if ((+value % 1000) < 450) {
    return `${(+value / 1000)}k`;
  }
  return `${(+value / 1000).toFixed(1)}k`;
};

export const formatNumbersAfterOne = (value: string | number) => {
  return +value % 2 === 0 ? value : '';
};

const arrayWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const formatDayOfWeek = (value: string | number) => {
  return arrayWeek[+value];
};
