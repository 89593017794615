export type SelectedType = {
  only: string[];
  exclude: string[];
};

export enum DateRangeNameENUM {
  FROM = 'from',
  TO = 'to',
}

export enum PriceRangeNameENUM {
  FROM = 'from',
  TO = 'to',
  FIXED_BUDGET = 'fixedBudget',
}

export type DateRangeType = Record<DateRangeNameENUM, Record<ReadinessENUM, string | null>>;

export type PriceRangeType = Record<PriceRangeNameENUM, Record<ReadinessENUM, string | null>>;

export type DateRangeDisplayType = Record<DateRangeNameENUM, string | null>;

export type PriceRangeDisplayType = Record<PriceRangeNameENUM, string | null>;

export enum NameFilterENUM {
  SKILLS = 'selectedSkills',
  REGIONS = 'selectedRegions',
  COUNTRIES = 'selectedCountries',
}

export enum TypeFilterENUM {
  ONLY = 'only',
  EXCLUDE = 'exclude',
}

export enum ReadinessENUM {
  SAVED = 'saved',
  APPLIED = 'applied',
}

export enum PeriodENUM {
  WEEK = 'Week',
  MONTH = 'Month',
  DAY = 'Day',
}

export enum SkillsLimitENUM {
  TEN = 10,
  TWENTY = 20,
  THIRTY = 30,
}

export enum TileLinePeriodENUM {
  HOUR = 'hour',
  DAY = 'day',
}

export type FilterType = Record<
  NameFilterENUM,
  Record<TypeFilterENUM, Record<ReadinessENUM, string[]>>
> & {
  dateRange: DateRangeType;
  priceRange: PriceRangeType;
};

export type PaginationType = {
  page: number;
  perPage: number;
  totalPages: number;
};

export type FilterDisplayType = Record<
  NameFilterENUM,
  Record<TypeFilterENUM, string[]>
> & {
  dateRange: DateRangeDisplayType;
  priceRange: PriceRangeDisplayType;
};
