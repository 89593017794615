import styled from 'styled-components';

export default styled.div`
  width: 100%;
  max-width: 560px;
  display: flex;

  @media (max-width: 815px) {
    max-width: 100%;
  }

  .container {
    padding: 16px;
  }

  .form-section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__button-area {
      width: 100%;
      display: flex;
      justify-content: end;
      column-gap: 12px;
    }
  }
  
  .MuiPaper-root {
    width: inherit;
    
  }
`;
