const incorrectSymbols = {
  '&amp;': '&',
  '&oacute;': 'ó',
  '&nbsp;': ' ',
  '&#039;': '\'',
  '&rsquo;': '\'',
  '&ndash;': '–',
  '&bull;': '•',
  '&ldquo;': '"',
  '&rdquo;': '"',
  '&egrave;': 'è',
  '&euro;': '€',
  '&agrave;': 'à',
  '&eacute;': 'é',
  '&iuml;': 'ï',
  '&uuml': 'ü',
  '&ouml;': 'ö',
  '&ccedil;': 'ç',
  '&mdash;': '—',
  '&lsquo;': '\'',
  '&ntilde;': 'ñ',
  '&iacute;': 'í',
  '&aacute;': 'á',
  '&uacute;': 'ú',
  '&hellip;': '...',
  '&auml;': 'ä',
  '&atilde;': 'ã',
  '&Ccedil;': 'Ç',
  '&Atilde;': 'Ã',
  '&Agrave;': 'À',
  '&Uacute;': 'Ú',
  '&Ecirc;': 'Ê',
  '&Eacute;': 'É',
  '&otilde;': 'õ',
  '&ensp;': ' ',
  '&Otilde;': 'Õ',
  '&Ouml;': 'Ö',
  '&zwj;': '',
  '&Ntilde;': 'Ñ',
  '&ecirc;': 'ê',
  '&times;': 'x',
  '&quot;': '"',
  '&middot;': '·',
  '&szlig;': 'ß',
  '&bdquo;': '"',
  '&acirc;': 'â',
  '&Uuml;': 'Ü',
  '&alpha;': 'α',
  '&beta;': 'β',
  '&aring;': 'å',
  '&pound;': '£',
  '&acute;': '\'',
  '&deg;': '°',
  '&iexcl;': '¡',
  '&iquest;': '¿',
  '&rarr;': '→',
  '&Auml;': 'Ä',
  '&trade;': '™',
  '&Egrave;': 'È',
  '&plusmn;': '±',
  '&Oslash;': 'Ø',
  '&Iacute;': 'Í',
  '&ograve;': 'ò',
  '&copy;': '©',
  '&ugrave;': 'ù',
  '&icirc;': 'î',
  '&reg;': '®',
  '&diams;': '♦',
  '&ocirc;': 'ô',
  '&Sigma;': 'Σ',
  '&laquo;': '<<',
  '&raquo;': '>>',
  '&omega;': 'ω',
  '&igrave;': 'ì',
  '&minus;': '-',
  '&oslash;': 'ø',
  '&aelig;': 'æ',
  '&ucirc;': 'û',
  '&Theta;': 'Θ',
  '&sigma;': 'σ',
  '&sigmaf;': 'ς',
  '&delta;': 'δ',
  '&omicron;': 'o',
  '&theta;': 'θ',
  '&nu;': 'ν',
  '&pi;': 'π',
  '&lambda;': 'λ',
  '&mu;': 'μ',
  '&iota;': 'i',
  '&kappa;': 'κ',
  '&rho;': 'ρ',
  '&tau;': 'τ',
  '&epsilon;': 'ε',
  '&xi;': 'ξ',
  '&euml;': 'ë',
  '&yen;': '¥',
  '&oelig;': 'œ',
  '&Aacute;': 'Á',
  '&yacute;': 'ý',
  '&ordm;': 'º',
  '&Epsilon;': 'E',
  '&gamma;': 'γ',
  '&Oacute;': 'Ó',
  '&ge;': '≥',
  '&frac12;': '½',
  '&prime;': "'",
  '&Prime;': '"',
  '&Tau;': 'T',
  '&asymp;': '≈',
  '&hearts;': '♥',
  '&shy;': ' ',
  '&scaron;': 'š',
  '&uml;': '¨',
  '&cent;': '¢',
  '&lrm;': ' ',
  '&sbquo;': ',',
  '&emsp;': ' ',
  '&rlm;': ' ',
  '&darr;': '↓',
  '&sdot;': '⋅',
  '&sup2;': '²',
  '&Icirc;': 'Î',
  '&brvbar;': '¦',
  '&sup1;': '¹',
  '&dArr;': '⇓',
  '&eta;': 'η',
  '&chi;': 'χ',
  '&upsilon;': 'υ',
  '&Aring;': 'Å',
  '&sect;': '§',
  '&lt;': '<',
  '&dagger;': '†',
  '&larr;': '←',
  '&Iota;': 'I',
  '&gt;': '>',
  '&le;': '≤',
  '&Mu;': 'M',
  '&Chi;': 'X',
  '&zeta;': 'ζ',
  '&phi;': 'φ',
  '&Kappa;': 'K',
  '&Beta;': 'B',
  '&divide;': '÷',
  '&thinsp;': ' ',
  '&Delta;': 'Δ',
  '&frac14;': '¼',
  '&Omicron;': 'O',
  '&Ograve;': 'Ò',
  '&Lambda;': 'Λ',
  '&part;': '∂',
  '&zwnj;': ' ',
  '&sup3;': '3',
  '&Omega;': 'Ω',
  '&rArr;': '⇒',
  '&Scaron;': 'Š',
  '&Rho;': 'P',
  '&Alpha;': 'A',
  '&Zeta;': 'Z',
  '&Ocirc;': 'Ô',
  '&Acirc;': 'Â',
  '&harr;': '↔',
  '&circ;': 'ˆ',
  '&infin;': '∞',
  '&frac34;': '¾',
  '&Eta;': 'H',
  '&equiv;': '≡',
  '&macr;': '¯',
  '&Pi;': 'Π',
  '&psi;': 'ψ',
  '&Phi;': 'Φ',
  '&Gamma;': 'Γ',
  '&Nu;': 'N',
  '&Upsilon;': 'Y',
  '&uarr;': '↑',
  '&ordf;': 'ª',
  '&sum;': '∑',
  '&AElig;': 'Æ',
  '&micro;': 'µ',
  '&otimes;': '⊗',
  '&radic;': '√',
  '&lowast;': '*',
  '&Yacute;': 'Ý',
  '&loz;': '◊',
  '&THORN;': 'Þ',
  '&eth;': 'ð',
  '&frasl;': '/',
  '&Psi;': 'Ψ',
  '&yuml;': 'ÿ',
  '&Igrave;': 'Ì',
  '&isin;': '∈',
  '&not;': '¬',
  '&thorn;': 'þ',
  '&cap;': '∩',
  '&Euml;': 'Ë',
  '&Dagger;': '‡',
  '&spades;': '♠',
  '&para;': '¶',
  '&Ugrave;': 'Ù',
  '&Xi;': 'Ξ',
  '&curren;': '¤',
  '&rsaquo;': '>',
  '&sim;': '~',
  '&ETH;': 'Ð',
  '&cedil;': ',',
  '&sube;': '⊆',
  '&cup;': 'u',
  '&ne;': '≠',
  '&int;': '∫',
};

export default (oldString: string) => {
  let newString = oldString;

  Object.keys(incorrectSymbols).forEach((key) => {
    if (newString.includes(key)) {
      newString = newString.replace(
        new RegExp(key, 'g'),
        incorrectSymbols[key as keyof typeof incorrectSymbols],
      );
    }
  });

  return newString;
};
