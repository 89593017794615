import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;

  .divider {
    display: flex;
    align-items: center;
    padding: 16px 0 32px 0;
    
    &__line {
      height: 1px;
      background-color: ${({ theme }) => theme.mainTheme.color.blue30};
      width: 100%;
    }
    &__text {
      white-space: nowrap;
      padding: 4px 8px;
      font-family: ${({ theme }) => theme.font.family};
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.32px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.mainTheme.color.blue40};
    }
  }

  .grid-with-dif-height {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(3, auto);

    .first {
      grid-column: 1/6;
    }
    .second {
      grid-column: 6/13;
      grid-row: 1/3;
    }
    .third {
      grid-column: 1/6;
      grid-row: 2/4;
    }
    .fourth {
      grid-column: 6/13;
    }

    @media (max-width: 1199px) {
      grid-template-rows: auto;

      .first {
        grid-column: 1/13;
        grid-row: 1;
      }

      .second {
        grid-column: 1/13;
        grid-row: 2;
      }

      .third {
        grid-column: 1/13;
        grid-row: 3;
      }

      .fourth {
        grid-column: 1/13;
        grid-row: 4;
      }
    }
  }
`;
