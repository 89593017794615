import styled from 'styled-components';

export default styled.div`
  .chart-section {
    height: 260px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .loading-section {
    height: 260px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .chart-section__chart {
    height: 200px;
  }
`;
