import FormControl from '@mui/material/FormControl';
import styled, { css } from 'styled-components';

type PropsType = {
  iswithstarticon: boolean;
  iserrordisplay?: boolean;
  isdisabled?: boolean;
};

export default styled(FormControl) <PropsType>`
  width: 100%;

  .input-icon {
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }

  && .MuiIconButton-root {
    padding: 16px;
  }

  .MuiOutlinedInput-root {
    padding: 0;
    border-radius: ${({ theme }) => theme.borderRadius};
    font-family: ${({ theme }) => theme.font.family};

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid ${({ theme }) => theme.mainTheme.color.blue20};
      border-width: 1px !important;

      :focus {
        border: 1px solid ${({ theme }) => theme.mainTheme.color.blue50};
      }
    }

    .MuiOutlinedInput-input {
      padding: 16px;
      ${(props) => props.iswithstarticon && 'padding-left: 48px;'}
      font-family: ${({ theme }) => theme.font.family};
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: ${({ theme }) => theme.mainTheme.color.gray50};

      ::placeholder {
        color: ${({ theme }) => theme.mainTheme.color.gray30};
      }
    }

    :hover {
      ${(props) => !props.iserrordisplay && !props.isdisabled && css`
        .MuiOutlinedInput-notchedOutline {
          border-color: ${({ theme }) => theme.mainTheme.color.blue50};
        }
      `}
    }


    .MuiInputAdornment-positionEnd {
      right: 16px;
      position: absolute;
    }

    .MuiSvgIcon-root {
      ${(props) => props.iswithstarticon && css`
        padding: 16px;
        position: absolute;
      `};
    }
  }

  .MuiFormLabel-colorPrimary {
    font-family: ${({ theme }) => theme.font.family};
    color: ${({ theme }) => theme.mainTheme.color.gray30};

    :focus {
      color: ${({ theme }) => theme.mainTheme.color.blue50};
    }
  }
`;
