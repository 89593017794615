import type { FC } from 'react';
import type { IIcon } from '../../types/commonTypes';

const InfoIcon: FC<IIcon> = (props) => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    <path d="M11.9965 24.019C10.4168 24.0216 8.85217 23.7118 7.39274 23.1072C5.93332 22.5026 4.60792 21.6153 3.49288 20.4963C2.37784 19.3773 1.49518 18.0488 0.895745 16.5872C0.29631 15.1257 -0.00806094 13.56 0.000162283 11.9803C0.0177416 5.30178 5.33949 -0.00558901 12.0069 4.41711e-06C18.6934 0.00799503 24.0255 5.35371 24 12.0243C23.9744 18.6948 18.6287 24.0374 11.9965 24.019ZM12.0764 1.32565C10.6712 1.32124 9.27892 1.59365 7.97902 2.12731C6.67912 2.66098 5.49707 3.44545 4.50034 4.43594C3.50362 5.42643 2.71174 6.60355 2.16994 7.90007C1.62813 9.1966 1.34699 10.5871 1.34259 11.9923C1.33818 13.3975 1.61058 14.7898 2.14425 16.0897C2.67791 17.3896 3.46239 18.5716 4.45288 19.5684C5.44337 20.5651 6.62049 21.357 7.91701 21.8988C9.21354 22.4406 10.6041 22.7217 12.0093 22.7261C17.9471 22.7445 22.7223 17.9733 22.7343 12.0099C22.7462 6.0465 18.0414 1.33204 12.0764 1.32485V1.32565Z" fill="#C8D0D9" />
    <path d="M10.9792 13.95C10.9792 12.9607 10.9792 11.9723 10.9792 10.9839C10.9792 10.4501 11.2853 10.1456 11.8095 10.144C12.3073 10.144 12.6381 10.4517 12.6405 10.9655C12.6474 12.9834 12.6474 15.001 12.6405 17.0184C12.6405 17.529 12.3057 17.8238 11.7951 17.8174C11.2845 17.811 10.9816 17.4978 10.98 16.98C10.9768 15.9676 10.9792 14.9576 10.9792 13.95Z" fill="#C8D0D9" />
    <path d="M11.863 8.41069C11.2677 8.41069 10.9121 8.06389 10.9185 7.49656C10.9249 6.96918 11.3085 6.62318 11.8814 6.62798C12.4543 6.63277 12.8307 6.99235 12.8331 7.52133C12.8355 8.07588 12.4679 8.41308 11.863 8.41069Z" fill="#C8D0D9" />
    </svg>
  );
};

export default InfoIcon;
