import styled from 'styled-components';

export default styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 24px;
  justify-content: space-between;
  padding: 16px 32px 24px;

  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.mainTheme.color.blue30};
  }

  .job-item {
    &__title-area {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 6px;

      &__category {
        font-weight: 600;
        color: ${({ theme }) => theme.mainTheme.color.blue50};
      }
    }
     
    &__skills-area {
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      align-items: center;
      justify-content: end;

      .skill-plate {
        padding: 8px 12px;
        background-color: ${({ theme }) => theme.mainTheme.color.blue20};
        border-radius: 8px;

        h4 {
          font-weight: 600;
        }
      }
    }
  }
`;
