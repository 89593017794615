import React from 'react';

import { useAppSelector } from 'store/hooks';
import { savedFiltersSelector } from 'store/filter/filterSelector';

import type { NameFilterENUM, TypeFilterENUM } from 'types/filtersType';
import IncludeItem from '../IncludeItem/IncludeItem';
import StyledIncludeSection from './IncludeSection.styles';
import type { SectionFilterType } from '../../FilterSection';

type PropsType = {
  onClick: (section: SectionFilterType) => void;
  filtersArr: SectionFilterType[];
  selectedSectionType: TypeFilterENUM;
};

const IncludeSection: React.FC<PropsType> = ({ onClick, filtersArr, selectedSectionType }) => {
  const filters = useAppSelector(savedFiltersSelector);

  const isActiveSection = (options: { section: NameFilterENUM; type: TypeFilterENUM }) => {
    let isActive;

    Object.keys(filters[options.section]).forEach((item) => {
      if (item !== options.type) {
        isActive = !filters[options.section][item as TypeFilterENUM].length;
      }
    });
    return isActive;
  };

  return (
    <StyledIncludeSection>
      <div className="include-section">
        {filtersArr.map((item, index) => (
          <IncludeItem
            key={index}
            title={item.title}
            itemType={item}
            isSelected={item.type === selectedSectionType}
            isInactive={!isActiveSection({ section: item.section, type: item.type })}
            isHasInfoIcon={!!item.hintText}
            hintText={item.hintText}
            className="include-section__item"
            onClick={onClick}
          />
        ))}
      </div>
    </StyledIncludeSection>
  );
};

export default IncludeSection;
