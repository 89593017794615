import styled from 'styled-components';

export default styled.div`
  .little-section {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }

  .title-area__link {
    color: ${({ theme }) => theme.mainTheme.color.blue50};
  }

  .dialog {
    &__title-area {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      &__actions {
        padding: 0;
        align-self: start;
      }
    }

    &__main-area {
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &__description {
        width: 100%;
      }

      &__section {
        display: flex;
        column-gap: 32px;

        .bold {
          font-weight: 600;
        }
      }

      &__skills-area {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        .skill-plate {
          padding: 8px 12px;
          background-color: ${({ theme }) => theme.mainTheme.color.blue20};
          border-radius: 8px;

          h4 {
            font-weight: 600;
          }
        }
      }
    }
  }
`;
