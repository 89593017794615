import React from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import clsx from 'clsx';
import type { LegendItem } from 'chart.js';

type PropsType = {
  item: LegendItem;
  onClickItem: (item: LegendItem) => void;
};

const CustomLegendItem: React.FC<PropsType> = ({ item, onClickItem }) => {
  const onClickLI = () => {
    onClickItem(item);
  };

  return (
    <li
      className="legend__li"
      onClick={onClickLI}
    >
      <div className="legend__text-area">
        <span
          className="legend__box"
          style={{ backgroundColor: `${item.fillStyle}` }}
        />

        <h4 className={clsx(['legend__text', { legend__text_hidden: item.hidden }])}>
          {item.text}
        </h4>
      </div>

      {item.hidden ? (
        <VisibilityOffIcon className="legend__svg-container" />
      ) : (
        <VisibilityIcon className="legend__svg-container" />
      )}

    </li>
  );
};

export default CustomLegendItem;
