import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .section__buttons-area {
    display: flex;
    column-gap: 12px;

    .MuiButton-textPrimary {
      padding: 8px 12px;
      color: ${({ theme }) => theme.mainTheme.color.gray30};
      text-transform: lowercase;
    }

    .MuiButton-root {
      text-transform: lowercase;
      padding: 8px 12px;
    }
  }

  .without-content-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${({ theme }) => theme.mainTheme.color.gray40};
    }
  }

  .chart-container {
    height: 245px;
  }
`;
