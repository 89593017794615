import type { ChartDataset } from 'chart.js';
import { convertMultiBarGraphParamsToDataset } from 'utils/setParamsHelper';
import type { FilterDisplayType } from 'types/filtersType';
import { baseApi } from 'api/baseApiBuilder';
import { MethodENUM, StatisticApiPathENUM } from '../../../types/apiTypes/statisticApiTypes';
import type { ChartType } from '../../../types/apiTypes/statisticApiTypes';

const timeLineApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTimeLineByHour: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_TIME_LINE_HOUR,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['TimeLineByHour'],
      transformResponse: (response)
        : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
        return convertMultiBarGraphParamsToDataset(response as { [s: number]: number });
      },
    }),

    getTimeLineByWeek: builder.query<ChartType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_TIME_LINE_DOW,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['TimeLineByWeek'],
      transformResponse: (response)
        : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
        return convertMultiBarGraphParamsToDataset(response as { [s: number]: number });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTimeLineByHourQuery,
  useLazyGetTimeLineByWeekQuery,
} = timeLineApi;
