import React, { useMemo, useState } from 'react';
import type { Chart, ChartDataset, ChartOptions, LegendItem } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { convertDoughnutGraphParamsToDataset } from 'utils/setParamsHelper';
import CustomLegend from 'components/CustomLegend';
import StyledDoughnutChart from './DoughnutChart.styles';
import InsideCount from './InsideCount';

type PropsType = {
  permanentTrackY?: boolean;
  legendId: string;
  countId: string;
  className?: string;
  labels?: string[];
  datasets?: ChartDataset[];
  isChartDataVisible?: boolean;
  datasetsColors?: string[];
  onClickDoughnut?: (legendItem: string) => void;
  updateDatasetsColors?: (colors: string[]) => void;
};

const DoughnutChart: React.FC<PropsType> = (props) => {
  const [legendArray, setLegendArray] = useState<LegendItem[] | undefined>([]);
  const [currentChart, setCurrentChart] = useState<Chart>();
  const [totalCount, setTotalCount] = useState<number>();

  const options: ChartOptions<'doughnut'> = useMemo(() => ({
    aspectRatio: 2.7,
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'point',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    onClick: (evt, item) => {
      if (!item.length) { return; }

      if (props.labels) {
        props.onClickDoughnut?.(props.labels[item[0].index]);
      }
    },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [props.labels, props.onClickDoughnut]);

  const newDatasets = useMemo(() => {
    if (props.datasets) {
      return props.datasets.map((item) => {
        return convertDoughnutGraphParamsToDataset(
          item as ChartDataset<'doughnut', (number)[]>,
          props.datasetsColors,
        );
      });
    }

    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.datasets]);

  const doughnutPlugin = (chart: Chart) => {
    setCurrentChart(chart);
    const list = chart.options.plugins?.legend?.labels?.generateLabels?.(chart);
    setLegendArray(list);
    if (chart.config.data.datasets.length) {
      const centerConfig = chart.config.data.datasets[0].data as number[];
      const count: number = centerConfig?.reduce(
        (accumulator: number, item: number, index: number) => {
          if (list && list[index].hidden) {
            return accumulator;
          }
          return accumulator + item;
        }, 0,
      );
      setTotalCount(count);
    } else {
      setTotalCount(0);
    }
  };

  return (
    <StyledDoughnutChart className={props.className}>
      <div className="doughnut-container">
        <Doughnut
          options={options}
          data={{ labels: props.labels, datasets: newDatasets }}
          plugins={[{
            id: 'htmlLegend',
            afterUpdate: doughnutPlugin,
          }]}
        />

        <InsideCount id={props.countId} count={totalCount} />
      </div>

      <CustomLegend
        id={props.legendId}
        chart={currentChart}
        legendArray={legendArray}
        permanentTrackY={props.permanentTrackY}
        isChartDataVisible={props.isChartDataVisible}
      />
    </StyledDoughnutChart>
  );
};

export default DoughnutChart;
