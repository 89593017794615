import styled from 'styled-components';

export default styled.div`
  .list-section {
    &__container {
      z-index: 2;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      background-color: ${({ theme }) => theme.mainTheme.color.background};

      &__title-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 30px;

        & .ScrollbarsCustom {
          height: 140px !important;
        }

        & .ScrollbarsCustom-Content {
          height: 100px !important;
        }

        & .ScrollbarsCustom-TrackY {
          height: calc(100% - 16px) !important;
          top: 8px !important;
        }

        &__not-found-section {
          padding: 20px 0;
          display: flex;
          flex-direction: column;
          row-gap: 8px;
          align-items: center;

          h4 {
            color: ${({ theme }) => theme.mainTheme.color.gray40};
          }
        }
      }

      &__list-area {
        padding: 0;
        height: 100%;
        display: grid;
        grid-template-rows: repeat(3, max-content);
        grid-template-columns: repeat(2, auto);
        justify-content: space-between;
        row-gap: 15px;
        
        & > label {
          margin: 0;
        }

        &__item {
          padding: 0;
          cursor: pointer;
          font-weight: 400;
        }
      }
    }
  }
`;
