import React from 'react';

import Button from 'components/Button';

import StyledToggleButton from './ToggleButton.styles';

type PropsType = {
  isPressed: boolean;
  title: string;
  onClick: () => void;
};

const ToggleButton: React.FC<PropsType> = ({
  isPressed,
  title,
  onClick,
}) => {
  return (
    <StyledToggleButton>
      <Button
        isPressed={isPressed}
        variant={isPressed ? 'outlined' : 'text'}
        onClick={onClick}
      >
        {title}
      </Button>
    </StyledToggleButton>
  );
};

export default ToggleButton;
