import styled, { css } from 'styled-components';

export default styled.div`
overflow: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  .un-auth-container {
    &__left {
      width: 50%;
      padding-top: 72px;
      padding-left: 72px;
      padding-right: 108px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (max-width: 970px) {
        width: 100%;
        padding: 72px 120px;
      }
    }

    &__right {
      position: relative;
      width: 50%;
      display: flex;
      flex-direction: column;
      ${({ theme }) => css`
        background: linear-gradient(251deg, ${theme.mainTheme.color.blue40} 11.99%, ${theme.mainTheme.color.blue50} 100.78%);
      `}

      @media (max-width: 970px) {
        display: none;
      }

      & > .right-container__picture {
        max-width: 1000px;
        position: absolute;
        border-radius: ${({ theme }) => theme.borderRadius};
        box-shadow: ${({ theme }) => theme.boxShadow};

        &--top {
          top: -150px;
          left: 72px;
          z-index: 1;
        }

        &--bottom {
          top: 460px;
          left: 139px;
        }
      }
    }
  }
`;
