import React, { useLayoutEffect, useState, useMemo } from 'react';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import { Link } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  resetFilters,
  setRequestSentFilters,
} from 'store/filter/filterSlice';

import storageUtil from 'utils/storageHelper';
import { PathENUM } from 'routes';
import { useGetLocationPath } from 'utils/checkLocationHelper';
import Button from 'components/Button';

import FunnelIcon from 'assets/svg/FunnelIcon';
import FiltersModal from './components/FiltersModal/FiltersModal';
import StyledFilterContainer from './FilterContainer.styles';

type PropsType = {
  className?: string;
};

const useWindowWidth = () => {
  const [width, setWidth] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return width;
};

const FilterContainer: React.FC<PropsType> = ({ className }) => {
  const dispatch = useAppDispatch();
  const canResetFilters = useAppSelector((state) => state.filterSlice.canResetFilters);
  const currentLocation = useGetLocationPath();

  const [isShowFilters, setIsShowFilters] = useState(false);

  const width = useWindowWidth();
  const isSmallTablet = width <= 850;

  const handleResetFilters = () => {
    storageUtil.dateRangeFrom.remove();
    storageUtil.dateRangeTo.remove();
    storageUtil.priceRangeFrom.remove();
    storageUtil.priceRangeTo.remove();
    storageUtil.fixedBudget.remove();
    storageUtil.mainFilters.remove();
    dispatch(resetFilters());
    dispatch(setRequestSentFilters(true));
  };

  const handleOpenFiltersModalClick = () => {
    setIsShowFilters(true);
  };

  const handleCloseFiltersModalClick = () => {
    setIsShowFilters(false);
  };

  const isNotJobsPage = useMemo(() => {
    return currentLocation !== PathENUM.JOBS;
  }, [currentLocation]);

  return (
    <StyledFilterContainer className={className}>
      <div className="filters-container">
        {isNotJobsPage && (
          <Link to={PathENUM.JOBS}>
            <Button
              type="button"
              variant="contained"
              className="filters-container__show-btn"
              >
              Show filtered jobs
            </Button>
          </Link>
        )}
        <Button
          type="button"
          variant="contained"
          className="filters-container__filters-btn"
          onClick={handleOpenFiltersModalClick}
        >
          <FunnelIcon />
          Filters
        </Button>
        <Button
          variant="outlined"
          color="error"
          disabled={!canResetFilters}
          onClick={handleResetFilters}
          endIcon={isSmallTablet && <ReplayRoundedIcon className="icon-button" />}
          className="buttons__button"
        >
          {!isSmallTablet && 'Reset Filters'}
        </Button>
      </div>
      <FiltersModal
        open={isShowFilters}
        onClose={handleCloseFiltersModalClick}
      />
    </StyledFilterContainer>
  );
};

export default FilterContainer;
