import { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import type { ChartDataset } from 'chart.js';

import { handleError } from 'utils/errorHandler';
import { TIME_TO_REFETCH } from 'constants/mainConstants';
import { SkillsLimitENUM } from 'types/filtersType';
import { setRelatedSkill, setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { useLazyGetMostPopularSkillsQuery } from 'api/services/chartEndpoints/mostPopularSkillsEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import Paper from 'components/Paper';
import DoughnutChart from 'components/DoughnutChart';
import EmptyState from 'components/EmptyState/EmptyState';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './MostPopularSkillsDoughnutSection.styles';

const buttonsArray = [
  {
    title: 'Top 30',
    value: SkillsLimitENUM.THIRTY,
  },
  {
    title: 'Top 20',
    value: SkillsLimitENUM.TWENTY,
  },
  {
    title: 'Top 10',
    value: SkillsLimitENUM.TEN,
  },
];

type PropsType = {
  chartColors: string[];
};

const MostPopularSkillsDoughnutSection: React.FC<PropsType> = ({ chartColors }) => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const [isChartDataVisible, setIsChartDataVisible] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);

  const [triggerGetMostPopularSkillsQuery, {
    data,
    error,
    isError,
    isLoading,
    currentData,
  }] = useLazyGetMostPopularSkillsQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(!currentData);
      await triggerGetMostPopularSkillsQuery({ filters });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexButtonPressed]);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetMostPopularSkillsQuery({ filters });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    indexButtonPressed,
    isRequestSent,
    triggerGetMostPopularSkillsQuery,
  ]);

  const limitedData = useMemo(() => {
    const labels = data?.labels?.slice(0, buttonsArray[indexButtonPressed].value);
    const newDataInddatasets =
      data?.datasets[0].data.slice(0, buttonsArray[indexButtonPressed].value);
    return {
      labels,
      datasets: [
        {
          data: newDataInddatasets,
        },
      ] as ChartDataset[],
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexButtonPressed, data]);

  const onClickLegend = (legendItem: string) => {
    dispatch(setRelatedSkill(legendItem));
  };

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  const toggleChartDataVisible = () => {
    setIsChartDataVisible(!isChartDataVisible);
  };

  return (
    <Paper
      title="Most popular skills"
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          isNeedHideButton
          isChartDataVisible={isChartDataVisible}
          updateIndexButtonPressed={updateIndexButtonPressed}
          toggleChartDataVisible={toggleChartDataVisible}
          isDoughnutChartButtons
        />
      )}
      >
      <StyledSection>
        {isLoading || isLoadingSentRequest ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <div className="graph-section">
            { data?.datasets?.[0]?.data?.length && !isLoading ? (
            <DoughnutChart
              legendId="legend-most-popular-doughnut"
              countId="count-most-popular-doughnut"
              labels={limitedData?.labels}
              datasets={limitedData?.datasets}
              onClickDoughnut={onClickLegend}
              isChartDataVisible={isChartDataVisible}
              datasetsColors={chartColors}
            />
            ) : (
              <EmptyState />
            )}
          </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default MostPopularSkillsDoughnutSection;
