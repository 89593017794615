import styled from 'styled-components';

export default styled.div`
  // height: 277.5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .loading-section {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .without-content-section {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h4 {
      color: ${({ theme }) => theme.mainTheme.color.gray40};
      text-align: center;
      font-weight: 600;
      max-width: 191px;
    }
  }
`;
