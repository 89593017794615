import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-template-rows: repeat(3, max-content);
  row-gap: 15px;
  width: 100%;
  
  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24.52px;
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }
`;
