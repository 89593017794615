/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { handleError } from 'utils/errorHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunk from 'store/user/userThunk';
import Button from 'components/Button';
import Paper from 'components/Paper';
import Input from 'components/Input';
import CrownIconWrapper from 'components/CrownIconWrapper/CrownIconWrapper';
import StyledCommentSection from './CommentSection.styles';

const CommentSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isSentComment, setSentComment] = useState(false);
  const [isSendLoading, setSendLoading] = useState(false);

  const formikComments = useFormik({
    initialValues: {
      comments: '',
    },
    onSubmit: async (values, { resetForm }) => {
      if (values.comments) {
        setSendLoading(true);
        try {
          await dispatch(userThunk.sendComment({ text: values.comments })).unwrap();
          toast.info('Request for Full Version has been send', {
            position: 'bottom-right',
          });
          resetForm();
          setSentComment(true);
        } catch (err) {
          handleError(err, {});
        }
        setSendLoading(false);
      }
    },
  });

  const onGetRequest = () => {
    setSentComment(false);
  };

  /* ------- uncomment back when payment will be added ---------
  const userStatus = useAppSelector((state) => state.userSlice.user.status);
  const versionPhrase = useMemo(() => {
    if (userStatus === 'paid') {
      return {
        successScreen: {
          header: 'Feedback has been send',
          text: undefined,
          buttonText: 'Send more',
        },
        header: '',
        text: `Please,leave your feedback about using the application ${'\n'} or describe what/how can be improved.`,
        isCrowned: true,
      };
    }
    return {
      successScreen: {
        header: 'Your request has been send',
        text: 'Didn’t get any answer for 2 work days? Send request again!',
        buttonText: 'Send Another Request',
      },
      header: 'You are using demo version.',
      text: 'Want to get full one? Send a request and we connect with you',
      isCrowned: false,
    };
  }, [userStatus]);
  */

  const versionPhrase = {
    successScreen: {
      header: 'Feedback has been send',
      text: undefined,
      buttonText: 'Send more',
    },
    header: '',
    text: `Please, leave your feedback about using the application ${'\n'} or describe what/how can be improved.`,
    isCrowned: false,
  };

  return (
    <StyledCommentSection>
      <Paper className="general__main-paper">
        {isSentComment ? (
          <div className="result-area">
            {/* <CheckCircleOutlineRoundedIcon className="icon" /> */}

            <h3>{versionPhrase?.successScreen?.header}</h3>

            {versionPhrase?.successScreen?.text && (
              <h4>{versionPhrase?.successScreen?.text}</h4>
            )}

            <Button
              variant="contained"
              onClick={onGetRequest}
            >
              {versionPhrase?.successScreen?.buttonText}
            </Button>
          </div>
        ) : (
          <form onSubmit={formikComments.handleSubmit}>
            <div className="title-area">
              <h3>
                {versionPhrase?.header}
                {versionPhrase?.isCrowned && (
                  <CrownIconWrapper className="title-area-crown" color="white" />)}
              </h3>

              <h4 className="title-area-description">{versionPhrase?.text}</h4>
            </div>

            <Input
              nameInput="comments"
              label="Your thoughts"
              multiline
              onChangeValue={formikComments.handleChange}
              value={formikComments.values.comments}
              className="big-input"
            />

            <div className="form-section__button-area">
              <Button
                variant="outlined"
                type="reset"
                disabled={isSendLoading}
                onClick={() => formikComments.resetForm()}
              >
                Clear Form
              </Button>

              <Button
                loading={isSendLoading}
                variant="contained"
                type="submit"
              >
                Send
              </Button>
            </div>
          </form>
        )}
      </Paper>
    </StyledCommentSection>
  );
};

export default CommentSection;
