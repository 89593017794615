import type {
  NameFilterENUM,
  TypeFilterENUM,
} from 'types/filtersType';
import {
  PriceRangeNameENUM,
  DateRangeNameENUM,
} from 'types/filtersType';
import storageUtil from 'utils/storageHelper';

export const setMainFiltersToStorage = (options: {
  section: NameFilterENUM;
  type: TypeFilterENUM;
  resultArray: string[];
}) => {
  const { section, type, resultArray } = options;
  const storageMainFilters = storageUtil.mainFilters.get();
  let parsedStorageMainFilters: Record<NameFilterENUM, Record<TypeFilterENUM, string[]>> = {
    selectedSkills: {
      only: [],
      exclude: [],
    },
    selectedRegions: {
      only: [],
      exclude: [],
    },
    selectedCountries: {
      only: [],
      exclude: [],
    },
  };
  if (!storageMainFilters) {
    parsedStorageMainFilters[section][type] = resultArray;
    return storageUtil.mainFilters.set({
      value: JSON.stringify(parsedStorageMainFilters),
    });
  }
  parsedStorageMainFilters =
    JSON.parse(storageMainFilters);
  parsedStorageMainFilters[section][type] = resultArray;
  storageUtil.mainFilters.set({
    value: JSON.stringify(parsedStorageMainFilters),
  });
};

export const setDateRangeToStorage = (options: {
  key: DateRangeNameENUM;
  value: string | null;
}) => {
  const { key, value } = options;
  if (key === DateRangeNameENUM.FROM) {
    if (value) {
      storageUtil.dateRangeFrom.set({ value });
    } else {
      storageUtil.dateRangeFrom.remove();
    }
  }
  if (key === DateRangeNameENUM.TO) {
    if (value) {
      storageUtil.dateRangeTo.set({ value });
    } else {
      storageUtil.dateRangeTo.remove();
    }
  }
};
export const setPriceRangeToStorage = (options: {
  key: PriceRangeNameENUM;
  value: string | null;
}) => {
  const { key, value } = options;
  switch (key) {
    case PriceRangeNameENUM.FROM:
      if (value) {
        storageUtil.priceRangeFrom.set({ value });
      } else {
        storageUtil.priceRangeFrom.remove();
      }
      break;
    case PriceRangeNameENUM.TO:
      if (value) {
        storageUtil.priceRangeTo.set({ value });
      } else {
        storageUtil.priceRangeTo.remove();
      }
      break;
    default:
      if (value) {
        storageUtil.fixedBudget.set({ value });
      } else {
        storageUtil.fixedBudget.remove();
      }
  }
};
