import type { ChartOptions } from 'chart.js';

import { defaultTheme } from 'styles/theme';
import formatGraphAxisValues from 'utils/formatGraphAxisValues';

const getPriceBarOptions = (isLogarithmic?: boolean): ChartOptions<'bar'> => {
  return {
    aspectRatio: 2.7,
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    interaction: {
      mode: 'point',
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        type: isLogarithmic ? 'logarithmic' : 'linear',
        grid: {
          display: true,
          circular: false,
          drawTicks: false,
          lineWidth: 1,
          tickBorderDash: [0, 1, 2],
          color: defaultTheme.mainTheme.color.blue20,
        },
        border: {
          display: true,
          color: defaultTheme.mainTheme.color.gray40,
          width: 1,
          dash: [0, 2, 2],
          dashOffset: 1,
        },
        ticks: {
          maxTicksLimit: 10,
          color: defaultTheme.mainTheme.color.gray40,
          font: {
            family: defaultTheme.font.family,
            size: 12,
            weight: '500',
          },
          callback(value) {
            return formatGraphAxisValues(Number(value));
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        border: {
          display: false,
          width: 0,
          dash: [0, 4, 4],
          dashOffset: 1,
        },
        ticks: {
          display: true,
          crossAlign: 'far',
          backdropColor: defaultTheme.mainTheme.color.blue50,
          color: defaultTheme.mainTheme.color.gray40,
          font: {
            family: defaultTheme.font.family,
            size: 12,
            weight: '500',
          },
        },
      },
    },
  };
};

export default getPriceBarOptions;
