import styled from 'styled-components';

export default styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .top-section__title {
    font-weight: 700;
    font-size: 32px;
    line-height: 43.58px;
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }
`;
