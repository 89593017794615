import styled from 'styled-components';

export default styled.div`
  background-color: ${({ theme }) => theme.mainTheme.color.background};
  display: grid;
  grid-template-rows: repeat(3, max-content);
  row-gap: 16px;

  @media (max-width: 1115px) {
    right: auto;
  }

  .date-picker__icon {
    cursor: pointer;
  }

  h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 24.52px;
    color: ${({ theme }) => theme.mainTheme.color.gray50};
    margin-bottom: 4px;
  }
`;
