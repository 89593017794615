import React from 'react';
import { OutlinedInput, InputLabel, FormHelperText } from '@mui/material';
import StyledInput from './Input.styles';
import StyledFormControl from './TextField.styles';

type PropsType = {
  isRequired?: boolean;
  isTouched?: boolean;
  multiline?: boolean;
  nameInput: string;
  value: string;
  errorText?: string;
  label?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  isDisabled?: boolean;
  autoFocus?: boolean;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChangeValue?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
};

const Input: React.FC<PropsType> = ({
  isRequired = false,
  startAdornment,
  endAdornment,
  isTouched,
  multiline,
  nameInput,
  value,
  errorText,
  label,
  type = 'text',
  placeholder,
  className,
  isDisabled,
  autoFocus,
  onChangeValue,
  onBlur,
  onClick,
}) => {
  const isErrorDisplay = isTouched && Boolean(errorText);

  const searchParams = new URLSearchParams(document.location.search);
  const isRedirected = !!searchParams.get('redirect');

  return (
    <StyledInput onClick={onClick} className={className}>
      <StyledFormControl
        variant="outlined"
        iswithstarticon={!!startAdornment}
        iserrordisplay={isErrorDisplay}
        isdisabled={isDisabled}
      >
        <InputLabel htmlFor={nameInput} error={isErrorDisplay}>{label}</InputLabel>

        <OutlinedInput
          id={nameInput}
          label={label}
          placeholder={placeholder}
          error={isErrorDisplay}
          required={isRequired}
          value={value}
          onChange={onChangeValue}
          onBlur={onBlur}
          type={type}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          autoComplete="none"
          multiline={multiline}
          disabled={isDisabled}
          autoFocus={isRedirected || autoFocus}
        />

          {isErrorDisplay && <FormHelperText error={isErrorDisplay}>{errorText}</FormHelperText>}
      </StyledFormControl>
    </StyledInput>
  );
};

export default Input;
