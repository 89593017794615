import type { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  borderRadius: '12px',
  font: {
    family: '"Noto Sans", sans-serif',
  },
  boxShadow: '4px 4px 8px 0px rgba(4, 51, 104, 0.20), 20px 70px 90px 0px rgba(14, 75, 142, 0.20)',
  boxShadowSmall: '0px 0px 8px 0px rgba(60, 64, 67, 0.05)',
  boxShadowExtraSmall: '0px 0px 16px 0px rgba(14, 75, 142, 0.10), 0px 0px 2px 0px rgba(4, 51, 104, 0.15)',
  mainTheme: {
    color: {
      background: '#FFFFFF',
      gray50: '#3C4043',
      gray40: '#79818A',
      gray30: '#869AB0',
      gray20: '#E7EBF1',
      blue60: '#106ACF',
      blue5005: '#096ee27f',
      blue5002: '#096ee233',
      blue5000: 'rgba(9, 110, 226, 0)',
      blue50: '#096FE2',
      blue40: '#569DEC',
      blue30: '#AAC9F6',
      blue20: '#EEF2F6',
      blue10: '#F8FAFE',
      darkRed: '#8A1212',
    },
  },
};
