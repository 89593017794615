import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const getCurrentTimezone = () => {
  return `${dayjs.tz.guess().split('/')[1]} (${dayjs().offsetName()})`;
};
