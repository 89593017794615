import React from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import type { AxiosError } from 'axios';

import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import { forgotPassSchema } from 'types/validationSchemes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { PathENUM } from 'routes';

import Input from 'components/Input';
import Button from 'components/Button';
import NavToSection from 'components/NavToSection';

import StyledForgotPassword from '../../ForgotPassPage.styles';

const ForgotPassword: React.FC = () => {
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: forgotPassSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(userThunks.forgotPassword(values)).unwrap();
        const currentPath = `${PathENUM.FORGOT_PASSWORD}/${PathENUM.RESET_PASSWORD}`;
        navigate({
          pathname: currentPath,
          search: `?email=${values.email}`,
        });
      } catch (err) {
        handleError(err, {
          errors: {
            404: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              setFieldError('email', axiosError.response?.data.message);
            },
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const onClickCancel = () => {
    navigate(-1);
  };

  return (
    <StyledForgotPassword>
      <div className="forgot-password-part__title">
        <h1>Forgot Password?</h1>

        <p className="paragraph">Please, enter an email you used for Sign Up to get One Time Password</p>
      </div>

      <form onSubmit={formik.handleSubmit} className="forgot-password-part__form-section">
        <Input
          label="Email"
          nameInput="email"
          onChangeValue={formik.handleChange}
          value={formik.values.email}
          errorText={formik.errors.email}
          isTouched={formik.touched.email}
          onBlur={formik.handleBlur}
        />

        <div className="forgot-password-part__form-section__button-area">
          {!isLoading && (
            <Button
              variant="outlined"
              onClick={onClickCancel}
            >
              Cancel
            </Button>
          )}

          <Button
            loading={isLoading}
            variant="contained"
            disabled={!formik.isValid || !formik.values.email}
            type="submit"
          >
            Get Code
          </Button>
        </div>
      </form>

      <NavToSection
        title="Don’t have an account yet?"
        titleButton="Sign Up"
        path={PathENUM.SIGN_UP}
      />
    </StyledForgotPassword>
  );
};

export default ForgotPassword;
