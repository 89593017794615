import React, { useState, useMemo } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import Button from 'components/Button';

import StyledFilterCollapse from './FilterCollapse.styles';
import type { DisplayedFilterType } from '../FiltersActions';

type PropsType = {
  item: {
    title: string;
    filters: DisplayedFilterType[];
  };
  removeFilter: (options: DisplayedFilterType) => void;
  isHideButton?: boolean;
};

const FilterCollapse: React.FC<PropsType> = ({ item, removeFilter, isHideButton = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const isDisplayCollapse = item.filters.length > 5;

  const handleClickCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const curFiltersArr = useMemo(
    () => (isCollapsed ? item.filters : item.filters.slice(0, 5)),
    [isCollapsed, item],
  );

  return (
    <StyledFilterCollapse isCollapsed={isCollapsed}>
      {!isHideButton && isDisplayCollapse && (
        <Button
          variant="text"
          onClick={handleClickCollapse}
          endIcon={<KeyboardArrowUpIcon className="filter-collapse__collapse-btn__icon" />}
          className="filter-collapse__collapse-btn"
        >
          {isCollapsed ? 'Hide' : 'Show All'}
        </Button>
      )}

      <div className="filters-actions__filter-wrapper">
        {curFiltersArr.map((filter) => (
          <div className="filters-actions__filter-item" key={filter.text}>
            <p className="filters-actions__filter-title">{filter.text}</p>

            <IconButton
              onClick={() => removeFilter(filter)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        ))}
      </div>
    </StyledFilterCollapse>
  );
};

export default FilterCollapse;
