import styled from 'styled-components';

export default styled.div`
  width: 100%;
  position: relative;

  .filters-actions {
    &__applied-filters {
      margin-top: -18px;
      padding-bottom: 10px;
      font-size: 12px;
      font-weight: 500;
      color: ${({ theme }) => theme.mainTheme.color.gray30};

      @media (max-width: 1115px) {
        margin-top: 0;
      }
    }

    &__container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 8px;

      @media (max-width: 970px) {
        flex-direction: column;
      }
    }

    &__filters-container {
      display: grid;
      grid-template-columns: 165px auto;
      align-items: baseline;
      grid-column: 1;
      margin-bottom: 16px;
    }

    &__filter-title {
      color: ${({ theme }) => theme.mainTheme.color.gray50};
      font-family: ${({ theme }) => theme.font.family};
      font-size: 16px;
      font-weight: 600;
      margin-right: 8px;
    }

    &__filter-wrapper {
      display: flex;
      flex-wrap: wrap;
      row-gap: 8px;
    }
    
    &__filter-item {
      padding: 8px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${({ theme }) => theme.mainTheme.color.blue20};
      border: 1ps solid ${({ theme }) => theme.mainTheme.color.blue20};
      border-radius: 8px;
      margin-right: 8px;
      width: fit-content;
    }

    &__filter-title {
      color: ${({ theme }) => theme.mainTheme.color.gray50};
      font-family: ${({ theme }) => theme.font.family};
      font-size: 16px;
      font-weight: 600;
      cursor: default;
    }

    &__filter-item-remove-btn {
      padding: 0;
      border: none;
      background: inherit;
    }

    &__filter-item-remove-btn {
      height: 24px;
      cursor: pointer;
    }
  }
`;
