import React, { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { changeOldPasswordSchema } from 'types/validationSchemes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import Paper from 'components/Paper';
import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';
import StyledPasswordSection from './PasswordSection.styles';

const PasswordSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.userSlice);
  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: '',
    },
    validationSchema: changeOldPasswordSchema,
    onSubmit: async (values, { setFieldError, resetForm }) => {
      try {
        await dispatch(userThunks.changePass({ ...values })).unwrap();
        toast.info('Password has been changed', {
          position: 'bottom-right',
        });
        resetForm();
      } catch (err) {
        handleError(err, {
          errors: {
            401: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              setFieldError('currentPassword', axiosError.response?.data.message);
            },
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const isUnFilledFields = useMemo(() => {
    return !formik.values.currentPassword ||
      !formik.values.newPassword ||
      !formik.values.repeatNewPassword;
  }, [formik.values]);

  return (
    <StyledPasswordSection>
      <Paper className="new-password-part">
        <form onSubmit={formik.handleSubmit} className="new-password-part__form-section">
          <PasswordInput
            label="Current Password"
            nameInput="currentPassword"
            onChangeValue={formik.handleChange}
            value={formik.values.currentPassword}
            errorText={formik.errors.currentPassword}
            isTouched={formik.touched.currentPassword}
            onBlur={formik.handleBlur}
          />

          <PasswordInput
            label="New Password"
            nameInput="newPassword"
            onChangeValue={formik.handleChange}
            value={formik.values.newPassword}
            errorText={formik.errors.newPassword}
            isTouched={formik.touched.newPassword}
            onBlur={formik.handleBlur}
          />

          <PasswordInput
            label="Confirm Password"
            nameInput="repeatNewPassword"
            onChangeValue={formik.handleChange}
            value={formik.values.repeatNewPassword}
            errorText={formik.errors.repeatNewPassword}
            isTouched={formik.touched.repeatNewPassword}
            onBlur={formik.handleBlur}
          />

          <div className="new-password-part__form-section__button-area">
            <Button
              loading={isLoading}
              variant="contained"
              disabled={!formik.isValid || isUnFilledFields}
              type="submit"
            >
              Change Password
            </Button>
          </div>
        </form>
      </Paper>
    </StyledPasswordSection>
  );
};

export default PasswordSection;
