import { DialogContent } from '@mui/material';
import StyledDialog from './CustomModal.styles';

type PropsType = {
  open: boolean;
  onClose?: () => void;
  className?: string;
  children: React.ReactNode;
  isBorderRounded?: boolean;
};

const CustomModal:React.FC<PropsType> = (props) => {
  return (
    <StyledDialog
      open={props.open}
      onClose={props.onClose}
      scroll="paper"
      isBorderRounded={props.isBorderRounded}
    >
      <DialogContent dividers>
        {props.children}
      </DialogContent>
    </StyledDialog>
  );
};

export default CustomModal;
