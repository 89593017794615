import { useEffect, useState } from 'react';

import { savedFiltersSelector, appliedFiltersSelector } from 'store/filter/filterSelector';
import { setIsApplyFilters } from 'store/filter/filterSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

export default () => {
  const dispatch = useAppDispatch();
  const savedFilters = useAppSelector(savedFiltersSelector);
  const appliedFilters = useAppSelector(appliedFiltersSelector);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (JSON.stringify(savedFilters) === JSON.stringify(appliedFilters)) {
      dispatch(setIsApplyFilters(true));
    } else {
      dispatch(setIsApplyFilters(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  return () => {
    setTrigger(!trigger);
  };
};
