/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-len */
import type { FC } from 'react';
import type { IIcon } from '../../types/commonTypes';

const CrownIcon: FC<IIcon> = ({ className, color }) => {
  return null;
  // return (
  //   <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
  // <path d="M9.95122 15H2.72824C2.18757 15 1.93552 14.7995 1.81879 14.257C1.21933 11.4594 0.622015 8.66299 0.0268345 5.86763C-0.0449977 5.52834 0.0210623 5.22588 0.301977 5.00693C0.571348 4.79652 0.860601 4.80046 1.15947 4.94972C2.56533 5.65525 3.97632 6.34959 5.38282 7.05775C5.54765 7.14125 5.61756 7.1189 5.70799 6.9532C6.89023 4.79257 8.07695 2.63457 9.26817 0.479204C9.52087 0.0189362 9.96148 -0.122432 10.3585 0.110332C10.5188 0.204358 10.615 0.350329 10.7042 0.510108C11.8779 2.64707 13.0503 4.7849 14.2214 6.92361C14.3343 7.13073 14.4176 7.14783 14.6165 7.04657C16.0127 6.33842 17.4166 5.64604 18.8161 4.94249C19.1303 4.78534 19.4337 4.77087 19.714 4.99969C19.9943 5.22851 20.0417 5.53163 19.9705 5.86697C19.5857 7.62037 19.1994 9.37268 18.8116 11.1239C18.5803 12.172 18.3485 13.2199 18.1164 14.2675C18.0009 14.7889 17.7463 14.9974 17.2185 14.998L9.95122 15Z" fill={color} />
  //   </svg>
  // );
};

export default CrownIcon;
