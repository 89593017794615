import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import type { AxiosError } from 'axios';

import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { changePassByCodeSchema } from 'types/validationSchemes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { PathENUM } from 'routes';

import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';

import StyledChangePassword from '../../ForgotPassPage.styles';

const ChangePassword: React.FC = () => {
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      token: searchParams.get('token') || '',
      password: '',
      repeatPassword: '',
    },
    validationSchema: changePassByCodeSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(userThunks.resetPassword(values)).unwrap();
        navigate(PathENUM.SIGN_IN);
        toast.info('Password has been changed. Use to Log In', {
          position: 'bottom-left',
        });
      } catch (err) {
        handleError(err, {
          errors: {
            401: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              setFieldError('password', axiosError.response?.data.message);
            },
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const isUnFilledFields = useMemo(() => {
    return !formik.values.password ||
      !formik.values.repeatPassword;
  }, [formik.values]);

  return (
    <StyledChangePassword>
      <div className="forgot-password-part__title">
        <h1>Change Password</h1>

        <p className="paragraph">Previous passwords can’t be used</p>
      </div>

      <form onSubmit={formik.handleSubmit} className="forgot-password-part__form-section">
        <PasswordInput
          label="New Password"
          nameInput="password"
          onChangeValue={formik.handleChange}
          value={formik.values.password}
          errorText={formik.errors.password}
          isTouched={formik.touched.password}
          onBlur={formik.handleBlur}
        />

        <PasswordInput
          label="Confirm Password"
          nameInput="repeatPassword"
          onChangeValue={formik.handleChange}
          value={formik.values.repeatPassword}
          errorText={formik.errors.repeatPassword}
          isTouched={formik.touched.repeatPassword}
          onBlur={formik.handleBlur}
        />

        <div className="forgot-password-part__form-section__button-area">
          <Button
            loading={isLoading}
            variant="contained"
            disabled={!formik.isValid || isUnFilledFields}
            type="submit"
          >
            Change Password
          </Button>
        </div>
      </form>
    </StyledChangePassword>
  );
};

export default ChangePassword;
