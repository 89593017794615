import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  ArcElement,
  LogarithmicScale,
  Filler,
} from 'chart.js';

import { getRandomColorArray } from 'utils/getRandomColorHelper';

import AuthContainer from 'components/AuthContainer';
import BannerVersion from 'components/BannerVersion/BannerVersion';
import TrendsSection from './components/TrendsSection';
import MostPopularSkillsDoughnutSection from './components/MostPopularSkillsDoughnutSection';
import RelatedSkillsSection from './components/RelatedSkillsSection';
import LocationSection from './components/LocationSection';
import TimeLineSection from './components/TimeLineSection';
import FiltersActions from './components/FiltersActions';
import PricesSection from './components/PricesSection/PricesSection';
import TopSection from './components/TopSection/TopSection';
import StyledDashboard from './Dashboard.styles';

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  PointElement,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
);

const Dashboard: React.FC = () => {
  const [chartsColors, setChartColors] = useState<string[]>([]);

  useEffect(() => {
    setChartColors(getRandomColorArray());
  }, []);

  return (
    <AuthContainer>
      <TopSection />
      <BannerVersion />
      <StyledDashboard>
        <Grid container spacing={2}>

          <FiltersActions />

          <Grid item xs={12} lg={7}>
            <MostPopularSkillsDoughnutSection
              chartColors={chartsColors}
            />
          </Grid>

          <Grid item xs={12} lg={5}>
            <RelatedSkillsSection
              chartColors={chartsColors}
            />
          </Grid>

          <Grid item xs={12}>
            <TrendsSection
              chartColors={chartsColors}
            />
          </Grid>

          <Grid item xs={12} lg={5}>
            <LocationSection
              chartColors={chartsColors}
            />
          </Grid>

          <Grid item xs={12} lg={7}>
            <TimeLineSection />
          </Grid>

          <Grid item xs={12}>
            <PricesSection />
          </Grid>
        </Grid>
      </StyledDashboard>
    </AuthContainer>
  );
};

export default Dashboard;
