import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress } from '@mui/material';
import clsx from 'clsx';

import { useLazyGetLocationQuery } from 'api/services/chartEndpoints/locationEndpoints';
import { convertToDoughnutDataFromObj } from 'utils/setParamsHelper';
import { LocationENUM } from 'types/apiTypes/statisticApiTypes';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { handleError } from 'utils/errorHandler';

import Paper from 'components/Paper';
import DoughnutChart from 'components/DoughnutChart';
import EmptyState from 'components/EmptyState/EmptyState';
import { TIME_TO_REFETCH } from 'constants/mainConstants';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './LocationSection.styles';

const buttonsArray = [
  {
    title: 'by regions',
    value: LocationENUM.REGION,
  },
  {
    title: 'by country',
    value: LocationENUM.COUNTRY,
  },
];

type PropsType = {
  chartColors: string[];
};

const LocationSection: React.FC<PropsType> = ({ chartColors }) => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const [isLocationChartDataVisible, setIsLocationCChartDataVisible] = useState<boolean>(true);
  const [isCountriesChartDataVisible, setIsCountriesChartDataVisible] = useState<boolean>(true);
  const [triggerGetLocation, {
    data,
    error,
    isError,
    isLoading,
    currentData,
  }] = useLazyGetLocationQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(!currentData);
      await triggerGetLocation({ filters });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(!currentData);
        await triggerGetLocation({ filters });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isRequestSent, triggerGetLocation]);

  const actualData = useMemo(() => {
    return convertToDoughnutDataFromObj(data?.[buttonsArray[indexButtonPressed].value]);
  }, [data, indexButtonPressed]);

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  const toggleChartDataVisible = () => {
    if (indexButtonPressed === 0) {
      setIsLocationCChartDataVisible(!isLocationChartDataVisible);
    } else {
      setIsCountriesChartDataVisible(!isCountriesChartDataVisible);
    }
  };

  return (
    <Paper
      title="Location"
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          isNeedHideButton
          isChartDataVisible={
            indexButtonPressed === 0 ? isLocationChartDataVisible : isCountriesChartDataVisible
          }
          updateIndexButtonPressed={updateIndexButtonPressed}
          toggleChartDataVisible={toggleChartDataVisible}
        />
      )}
    >
      <StyledSection>
        {isLoading || isLoadingSentRequest ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <div>
            { actualData?.datasets?.[0]?.data?.length ? (
              <>
                <DoughnutChart
                  permanentTrackY
                  className={clsx({ disabled: indexButtonPressed !== 0 })}
                  legendId={`legend-location-${indexButtonPressed}`}
                  countId={`count-location-${indexButtonPressed}`}
                  labels={actualData?.labels}
                  datasets={actualData?.datasets}
                  isChartDataVisible={isLocationChartDataVisible}
                  datasetsColors={chartColors}
                />
                <DoughnutChart
                  permanentTrackY
                  className={clsx({ disabled: indexButtonPressed !== 1 })}
                  legendId={`legend-location-${indexButtonPressed}`}
                  countId={`count-location-${indexButtonPressed}`}
                  labels={actualData?.labels}
                  datasets={actualData?.datasets}
                  isChartDataVisible={isCountriesChartDataVisible}
                  datasetsColors={chartColors}
                />
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default LocationSection;
