/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import { useAppSelector } from 'store/hooks';
import BannerVersionStyles from './BannerVersion.styles';

const redirectLink = '/profile?redirect=true';

const BannerVersion = () => {
  const [isClosed, setIsClosed] = useState(false);

  /* ------- uncomment back when payment will be added ---------
  const userStatus = useAppSelector((state) => state.userSlice.user.status);

  const bannerPhrase = useMemo(() => {
    if (userStatus === 'free') {
      return {
        text: 'You are currently using the Demo version. ',
        link: 'Request the Full version',
      };
    }
    if (userStatus === 'paid') {
      return {
        text: 'You are using the Full version. ',
        link: 'Leave feedback about using the application.',
      };
    }
  }, [userStatus]);

  if (!bannerPhrase || isClosed) {
    return null;
  }
  */
  const bannerPhrase = {
    text: 'We want to provide you with the best experience. Please help us to improve. ',
    link: 'Leave feedback about using the application.',
  };

  if (isClosed) {
    return null;
  }

  return (
    <BannerVersionStyles>
      <p className="banner-version">
        {bannerPhrase.text}
        <Link className="banner-version-link" to={redirectLink}>
          {bannerPhrase.link}
        </Link>
      </p>
      <IconButton
        className="banner-version-close-button"
        onClick={() => setIsClosed(true)}
      >
        <CloseIcon />
      </IconButton>
    </BannerVersionStyles>
  );
};

export default BannerVersion;
