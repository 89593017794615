import React from 'react';
import type { PaperProps } from '@mui/material';
import StyledPaper from './Paper.styles';

type PropsType = PaperProps & {
  title?: string;
  subTitle?: string;
  timezone?: string;
  chartControlButtons?: React.ReactNode;
};

const Paper: React.FC<PropsType> = ({
  title,
  subTitle,
  timezone,
  children,
  chartControlButtons,
  ...props
}) => {
  return (
    <StyledPaper {...props}>
      {(title || subTitle) && (
        <div className="paper__top-area">
          {title && (
            <div className="paper__top-area__title-area">
              <div className="paper__top-area__title-wrapper">
              <h2>{title}</h2>
              {chartControlButtons}
              </div>
              {timezone && <h4>Time Zones: {timezone}</h4>}
            </div>
          )}
          {subTitle && <h4>{subTitle}</h4>}
        </div>
      )}

      {children}
    </StyledPaper>
  );
};

export default Paper;
