import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';

import { handleError } from 'utils/errorHandler';
import { useLazyGetUpToPricesQuery } from 'api/services/chartEndpoints/pricesEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { TIME_TO_REFETCH } from 'constants/mainConstants';

import Paper from 'components/Paper';
import PriceLineGraphWithSlider from '../PriceLineGraphWithSlider/PriceLineGraphWithSlider';
import StyledSection from './UpToPricesSection.styles';

const UpToPricesSection = () => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);
  const dispatch = useAppDispatch();

  const [triggerGetUpToPrices, {
    data,
    error,
    isError,
    isLoading,
  }] = useLazyGetUpToPricesQuery({ pollingInterval: TIME_TO_REFETCH });

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(true);
      await triggerGetUpToPrices({ filters });
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetUpToPrices({ filters });
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isRequestSent, triggerGetUpToPrices]);

  return (
    <Paper title="Up to Prices" subTitle="Number of jobs up to some price">
      <StyledSection>
        {isLoading || isLoadingSentRequest ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <PriceLineGraphWithSlider
            labels={data?.labels}
            datasets={data?.datasets}
          />
        )}
      </StyledSection>
    </Paper>
  );
};

export default UpToPricesSection;
