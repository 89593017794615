import { useEffect, useMemo, useState } from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {
  CircularProgress,
  IconButton,
} from '@mui/material';
import dayjs from 'dayjs';

import { getOneJob } from 'api/services/jobEndpoints';
import { handleError } from 'utils/errorHandler';
import type { JobType } from 'types/jobTypes';
import CustomModal from 'components/CustomModal/CustomModal';
import replaceIncorrectSymbol from 'utils/replaceIncorrectSymbol';
import StyledContainer from './JobModal.styles';

type PropsType = {
  className?: string;
  jobId: number | null;
  handleClose?: () => void;
};

const JobModal: React.FC<PropsType> = (props) => {
  const [isLoading, setIsloading] = useState(true);
  const [job, setJob] = useState<JobType | null>(null);
  const currentPublicationDate = dayjs(job?.pubDate).format('MMM D, YYYY, h:mm A');

  useEffect(() => {
    (async () => {
      try {
        if (!props.jobId) { return; }

        const job = await getOneJob(props.jobId);
        setJob(job.data);
        setIsloading(false);
      } catch (err) {
        handleError(err, {});
      }
    })();
    return () => {
      setJob(null);
      setIsloading(true);
    };
  }, [props.jobId]);

  const jobWithoutIncorrectSymbols = useMemo(() => {
    if (!job) { return null; }

    return {
      ...job,
      country: job.country ? replaceIncorrectSymbol(job.country) : null,
      jobText: replaceIncorrectSymbol(job.jobText),
      jobTitle: replaceIncorrectSymbol(job.jobTitle),
      category: {
        ...job.category,
        categoryTitle: replaceIncorrectSymbol(job.category.categoryTitle),
      },
      skills: job.skills
        ? job.skills.map((skill) => {
          return {
            ...skill,
            skillId: replaceIncorrectSymbol(skill.skillId),
          };
        }) : [],
    };
  }, [job]);

  return (
    <CustomModal
      open={!!props.jobId}
      onClose={props.handleClose}
    >
      <StyledContainer>
        {isLoading ? (
          <div className="loading-section">
            <CircularProgress />
          </div>
        ) : (
          <>
            <div className="dialog__title-area">
              <IconButton className="dialog__title-area__actions" onClick={props.handleClose}>
                <ChevronLeftIcon />
              </IconButton>

              <h1>{jobWithoutIncorrectSymbols?.jobTitle}</h1>

              <div className="little-section">
                <h4>Publication Date: {currentPublicationDate}</h4>

                <h4>
                  {'Link: '}
                  <a
                    className="title-area__link"
                    href={jobWithoutIncorrectSymbols?.link}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {jobWithoutIncorrectSymbols?.link}
                  </a>
                </h4>
              </div>
            </div>
            <div className="dialog__main-area">
              <p className="paragraph dialog__main-area__description">{jobWithoutIncorrectSymbols?.jobText}</p>

              <div className="dialog__main-area__section">
                {jobWithoutIncorrectSymbols?.minRate &&
                (
                  <div className="little-section">
                    <h4>Min Rate</h4>

                    <h4 className="bold">
                      $ {jobWithoutIncorrectSymbols.minRate}
                    </h4>
                  </div>
                )}
                {jobWithoutIncorrectSymbols?.minRate &&
                (
                  <div className="little-section">
                    <h4>Max Rate</h4>

                    <h4 className="bold">
                      $ {jobWithoutIncorrectSymbols?.maxRate}
                    </h4>
                  </div>
                )}
                {jobWithoutIncorrectSymbols?.fixedBudget &&
                (
                  <div className="little-section">
                    <h4>Fixed Budget</h4>

                    <h4 className="bold">
                      $ {jobWithoutIncorrectSymbols.fixedBudget}
                    </h4>
                  </div>
                )}
              </div>

              <div className="dialog__main-area__section">
                {jobWithoutIncorrectSymbols?.country && (
                  <div className="little-section">
                    <h4>Country</h4>

                    <h4 className="bold">{jobWithoutIncorrectSymbols.country}</h4>
                  </div>
                )}

                {jobWithoutIncorrectSymbols?.category.categoryTitle && (
                  <div className="little-section">
                    <h4>Category Title</h4>

                    <h4 className="bold">{jobWithoutIncorrectSymbols?.category.categoryTitle}</h4>
                  </div>
                )}
              </div>

              {!!jobWithoutIncorrectSymbols?.skills.length && (
                <div className="little-section">
                  <h4>Skills</h4>

                  <div className="dialog__main-area__skills-area">
                    {jobWithoutIncorrectSymbols.skills.map((skill, index) => (
                      <div key={index} className="skill-plate">
                        <h4>{skill.skillId}</h4>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </StyledContainer>
    </CustomModal>
  );
};

export default JobModal;
