import { useCallback, useMemo } from 'react';
import type { ChartDataset, Point } from 'chart.js';

import { PeriodENUM } from 'types/filtersType';
import type { FilterDisplayType } from 'types/filtersType';
import {
  useLazyGetTrendsByMonthQuery,
  useLazyGetTrendsByWeekQuery,
} from 'api/services/chartEndpoints/trendsEndpoints';

import { TIME_TO_REFETCH } from 'constants/mainConstants';

const setChartColors = (datasets: ChartDataset[], colors: string[]) => {
  return datasets.map((item, index) => {
    return {
      ...item,
      borderColor: colors[index],
      backgroundColor: colors[index],
    };
  }) as ChartDataset<'line', (number | Point | null)[]>[];
};

export const useTrends = (currentSection: PeriodENUM, colors: string[]) => {
  const [triggerGetTrendsByWeekQuery, {
    data: dataByWeek,
    error: errorByWeek,
    isError: isErrorByWeek,
    isLoading: isLoadingByWeek,
    currentData: currentDataByWeek,
  }] = useLazyGetTrendsByWeekQuery({ pollingInterval: TIME_TO_REFETCH });

  const [triggerGetTrendsByMonthQuery, {
    data: dataByMonth,
    error: errorByMonth,
    isError: isErrorByMonth,
    isLoading: isLoadingByMonth,
    currentData: currentDataByMonth,
  }] = useLazyGetTrendsByMonthQuery({ pollingInterval: TIME_TO_REFETCH });

  const currentError = useMemo(() => {
    if (currentSection === PeriodENUM.WEEK) {
      return {
        isError: isErrorByWeek,
        error: errorByWeek,
      };
    }

    return {
      isError: isErrorByMonth,
      error: errorByMonth,
    };
  }, [
    currentSection,
    isErrorByWeek,
    errorByWeek,
    isErrorByMonth,
    errorByMonth,
  ]);

  const isLoading = useMemo(() => {
    if (currentSection === PeriodENUM.WEEK) {
      return isLoadingByWeek;
    }

    return isLoadingByMonth;
  }, [currentSection, isLoadingByWeek, isLoadingByMonth]);

  const triggerGetTrends = useCallback(async (
    filters: FilterDisplayType,
    displayFrequency: PeriodENUM,
  ) => {
    if (currentSection === PeriodENUM.WEEK) {
      await triggerGetTrendsByWeekQuery({ filters, displayFrequency });
    } else await triggerGetTrendsByMonthQuery({ filters, displayFrequency });
  }, [
    currentSection,
    triggerGetTrendsByWeekQuery,
    triggerGetTrendsByMonthQuery,
  ]);

  const currentData = useMemo(() => {
    return currentSection === PeriodENUM.WEEK ? currentDataByWeek : currentDataByMonth;
  }, [currentSection, currentDataByWeek, currentDataByMonth]);

  const newDatasets = useMemo(() => {
    if (currentSection === PeriodENUM.WEEK) {
      if (dataByWeek?.datasets) {
        return setChartColors(dataByWeek.datasets, colors);
      }
    } else if (dataByMonth?.datasets) {
      return setChartColors(dataByMonth.datasets, colors);
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataByWeek, dataByMonth, currentSection]);

  const newLabels = useMemo(() => {
    if (currentSection === PeriodENUM.WEEK) {
      if (dataByWeek?.labels) {
        return dataByWeek.labels;
      }
    } else if (dataByMonth?.labels) {
      return dataByMonth.labels;
    }
    return [];
  }, [currentSection, dataByWeek, dataByMonth]);

  return {
    dataset: newDatasets,
    labels: newLabels,
    ...currentError,
    isLoading,
    currentData,
    triggerGetTrends,
  };
};
