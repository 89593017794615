import React, { useMemo } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import type { AxiosError } from 'axios';

import { handleError, handleValidationError } from 'utils/errorHandler';
import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { signInSchema } from 'types/validationSchemes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { PathENUM } from 'routes';

import UnAuthContainer from 'components/UnAuthContainer';
import Input from 'components/Input';
import PasswordInput from 'components/Input/PasswordInput';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import NavToSection from 'components/NavToSection';

import StyledSignIn from './SignInPage.styles';

const SignInPage: React.FC = () => {
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: { email: '', password: '', agreement: false },
    validationSchema: signInSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(userThunks.loginByPassEmail(values)).unwrap();
        navigate(PathENUM.DASHBOARD);
      } catch (err) {
        handleError(err, {
          errors: {
            404: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              setFieldError('email', axiosError.response?.data.message);
            },
            401: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              setFieldError('password', axiosError.response?.data.message);
            },
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const isUnFilledFields = useMemo(() => {
    return !formik.values.email || !formik.values.password;
  }, [formik.values]);

  const onClickForgotPass = () => {
    navigate(PathENUM.FORGOT_PASSWORD);
  };

  return (
    <UnAuthContainer>
      <StyledSignIn>
        <div className="sign-in__title">
          <h1>Sign In</h1>

          <p className="paragraph">Welcome back!</p>
        </div>

        <form onSubmit={formik.handleSubmit} className="sign-in__form-section">
          <Input
            label="Email"
            nameInput="email"
            onChangeValue={formik.handleChange}
            value={formik.values.email}
            errorText={formik.errors.email}
            isTouched={formik.touched.email}
            onBlur={formik.handleBlur}
          />

          <PasswordInput
            label="Password"
            nameInput="password"
            onChangeValue={formik.handleChange}
            value={formik.values.password}
            errorText={formik.errors.password}
            isTouched={formik.touched.password}
            onBlur={formik.handleBlur}
          />

          <div className="sign-in__form-section__remember-me-area">
            <Checkbox
              id="agreement"
              title="Remember me"
              value={formik.values.agreement}
              onChange={formik.handleChange}
            />

            <Button variant="text" onClick={onClickForgotPass}>Forgot Password?</Button>
          </div>

          <div className="sign-in__form-section__button-area">
            <Button
              loading={isLoading}
              variant="contained"
              disabled={!formik.isValid || isUnFilledFields}
              type="submit"
            >
              Sign In
            </Button>
          </div>
        </form>

        <NavToSection
          title="Don’t have an account yet?"
          titleButton="Sign Up"
          path={PathENUM.SIGN_UP}
        />
      </StyledSignIn>

    </UnAuthContainer>
  );
};

export default SignInPage;
