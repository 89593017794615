import React, { useState } from 'react';
import Input from 'components/Input';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';

type PropsType = {
  isRequired?: boolean;
  isTouched?: boolean;
  nameInput: string;
  value: string;
  errorText?: string;
  label?: string;
  placeholder?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChangeValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const PasswordInput: React.FC<PropsType> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const currentType = showPassword ? 'text' : 'password';

  return (
    <Input
      nameInput={props.nameInput}
      label={props.label}
      placeholder={props.placeholder}
      errorText={props.errorText}
      isRequired={props.isRequired}
      value={props.value}
      onChangeValue={props.onChangeValue}
      onBlur={props.onBlur}
      type={currentType}
      isTouched={props.isTouched}
      endAdornment={
        (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff className="input-icon" /> : <Visibility className="input-icon" />}
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
};

export default PasswordInput;
