import React from 'react';
import type { LoadingButtonProps } from '@mui/lab/LoadingButton';
import LoadingButton from '@mui/lab/LoadingButton';
import StyledButton from './Button.styles';

interface IProps extends LoadingButtonProps {
  isPressed?: boolean;
}

const Button: React.FC<IProps> = (props) => {
  return (
    <StyledButton ispressed={props.isPressed} className={props.className}>
      <LoadingButton {...props} className="button-text">
        {props.children}
      </LoadingButton>
    </StyledButton>
  );
};

export default Button;
