import type { FC } from 'react';
import type { IIcon } from '../../types/commonTypes';

const DashboardIcon: FC<IIcon> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 44 44" fill="currentColor" className={className}>
    <defs>
    <linearGradient id="myGradient" x1="27.1458" y1="-13.7277" x2="17.3958" y2="49.1057" gradientUnits="userSpaceOnUse">
    <stop stopColor="#569DEC" />
    <stop offset="1" stopColor="#096FE2" />
    </linearGradient>
    </defs>
    <rect x="0.333313" y="0.333984" width="43.3333" height="43.3333" rx="3" fill="url('#myGradient')" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.8229 8.04774C39.0354 8.63562 39.5418 10.0951 38.9539 11.3077L35.0049 19.4349C32.7496 24.0864 27.3864 26.3339 22.4888 24.6801C20.0486 23.856 17.3667 24.8766 16.0916 27.1144L11.3523 35.6301C10.6852 36.8009 9.19523 37.2092 8.02444 36.542C6.85365 35.8749 6.44534 34.385 7.11246 33.2142L11.8518 24.6985C14.2831 20.4315 19.3971 18.4855 24.0501 20.0568C26.6186 20.9241 29.4312 19.7454 30.614 17.306L34.563 9.17872C35.1509 7.96621 36.6104 7.45985 37.8229 8.04774Z" fill="white" />
    </svg>
  );
};

export default DashboardIcon;
