import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  .empty-container {
    width: 100%;
    min-height: calc(100vh - 300px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .jobs-list {
    border: 1px solid ${({ theme }) => theme.mainTheme.color.blue20};
    border-radius: 12px;
    background-color: ${({ theme }) => theme.mainTheme.color.background};
  }
`;
