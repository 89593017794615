import dayjs from 'dayjs';
import type { ChartDataset, Point, ScriptableContext } from 'chart.js';
import { defaultTheme } from 'styles/theme';
import type { ResponseMostPopularType } from 'types/apiTypes/statisticApiTypes';
import type { MultiLineGraphType, MultiGraphReduceResultType } from 'types/apiTypes/chartTypes';
import { getRandomColor } from './getRandomColorHelper';

export const convertMultiLineGraphParamsToDataset = (dataset: MultiLineGraphType[])
  : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
  const res = dataset.reduce((acc, item) => {
    acc.labels.add(item.weekly);

    if (acc.skills[item.skill]) {
      acc.skills[item.skill].data.push(+item.relatedFeedItems);
    } else {
      const randomColor = getRandomColor();
      acc.skills[item.skill] = {
        label: item.skill,
        data: [+item.relatedFeedItems],
        borderColor: randomColor,
        backgroundColor: randomColor,
        pointBorderColor: '#fff',
      };
    }
    return acc;
  }, {
    labels: new Set(),
    skills: {},
  } as MultiGraphReduceResultType);

  const labels = Array.from(res.labels).map((item) => dayjs(item).format('DD-MM-YYYY'));

  const result = {
    labels,
    datasets: Object.values(res.skills),
  };
  return result;
};

export const convertMultiBarGraphParamsToDataset = (data: { [s: number]: number })
  : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
  const labels = Object.keys(data);
  const dataset = {
    data: Object.values(data),
    barBorderRadius: 50,
    categoryPercentage: 0.4,
    barPercentage: 0.6,
    backgroundColor: defaultTheme.mainTheme.color.blue50,
  };
  return { labels, datasets: [dataset] };
};

export const convertDoughnutGraphParamsToDataset = (
  dataset: ChartDataset<'doughnut', (number | Point | null)[]>,
  colors: string[] | undefined,
) => {
  const newDataset = { ...dataset };

  if (colors) {
    const limitedColorList = colors.slice(0, dataset.data.length);
    newDataset.backgroundColor = limitedColorList;
    return newDataset;
  }

  const arrayColors: string[] = [];
  newDataset.data.forEach(() => {
    arrayColors.push(getRandomColor());
  });

  newDataset.backgroundColor = arrayColors;
  return newDataset;
};

export const convertToDoughnutData = (data?: ResponseMostPopularType[]) => {
  const labels: string[] = [];
  const datasets: ChartDataset[] = [{ data: [] }];
  if (data) {
    data.forEach((element) => {
      labels.push(element.skill);
      datasets[0].data.push(+element.related_feed_items);
    });
  }
  return { labels, datasets };
};

export const convertGradientLineGraphParamsToDataset = (data: { [s: number]: number })
  : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
  const labels = Object.keys(data);

  const dataset = {
    data: Object.values(data),
  };
  return { labels, datasets: [dataset] };
};

export const convertPriceBarGraphParamsToDataset = (data: { [s: string]: number })
  : { labels: string[]; datasets: ChartDataset<'bar', (number | null)[]>[] } => {
  const labels = Object.keys(data[0]);

  const dataset = {
    data: Object.values(data[0]),
    barBorderRadius: 50,
    categoryPercentage: 0.4,
    barPercentage: 0.6,
    backgroundColor: defaultTheme.mainTheme.color.blue50,
  };
  return { labels, datasets: [dataset] };
};

export const setGradientColorToLineGraph = (dataset: ChartDataset<'line', (number | Point | null)[]>) => {
  const getGradient = (context: ScriptableContext<'line'>) => {
    const ctx = context.chart.ctx;
    const gradient = ctx.createLinearGradient(0, 0, 0, 180);
    gradient.addColorStop(0, defaultTheme.mainTheme.color.blue5002);
    gradient.addColorStop(1, defaultTheme.mainTheme.color.blue5000);
    return gradient;
  };

  const newDataset = { ...dataset };
  newDataset.borderColor = defaultTheme.mainTheme.color.blue50;
  newDataset.pointBackgroundColor = defaultTheme.mainTheme.color.blue50;
  newDataset.borderWidth = 2;
  newDataset.borderCapStyle = 'round';
  newDataset.tension = 0.4;
  newDataset.pointRadius = 0;
  newDataset.pointHitRadius = 10;
  newDataset.fill = true;
  newDataset.backgroundColor = getGradient;
  return newDataset;
};

export const convertToDoughnutDataFromObj = (data?: Record<string, number>) => {
  const labels: string[] = [];
  const datasets: ChartDataset[] = [{ data: [] }];
  if (data) {
    Object.entries(data).forEach((element) => {
      labels.push(element[0]);
      datasets[0].data.push(+element[1]);
    });
  }
  return { labels, datasets };
};
