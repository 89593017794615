import { useCallback, useMemo } from 'react';
import type { ChartDataset, Point } from 'chart.js';

import type { FilterDisplayType } from 'types/filtersType';
import {
  useLazyGetTimeLineByHourQuery,
  useLazyGetTimeLineByWeekQuery,
} from 'api/services/chartEndpoints/timeLineEndpoints';

import { TIME_TO_REFETCH } from 'constants/mainConstants';

export const useTimeLine = (currentSection: string) => {
  const [triggerGetTimeLine1, {
    data,
    error,
    isError,
    isLoading: isLoading1,
    currentData: currentData1,
  }] = useLazyGetTimeLineByHourQuery({ pollingInterval: TIME_TO_REFETCH });

  const [triggerGetTimeLine2, {
    data: data2,
    error: error2,
    isError: isError2,
    isLoading: isLoading2,
    currentData: currentData2,
  }] = useLazyGetTimeLineByWeekQuery({ pollingInterval: TIME_TO_REFETCH });

  const currentError = useMemo(() => {
    if (currentSection === 'hour') {
      return {
        isError,
        error,
      };
    }
    return {
      isError: isError2,
      error: error2,
    };
  }, [currentSection, isError2, error2, isError, error]);

  const isLoading = useMemo(() => {
    if (currentSection === 'hour') {
      return isLoading1;
    }
    return isLoading2;
  }, [currentSection, isLoading2, isLoading1]);

  const triggerGetTimeLine = useCallback(async (filters: FilterDisplayType) => {
    if (currentSection === 'hour') {
      await triggerGetTimeLine1({ filters });
    } else await triggerGetTimeLine2({ filters });
  }, [currentSection, triggerGetTimeLine1, triggerGetTimeLine2]);

  const currentData = useMemo(() => {
    return currentSection === 'hour' ? currentData1 : currentData2;
  }, [currentSection, currentData1, currentData2]);

  const newDatasets = useMemo(() => {
    if (currentSection === 'hour') {
      if (data?.datasets) {
        return data.datasets as ChartDataset<'bar', (number | Point | null)[]>[];
      }
    } else if (data2?.datasets) {
      return data2.datasets as ChartDataset<'bar', (number | Point | null)[]>[];
    }
    return [];
  }, [data?.datasets, data2?.datasets, currentSection]);

  const newLabels = useMemo(() => {
    if (currentSection === 'hour') {
      if (data?.labels) {
        return data.labels;
      }
    } else if (data2?.labels) {
      return data2.labels;
    }
    return [];
  }, [currentSection, data?.labels, data2?.labels]);

  return {
    dataset: newDatasets,
    labels: newLabels,
    ...currentError,
    isLoading,
    currentData,
    triggerGetTimeLine,
  };
};
