import styled from 'styled-components';

export default styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  .legend {
    &__ul {
      display: flex;
      flex-direction: column;
      row-gap: 14px;
      width: 100%;
    }

    &__li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
    }

    &__text-area {
      display: flex;
      align-items: center;
    }

    &__box {
      width: 8px;
      height: 8px;
      border-width: 0;
      border-radius: 2px;
      margin-right: 8px;
    }

    &__text {
      color: ${({ theme }) => theme.mainTheme.color.gray50};

      &_hidden {
        text-decoration: line-through;
        color: ${({ theme }) => theme.mainTheme.color.gray40};
      }
    }

    &__svg-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 8px;
      color: ${({ theme }) => theme.mainTheme.color.gray30};
    }
  }
`;
