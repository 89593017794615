import React, { useState, useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { AxiosError } from 'axios';

import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { resetPassSchema } from 'types/validationSchemes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import { PathENUM } from 'routes';

import Input from 'components/Input';
import Button from 'components/Button';

import StyledResetPassword from '../../ForgotPassPage.styles';

const ResetPassword: React.FC = () => {
  const isLoading = useAppSelector((state) => state.userSlice.isLoading);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [timerSeconds, setSeconds] = useState(59);
  const [timerActive, setTimerActive] = useState(true);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;
    if (timerSeconds > 0 && timerActive) {
      timeoutId = setTimeout(() => setSeconds((prev) => prev - 1), 1000);
    } else {
      setTimerActive(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timerSeconds, timerActive]);

  const currentTime = timerSeconds > 10 ? `${timerSeconds}` : `0${timerSeconds}`;

  const formik = useFormik({
    initialValues: { code: '', email: searchParams.get('email') || '' },
    validationSchema: resetPassSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        const token = await dispatch(userThunks.checkCode(values)).unwrap();
        const currentPath = `${PathENUM.FORGOT_PASSWORD}/${PathENUM.CHANGE_PASSWORD}`;
        navigate({
          pathname: currentPath,
          search: `?token=${token}`,
        });
      } catch (err) {
        handleError(err, {
          errors: {
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const isUnFilledFields = useMemo(() => {
    return !formik.values.email ||
      !formik.values.code;
  }, [formik.values]);

  const onClickSendAgain = async () => {
    try {
      await dispatch(userThunks.forgotPassword({ email: formik.values.email })).unwrap();
      setSeconds(59);
      setTimerActive(true);
    } catch (err) {
      handleError(err, {
        errors: {
          400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
            handleValidationError(formik.setFieldError, axiosError.response!.data.data);
          },
        },
      });
    }
  };

  return (
    <StyledResetPassword>
      <div className="forgot-password-part__title">
        <h1>Reset Password</h1>

        <div>
          <p className="paragraph">We have sent code to <span>{formik.values.email}</span></p>

          <p>Use it to reset password</p>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} className="forgot-password-part__form-section">
        <Input
          label="Code"
          nameInput="code"
          onChangeValue={formik.handleChange}
          value={formik.values.code}
          errorText={formik.errors.code}
          isTouched={formik.touched.code}
          onBlur={formik.handleBlur}
        />

        <div className="forgot-password-part__form-section__button-area">
          <Button
            loading={isLoading}
            variant="contained"
            disabled={!formik.isValid || isUnFilledFields}
            type="submit"
          >
            Reset Password
          </Button>
        </div>
      </form>

      <div className="forgot-password-part__last-section">
        <p className="paragraph">Didn’t receive a code?</p>

        <Button variant="text" disabled={timerActive} onClick={onClickSendAgain}>
          {timerActive ? `Send again after 00:${currentTime}` : 'Send again'}
        </Button>
      </div>
    </StyledResetPassword>
  );
};

export default ResetPassword;
