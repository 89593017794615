import { createSelector } from 'reselect';
import type { FilterDisplayType } from 'types/filtersType';
import type { StoreType } from 'store/store';

export const savedFiltersSelector = createSelector(
  [
    (state: StoreType) => state.filterSlice.filters,
  ],
  (filters) => {
    const savedFilters: FilterDisplayType = {
      selectedSkills: {
        only: filters.selectedSkills.only.saved,
        exclude: filters.selectedSkills.exclude.saved,
      },
      selectedRegions: {
        only: filters.selectedRegions.only.saved,
        exclude: filters.selectedRegions.exclude.saved,
      },
      selectedCountries: {
        only: filters.selectedCountries.only.saved,
        exclude: filters.selectedCountries.exclude.saved,
      },
      dateRange: {
        from: filters.dateRange.from.saved,
        to: filters.dateRange.to.saved,
      },
      priceRange: {
        from: filters.priceRange.from.saved,
        to: filters.priceRange.to.saved,
        fixedBudget: filters.priceRange.fixedBudget.saved,
      },
    };
    return savedFilters;
  },
);

export const savedDateRangeSelector = createSelector(
  [
    (state: StoreType) => state.filterSlice.filters.dateRange,
  ],
  (dateRange) => {
    return { from: dateRange.from.saved, to: dateRange.to.saved };
  },
);

export const savedPriceRangeSelector = createSelector(
  [
    (state: StoreType) => state.filterSlice.filters.priceRange,
  ],
  (priceRange) => {
    return {
      from: priceRange.from.saved,
      to: priceRange.to.saved,
      fixedBudget: priceRange.fixedBudget.saved,
    };
  },
);

export const appliedFiltersSelector = createSelector(
  [
    (state: StoreType) => state.filterSlice.filters,
  ],
  (filters) => {
    const savedFilters: FilterDisplayType = {
      selectedSkills: {
        only: filters.selectedSkills.only.applied,
        exclude: filters.selectedSkills.exclude.applied,
      },
      selectedRegions: {
        only: filters.selectedRegions.only.applied,
        exclude: filters.selectedRegions.exclude.applied,
      },
      selectedCountries: {
        only: filters.selectedCountries.only.applied,
        exclude: filters.selectedCountries.exclude.applied,
      },
      dateRange: {
        from: filters.dateRange.from.applied,
        to: filters.dateRange.to.applied,
      },
      priceRange: {
        from: filters.priceRange.from.applied,
        to: filters.priceRange.to.applied,
        fixedBudget: filters.priceRange.fixedBudget.applied,
      },
    };
    return savedFilters;
  },
);
