import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import TodayIcon from '@mui/icons-material/Today';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import clsx from 'clsx';

import { DateRangeNameENUM } from 'types/filtersType';

import Input from 'components/Input';
import StyledDateInput from './DateInput.styles';
import { DEFAULT_FORMAT, type DateInputType } from '../../DateRangeTypes';
import { REGEX_ALL_DAY, REGEX_DAY, REGEX_DIGIT, REGEX_MONTH } from './DateInput.constants';

dayjs.extend(customParseFormat);

type PropsType = {
  value: string | null;
  nameInput: DateInputType;
  placeholder: string;
  nameDate: DateRangeNameENUM;
  isDatePickerOpened: DateInputType | null;
  anotherDates: Date | null;
  onClick: (item: DateInputType) => void;
  onChangeDate: (name: DateRangeNameENUM, value: string | null) => void;
  onCloseDatePicker: () => void;
};

const DateInput: React.FC<PropsType> = ({
  value,
  nameInput,
  nameDate,
  placeholder,
  isDatePickerOpened,
  anotherDates,
  onClick,
  onChangeDate,
  onCloseDatePicker,
}) => {
  const isPickerOpen = useMemo(() => {
    return isDatePickerOpened === nameInput;
  }, [isDatePickerOpened, nameInput]);

  const onClearDateInput = () => {
    if (value) {
      onChangeDate(nameDate, null);
    }
  };

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 11) {
      if (event.target.value.length && !REGEX_DIGIT.test(event.target.value)) {
        return;
      }

      if (event.target.value.length === 2 && !REGEX_DAY.test(event.target.value)) {
        return;
      }

      if (event.target.value.length === 5 && !REGEX_MONTH.test(event.target.value)) {
        return;
      }
      if (event.target.value.length === 10 && !REGEX_ALL_DAY.test(event.target.value)) {
        return;
      }

      // eslint-disable-next-line no-bitwise
      if (~[2, 5].indexOf(event.target.value.length)) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        if (value?.[value?.length - 1] === '-') {
          // eslint-disable-next-line no-param-reassign
          event.target.value = event.target.value.slice(0, -1);
        } else {
          // eslint-disable-next-line no-param-reassign
          event.target.value += '-';
        }
      }
      onChangeDate(nameDate, event.target.value);
    } else {
      event.preventDefault();
    }
  };

  return (
    <StyledDateInput>
      <div>
        <Input
          value={value || ''}
          onClick={() => onClick(nameInput)}
          onChangeValue={handleChangeDate}
          nameInput={nameInput}
          placeholder="dd-mm-yyyy"
          label={placeholder}
        />
        <IconButton
          onClick={onClearDateInput}
          className="close-icon"
        >
          {
            value
              ? <CloseIcon className="date-picker__icon delete" />
              : <TodayIcon className="date-picker__icon" />
          }
        </IconButton>
      </div>

      <DatePicker
        open={isPickerOpen}
        dateFormat="dd-MM-yyyy"
        onChange={(date) => {
          onChangeDate(nameDate, dayjs(date).format(DEFAULT_FORMAT));
          onCloseDatePicker();
        }}
        className="date-picker"
        openToDate={REGEX_ALL_DAY.test(value || '') ? dayjs(value, DEFAULT_FORMAT).toDate() : anotherDates || new Date()}
        maxDate={nameDate === DateRangeNameENUM.FROM && anotherDates
          ? anotherDates
          : new Date()}
        minDate={
          nameDate === DateRangeNameENUM.TO && anotherDates
            ? anotherDates
            : null}
        selected={
          REGEX_ALL_DAY.test(value || '') ? dayjs(value, DEFAULT_FORMAT).toDate() : null}
        renderCustomHeader={(params) => {
          const monthLabel = dayjs(params.date).format('MMMM YYYY');
          return (
            <div className="react-datepicker__month-name">
              <KeyboardArrowLeftIcon
                className={clsx([{ disabled: params.prevMonthButtonDisabled }])}
                onClick={() => params.decreaseMonth()}
              />
              <h4>{monthLabel}</h4>
              <KeyboardArrowRightIcon
                className={clsx([{ disabled: params.nextMonthButtonDisabled }])}
                onClick={() => params.increaseMonth()}
              />
            </div>
          );
        }}
        formatWeekDay={(day) => {
          return <h4 className="react-datepicker__week__day">{day.slice(0, 3)}</h4>;
        }}
      />

    </StyledDateInput>
  );
};

export default DateInput;
