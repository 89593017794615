import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .loading-section {
    height: 218px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .disabled {
    display: none;
  }

  .without-content-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${({ theme }) => theme.mainTheme.color.gray40};
    }
  }
`;
