import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .loading-section {
    height: 250px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .graph-section {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    width: 100%;
    height: 250px;
  }

  .graph-section__graph {
    width: 100%;
  }

  .graph-section__legend {
    width: 30%;
  }
`;
