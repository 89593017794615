import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .app__container {
    width: 100%;
  }

  .Toastify__toast-theme--light {
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }

  .toast-container {
    width: auto;
  }

  .Toastify__toast {
    min-height: auto;
    border-radius: 10px;
    padding: 10px;
  }
`;
