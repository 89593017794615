import dayjs from 'dayjs';

import type { JobListItemType } from 'types/jobTypes';
import StyledJobItem from './JobItem.styles';

type PropsType = {
  job: JobListItemType;
  onClick?: (job: number) => void;
};

const JobItem: React.FC<PropsType> = ({ job, onClick }) => {
  const currentPublicationDate = dayjs(job.pubDate).format('MMM D, YYYY, h:mm A');

  const onClickJob = () => {
    onClick?.(job.feedItemId);
  };

  return (
    <StyledJobItem onClick={onClickJob}>
      <div className="job-item__title-area">
        <h4 className="job-item__title-area__category">{job?.category?.categoryTitle || ''}</h4>

        <h3>{job.jobTitle}</h3>

        <h4>Publication Date {currentPublicationDate}</h4>
      </div>

      <div className="job-item__skills-area">
        {job.skills.map((skill, index) => (
          <div
            key={index}
            className="skill-plate"
          >
            <h4>{skill.skillId}</h4>
          </div>
        ))}
      </div>
    </StyledJobItem>
  );
};

export default JobItem;
