import { Paper } from '@mui/material';
import styled from 'styled-components';

export default styled(Paper)`
  padding: 25px;
  box-shadow: none !important;
  border-radius: ${({ theme }) => theme.borderRadius} !important;
  border: 1px solid ${({ theme }) => theme.mainTheme.color.blue20};
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  height: 100%;

  .paper {
    &__top-area {
      display: flex;
      flex-direction: column;
      width: 100%;

      &__title-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
      }

      &__title-area {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  h4 {
    color: #869AB0;
  }
`;
