import styled, { css } from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;

  .close-icon {
    position: absolute;
    right: 8px;
    top: 8px;

    .delete {
      color: ${({ theme }) => theme.mainTheme.color.blue50};
    }
  }

  .MuiInputLabel-root {
    z-index: 0;
  }

  .date-picker {
    background-color: ${({ theme }) => theme.mainTheme.color.background};
    z-index: 9999;
  }

  .react-datepicker__tab-loop {
    position: relative;
  }

  .react-datepicker-wrapper {
    display: none;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    inset: unset !important;
    right: 0 !important;
    transform: none !important;
  }

  .disabled {
    color: ${({ theme }) => theme.mainTheme.color.gray30};
    pointer-events: none;
  }

  .react-datepicker__month-container {
    float: none;
    width: 100%;

    .react-datepicker__header {
      padding: 0;

      .react-datepicker__month-name {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        h4 {
          font-weight: 600;
        }
      }
    }


    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__month {
      margin: 0;
    }

    .react-datepicker__week {
      display: flex;
      justify-content: space-between;

      &__day {
        width: 38px;
        line-height: 38px;
      }
    }
  }

  .react-datepicker__day-names {
    margin: 0;

    .react-datepicker__day-name {
      width: auto;
      margin: 0;
    }
  }

  .react-datepicker__day {
    height: 38px;
    line-height: 35px;
    width: 38px;
    margin: 0;

    font-family: ${({ theme }) => theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    color: ${({ theme }) => theme.mainTheme.color.gray50};

    :hover {
      background-color: ${({ theme }) => theme.mainTheme.color.gray20};
    }
  }

  .react-datepicker__day.react-datepicker__day--disabled {
    color: ${({ theme }) => theme.mainTheme.color.gray30};

    :hover {
      background-color: ${({ theme }) => theme.mainTheme.color.background};
    }
  }

  .react-datepicker__day--keyboard-selected {
    border: none;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.mainTheme.color.background};
  }

  .react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
    border: none;
    border-radius: 8px;
  }

  .react-datepicker-popper .react-datepicker__navigation {
    padding-top: 12px;
    color: #000;
  }

  .react-datepicker {
    width: 358px;
    padding: 20px;
    ${({ theme }) => css`
      box-shadow: ${theme.boxShadowExtraSmall};
      border-radius: ${theme.borderRadius};
      border: 1px solid ${({ theme }) => theme.mainTheme.color.blue30};
      background-color: ${theme.mainTheme.color.background};
    `}
  }

  .react-datepicker__header {
    border-bottom: solid 5px var(--light) !important;
    background: ${({ theme }) => theme.mainTheme.color.background} !important;
  }

  .react-datepicker__current-month {
    color: ${({ theme }) => theme.mainTheme.color.gray50} !important;
  }

  .react-datepicker__day.react-datepicker__day--today {
    border-radius: 8px;
    color: ${({ theme }) => theme.mainTheme.color.gray50} !important;

    :hover {
      background-color: ${({ theme }) => theme.mainTheme.color.gray20};
    }
  }

  .react-datepicker__day--selected {
    border-radius: 8px;
    font-weight: 600 !important;
    ${({ theme }) => css`
      color: ${({ theme }) => theme.mainTheme.color.gray50};
      background-color: ${theme.mainTheme.color.blue20} !important;
      border: 2px solid ${({ theme }) => theme.mainTheme.color.blue50} !important;
    `}
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 8px !important;
    background-color: ${({ theme }) => theme.mainTheme.color.gray20};
  }
`;
