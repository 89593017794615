import styled from 'styled-components';

export default styled.div`
  height: 218px;
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 40px;
  justify-content: space-between;

  .doughnut-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    canvas {
      width: 218px !important;
      height: 218px !important;
      z-index: 1;
    }

    .center-count-container {
      position: absolute;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        > h2 {
          color: ${({ theme }) => theme.mainTheme.color.gray50};
        }
  
        > h4 {
          color: ${({ theme }) => theme.mainTheme.color.gray40};
          font-weight: 600;
        }
      }
    }
  }
`;
