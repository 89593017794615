import styled from 'styled-components';

export default styled.div`
  .filters-modal {
    &__header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 41px;
    }

    &__title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32.69px;
      color: ${({ theme }) => theme.mainTheme.color.gray50};
    }

    &__buttons-wrapper {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    &__apply-btn {
      & > button {
        padding: 5px 15px;
      }
    }

    &__close-btn {
      & > button {
        padding: 6px 15px;
        border: none;
        background: ${({ theme }) => theme.mainTheme.color.blue20};
        color: ${({ theme }) => theme.mainTheme.color.gray50};
      }
    }

    &__filters-wrapper {
      display: flex;
      flex-direction: column;
      gap: 40px;
    }
  }
`;
