import React from 'react';

type PropsType = {
  id: string;
  count?: number;
};

const InsideCount: React.FC<PropsType> = ({ id, count }) => {
  return (
    <div
      id={id}
      className="center-count-container"
    >
      <div>
        <h2>{count?.toFixed(0)}</h2>

        <h4>requests</h4>
      </div>
    </div>
  );
};

export default InsideCount;
