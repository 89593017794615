import type { ChartDataset } from 'chart.js';

import { convertMultiLineGraphParamsToDataset } from 'utils/setParamsHelper';
import type { FilterDisplayType, PeriodENUM } from 'types/filtersType';
import { baseApi } from 'api/baseApiBuilder';
import type { MultiLineGraphType } from '../../../types/apiTypes/chartTypes';
import type { ChartType } from '../../../types/apiTypes/statisticApiTypes';
import { MethodENUM, StatisticApiPathENUM } from '../../../types/apiTypes/statisticApiTypes';

const trendsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTrendsByWeek: builder.query<ChartType, {
      filters?: FilterDisplayType;
      displayFrequency: PeriodENUM;
    }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_TRENDS,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
          displayFrequency: body.displayFrequency,
        },
      }),
      providesTags: ['TrendsByWeek'],
      transformResponse: (response)
        : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
        return convertMultiLineGraphParamsToDataset(response as MultiLineGraphType[]);
      },
    }),

    getTrendsByMonth: builder.query<ChartType, {
      filters?: FilterDisplayType;
      displayFrequency: PeriodENUM;
    }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_TRENDS,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
          displayFrequency: body.displayFrequency,
        },
      }),
      providesTags: ['TrendsByMonth'],
      transformResponse: (response)
        : { labels: string[]; datasets: ChartDataset<'line', (number | null)[]>[] } => {
        return convertMultiLineGraphParamsToDataset(response as MultiLineGraphType[]);
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetTrendsByWeekQuery,
  useLazyGetTrendsByMonthQuery,
} = trendsApi;
