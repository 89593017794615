import React from 'react';

import skillsImage from '../../assets/images/skills.png';
import pricesImage from '../../assets/images/prices.png';
import StyledUnAuthContainer from './UnAuthContainer.styles';

type PropsType = React.PropsWithChildren & {
  withOutRightSection?: boolean;
};

const UnAuthContainer: React.FC<PropsType> = ({ children, withOutRightSection }) => {
  return (
    <StyledUnAuthContainer>
      <div className="un-auth-container__left">
        {children}
      </div>
      {
        withOutRightSection ? null : (
          <div className="un-auth-container__right">
            <img
              src={skillsImage}
              alt="Skills image"
              className="right-container__picture right-container__picture--top"
            />
            <img
              src={pricesImage}
              alt="Prices image"
              className="right-container__picture right-container__picture--bottom"
            />
          </div>
        )
      }
    </StyledUnAuthContainer>
  );
};

export default UnAuthContainer;
