import styled from 'styled-components';

export default styled.div`
  & .MuiButton-textPrimary {
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }

  & .MuiButton-outlinedPrimary {
    color: ${({ theme }) => theme.mainTheme.color.background};
    background: ${({ theme }) => theme.mainTheme.color.blue50};
  }

  & .MuiButton-root {
    padding: 5px 17.5px;
    min-width: 75px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    border: none;
    width: fit-content;
  }
`;
