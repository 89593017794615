import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}
body {
  margin: 0;
  background-color: ${(p) => p.theme.mainTheme.color.background};
  color: ${({ theme }) => theme.mainTheme.color.gray50};
  font-family: ${(p) => p.theme.font.family};
  font-style: normal;
  div {
    box-sizing: border-box;
  }

  h1 {
    font-family: ${(p) => p.theme.font.family};
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h2 {
    font-family: ${(p) => p.theme.font.family};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h3 {
    font-family: ${(p) => p.theme.font.family};
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  h4 {
    font-family: ${(p) => p.theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .paragraph {
    font-family: ${(p) => p.theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
  }

  .button-text {
    font-family: ${(p) => p.theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .scroll-position {
    width: 100% !important;
  }

  .ScrollbarsCustom-TrackY {
    width: 4px !important;
    top: 0 !important;
    height: 100% !important;
    background: ${({ theme }) => theme.mainTheme.color.gray20} !important;
  }

  .ScrollbarsCustom-ThumbY {
    background: ${({ theme }) => theme.mainTheme.color.blue60} !important;
  }

}
`;
