import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export default styled(FormControlLabel)`
  font-family: ${({ theme }) => theme.font.family};

  .Mui-checked {
    color: ${({ theme }) => theme.mainTheme.color.blue50} !important;
  }

  .MuiCheckbox-colorPrimary {
    padding: 0 8px;
    color: ${({ theme }) => theme.mainTheme.color.gray30};
  }

  .MuiFormControlLabel-label {
    font-family: ${(p) => p.theme.font.family};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${({ theme }) => theme.mainTheme.color.gray50};
  }
`;
