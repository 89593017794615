import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Grid } from '@mui/material';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setAppliedDateRange,
  setAppliedMainFilters,
  setAppliedPriceRange,
  setCanResetFilters,
  setIsApplyFilters,
} from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import type { DateRangeDisplayType, PriceRangeDisplayType } from 'types/filtersType';
import {
  DateRangeNameENUM,
  NameFilterENUM,
  PriceRangeNameENUM,
  TypeFilterENUM,
} from 'types/filtersType';
import {
  setDateRangeToStorage,
  setMainFiltersToStorage,
  setPriceRangeToStorage,
} from 'utils/storageFiltersHelper';

import FilterCollapse from '../FilterCollapse/FilterCollapse';
import StyledFiltersActions from './FiltersActions.styles';

const titlesArray = [
  'Date Range',
  'Price Range',
  'Skills Included',
  'Skills Excluded',
  'Regions Included',
  'Regions Excluded',
  'Countries Included',
  'Countries Excluded',
];

export type DisplayedFilterType = {
  text: string;
  type: DateRangeNameENUM | PriceRangeNameENUM | TypeFilterENUM;
  section?: NameFilterENUM;
};

const FiltersActions: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(appliedFiltersSelector);

  const {
    dateRange,
    priceRange,
    selectedSkills,
    selectedRegions,
    selectedCountries,
  } = useAppSelector(appliedFiltersSelector);

  const setCurrentArray = (options: {
    array: string[];
    type: DateRangeNameENUM | PriceRangeNameENUM | TypeFilterENUM;
    section?: NameFilterENUM;
  }) => {
    const { array, type, section } = options;
    return array.map((item) => ({ text: item, type, section }));
  };

  const getCurrentDateFilter = (dateRange: DateRangeDisplayType) => {
    const result: DisplayedFilterType[] = [];
    if (dateRange.from) {
      result.push({ text: `from ${dateRange.from}`, type: DateRangeNameENUM.FROM });
    }
    if (dateRange.to) {
      result.push({ text: `to ${dateRange.to}`, type: DateRangeNameENUM.TO });
    }

    return result;
  };

  const getCurrentPriceFilter = (priceRange: PriceRangeDisplayType) => {
    const result: DisplayedFilterType[] = [];
    if (priceRange.from) {
      result.push({ text: `from $${priceRange.from}`, type: PriceRangeNameENUM.FROM });
    }
    if (priceRange.to) {
      result.push({ text: `to $${priceRange.to}`, type: PriceRangeNameENUM.TO });
    }
    if (priceRange.fixedBudget) {
      result.push({ text: `fix budget $${priceRange.fixedBudget}`, type: PriceRangeNameENUM.FIXED_BUDGET });
    }

    return result;
  };

  const [formattedSelectedSkillsOnly, setFormattedSelectedSkillsOnly] = useState(
    setCurrentArray({
      array: selectedSkills.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.SKILLS,
    }),
  );
  const [formattedSelectedSkillsExclude, setFormattedSelectedSkillsExclude] = useState(
    setCurrentArray({
      array: selectedSkills.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.SKILLS,
    }),
  );
  const [formattedSelectedRegionsOnly, setFormattedSelectedRegionsOnly] = useState(
    setCurrentArray({
      array: selectedRegions.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.REGIONS,
    }),
  );
  const [formattedSelectedRegionsExclude, setFormattedSelectedRegionsExclude] = useState(
    setCurrentArray({
      array: selectedRegions.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.REGIONS,
    }),
  );
  const [formattedSelectedCountriesOnly, setFormattedSelectedCountriesOnly] = useState(
    setCurrentArray({
      array: selectedCountries.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.COUNTRIES,
    }),
  );
  const [formattedSelectedCountriesExclude, setFormattedSelectedCountriesExclude] = useState(
    setCurrentArray({
      array: selectedCountries.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.COUNTRIES,
    }),
  );

  const [dateFilter, setDateFilter] = useState(
    getCurrentDateFilter(dateRange),
  );
  const [priceFilter, setPriceFilter] = useState(
    getCurrentPriceFilter(priceRange),
  );

  const setCurrentAppliedFilters = useCallback(() => {
    setFormattedSelectedSkillsOnly(setCurrentArray({
      array: selectedSkills.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.SKILLS,
    }));
    setFormattedSelectedSkillsExclude(setCurrentArray({
      array: selectedSkills.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.SKILLS,
    }));
    setFormattedSelectedRegionsOnly(setCurrentArray({
      array: selectedRegions.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.REGIONS,
    }));
    setFormattedSelectedRegionsExclude(setCurrentArray({
      array: selectedRegions.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.REGIONS,
    }));
    setFormattedSelectedCountriesOnly(setCurrentArray({
      array: selectedCountries.only,
      type: TypeFilterENUM.ONLY,
      section: NameFilterENUM.COUNTRIES,
    }));
    setFormattedSelectedCountriesExclude(setCurrentArray({
      array: selectedCountries.exclude,
      type: TypeFilterENUM.EXCLUDE,
      section: NameFilterENUM.COUNTRIES,
    }));
    setDateFilter(getCurrentDateFilter(dateRange));
    setPriceFilter(getCurrentPriceFilter(priceRange));
  }, [
    dateRange,
    priceRange,
    selectedCountries.exclude,
    selectedCountries.only,
    selectedRegions.exclude,
    selectedRegions.only,
    selectedSkills.exclude,
    selectedSkills.only,
  ]);

  useEffect(() => {
    setCurrentAppliedFilters();
  }, [setCurrentAppliedFilters]);

  const handleRemoveDateFilter = (options: DisplayedFilterType) => {
    const { type } = options;
    dispatch(setAppliedDateRange({ name: type as DateRangeNameENUM, value: null }));
    dispatch(setIsApplyFilters(false));
    setDateRangeToStorage({ key: type as DateRangeNameENUM, value: null });
  };

  const handleRemovePriceFilter = (options: DisplayedFilterType) => {
    const { type } = options;
    dispatch(setAppliedPriceRange({ name: type as PriceRangeNameENUM, value: null }));
    dispatch(setIsApplyFilters(false));
    setPriceRangeToStorage({ key: type as PriceRangeNameENUM, value: null });
  };

  const handleRemoveMainFilter = (options: DisplayedFilterType) => {
    const { type, section, text } = options;
    if (!section) {
      return;
    }

    const newArrayItems = [...filters[section][type as TypeFilterENUM]];
    const findIndex = newArrayItems.indexOf(text);
    if (findIndex !== -1) {
      newArrayItems.splice(findIndex, 1);
    }

    dispatch(setAppliedMainFilters({
      section,
      type: type as TypeFilterENUM,
      value: newArrayItems,
    }));
    dispatch(setIsApplyFilters(false));

    setMainFiltersToStorage({ section, type: type as TypeFilterENUM, resultArray: newArrayItems });
  };

  const infoArr = {
    [titlesArray[0]]: {
      title: titlesArray[0],
      filters: dateFilter,
      onClick: handleRemoveDateFilter,
      isHideButton: true,
    },
    [titlesArray[1]]: {
      title: titlesArray[1],
      filters: priceFilter,
      onClick: handleRemovePriceFilter,
      isHideButton: true,
    },
    [titlesArray[2]]: {
      title: titlesArray[2],
      filters: formattedSelectedSkillsOnly,
      onClick: handleRemoveMainFilter,
    },
    [titlesArray[3]]: {
      title: titlesArray[3],
      filters: formattedSelectedSkillsExclude,
      onClick: handleRemoveMainFilter,
    },
    [titlesArray[4]]: {
      title: titlesArray[4],
      filters: formattedSelectedRegionsOnly,
      onClick: handleRemoveMainFilter,
    },
    [titlesArray[5]]: {
      title: titlesArray[5],
      filters: formattedSelectedRegionsExclude,
      onClick: handleRemoveMainFilter,
    },
    [titlesArray[6]]: {
      title: titlesArray[6],
      filters: formattedSelectedCountriesOnly,
      onClick: handleRemoveMainFilter,
    },
    [titlesArray[7]]: {
      title: titlesArray[7],
      filters: formattedSelectedCountriesExclude,
      onClick: handleRemoveMainFilter,
    },
  };

  const filteredInfoArr = Object.values(infoArr).filter((item) => item.filters.length);

  useEffect(() => {
    if (!filteredInfoArr.length) {
      dispatch(setCanResetFilters(false));
    } else {
      dispatch(setCanResetFilters(true));
    }
  }, [dispatch, filteredInfoArr]);

  if (!filteredInfoArr.length) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <StyledFiltersActions>
        <p className="filters-actions__applied-filters">Applied Filters</p>
        <div className="filters-actions__container">
          <div className="filters-actions__wrapper">
            {filteredInfoArr.map((item, index) => (
              <div key={index} className="filters-actions__filters-container">
                <span className="filters-actions__filter-title">{item?.title}</span>
                {item &&
                  (<FilterCollapse
                    item={item}
                    removeFilter={item.onClick}
                    isHideButton={item?.isHideButton}
                  />)
                }
              </div>
            ))}
          </div>
        </div>
      </StyledFiltersActions>
    </Grid>
  );
};

export default FiltersActions;
