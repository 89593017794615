export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const defaultChartColors = [
  '#F9A825',
  '#F56B00',
  '#C62828',
  '#C2185B',
  '#E92064',
  '#911FA2',
  '#9010D1',
  '#7448DD',
  '#3E3ACB',
  '#2044D5',
  '#016BDC',
  '#2196F3',
  '#00BCD4',
  '#0088A3',
  '#008F7C',
  '#00A352',
  '#1ABC1A',
  '#6FB80A',
  '#70788B',
  '#9F8660',
];

export const getRandomColorArray = (length = 100) => defaultChartColors.slice(0, length).concat(
  defaultChartColors.slice(0, length - defaultChartColors.length),
);
