import styled, { css } from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;

  .title-area-description {
    white-space: pre-wrap;
  }

  .title-area-crown {
    margin-left: 10px;
  }

  .general__main-paper {
    background-color: ${({ theme }) => theme.mainTheme.color.blue50};
    color: ${({ theme }) => theme.mainTheme.color.background};
    padding: 16px;

    > form {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      height: 100%;
    }

    .big-input {
      height: 100%;

      .MuiFormControl-root {
        height: 100%;

        .MuiInputBase-root {
          height: 100%;
          align-items: flex-start;

          textarea {
            max-height: 92px !important;
          }
        }
      }

      .MuiOutlinedInput-input {
        color: ${({ theme }) => theme.mainTheme.color.background};
      }

      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border: 2px solid ${({ theme }) => theme.mainTheme.color.blue40};
  
          :focus {
            border: 1px solid ${({ theme }) => theme.mainTheme.color.blue30} !important;
          }
        }
      }

      .MuiFormLabel-colorPrimary {
        color: ${({ theme }) => theme.mainTheme.color.blue30};

        :focus {
          color: ${({ theme }) => theme.mainTheme.color.blue30};
        }
      }

      @media (max-width: 815px) {
        height: 180px;

        .MuiFormControl-root {
          .MuiInputBase-root {
            textarea {
              max-height: 148px !important;
            }
          }
        }
      }
    }

    .title-area {
      width: 100%;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      text-align: center;
    }

    .form {
      &__button-area {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: end;

      }
    }

    .MuiButton-containedPrimary {
      background-color: ${({ theme }) => theme.mainTheme.color.background};
      color: ${({ theme }) => theme.mainTheme.color.blue50};
    }

    .MuiButton-outlinedPrimary {
      ${({ theme }) => css`
        border-color: ${theme.mainTheme.color.blue30};
        color: ${theme.mainTheme.color.background};
      `}
    }

    .MuiLoadingButton-loadingIndicatorCenter {
      color: ${({ theme }) => theme.mainTheme.color.blue50};
    }

    .result-area {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      row-gap: 8px;

      .icon {
        width: 64px;
        height: 64px;
        color: ${({ theme }) => theme.mainTheme.color.blue30};
      }

      button {
        margin-top: 24px;
      }

      @media (max-width: 815px) {
        height: 308px;
      }
    }
  }

  .form-section {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &__button-area {
      width: 100%;
      display: flex;
      justify-content: end;
      column-gap: 12px;
    }
  }
  
  .MuiPaper-root {
    width: inherit;
    
  }
`;
