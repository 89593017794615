import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  .loading-section {
    height: 218px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
