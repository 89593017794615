import { useCallback, useEffect, useMemo } from 'react';
import { useLazyGetSkillsQuery, useLazyGetRegionsAndCountiesQuery } from 'api/services/filterEndpoints';
import { NameFilterENUM } from 'types/filtersType';

const useInitialList = (section: NameFilterENUM) => {
  const [triggerSkills, {
    data: skills,
    currentData: currentSkills,
    isLoading: isLoadingSkills,
  }] = useLazyGetSkillsQuery();
  const [triggerGetRegions, {
    data: regionsAndCountries,
    currentData: currentRegionsAndCountries,
    isLoading: isLoadingRegionsAndCountries,
  }] = useLazyGetRegionsAndCountiesQuery();

  const initialHandler = useCallback(() => {
    switch (section) {
      case NameFilterENUM.SKILLS:
        return triggerSkills({});
      case NameFilterENUM.REGIONS:
        return triggerGetRegions({ location: 'Regions' });
      default:
        return triggerGetRegions({ location: 'Countries' });
    }
  }, [section, triggerGetRegions, triggerSkills]);

  useEffect(() => {
    initialHandler();
  }, [initialHandler]);

  const isLoading = useMemo(() => {
    return section === NameFilterENUM.SKILLS
      ? isLoadingSkills || !currentSkills
      : isLoadingRegionsAndCountries || !currentRegionsAndCountries;
  }, [
    currentRegionsAndCountries,
    currentSkills,
    isLoadingRegionsAndCountries,
    isLoadingSkills,
    section,
  ]);

  const data = useMemo(() => {
    return section === NameFilterENUM.SKILLS
      ? skills
      : regionsAndCountries;
  }, [regionsAndCountries, section, skills]);

  return {
    data,
    isLoading,
    trigger: triggerSkills,
  };
};

export default useInitialList;
