import FilterContainer from 'components/FilterContainer';
import StyledSection from './TopSection.styles';

const TopSection:React.FC = () => {
  return (
    <StyledSection>
      <h1 className="top-section__title">Dashboard</h1>
      <FilterContainer className="header-filter" />
    </StyledSection>
  );
};

export default TopSection;
