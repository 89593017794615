class StorageItem {
  key: string;

  saveToLocal?: boolean;

  constructor(key: string) {
    this.key = key;
  }

  get() {
    try {
      if (this.saveToLocal === undefined) {
        if (localStorage.getItem(this.key) !== null) {
          this.saveToLocal = true;
          return localStorage.getItem(this.key) || '';
        }
        if (sessionStorage.getItem(this.key) !== null) {
          this.saveToLocal = false;
          return sessionStorage.getItem(this.key) || '';
        }
      }
      if (this.saveToLocal) {
        return localStorage.getItem(this.key) || '';
      }
      return sessionStorage.getItem(this.key) || '';
    } catch (error) {
      return '';
    }
  }

  set(options: { value?: string; saveToLocal?: boolean }) {
    const { value, saveToLocal = true } = options;
    if (!value) {
      return localStorage.removeItem(this.key);
    }
    if (this.saveToLocal === undefined) {
      this.saveToLocal = saveToLocal;
    }
    if (!this.saveToLocal) {
      return sessionStorage.setItem(this.key, value);
    }
    return localStorage.setItem(this.key, value);
  }

  remove() {
    localStorage.removeItem(this.key);
  }
}

const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export default {
  token: new StorageItem('token'),
  refreshToken: new StorageItem('refresh_token'),
  deviceId: new StorageItem('deviceId'),
  mainFilters: new StorageItem('mainFilters'),
  dateRangeFrom: new StorageItem('dateRangeFrom'),
  dateRangeTo: new StorageItem('dateRangeTo'),
  priceRangeFrom: new StorageItem('priceRangeFrom'),
  priceRangeTo: new StorageItem('priceRangeTo'),
  fixedBudget: new StorageItem('fixedBudget'),
  logout,
};
