import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: 100%;
  margin: auto 0;
  padding: 50px 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  h3, h4 {
    text-align: center;
  }
  .empty-state-button {
    margin-top: 24px;
  }
`;
