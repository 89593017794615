import instance from 'api';
import type { FilterDisplayType, PaginationType } from 'types/filtersType';
import type { JobType, JobListItemType } from 'types/jobTypes';
import { MethodENUM } from 'types/apiTypes/statisticApiTypes';
import { baseApi } from '../baseApiBuilder';

type JobDataType = {
  data: JobType | null;
  message: string;
  meta: unknown;
};

enum JobsApiPathENUM {
  GET_JOBS = '/api/v2/jobs',
}

export const jobsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<{ data: JobListItemType[]; meta: PaginationType }, {
      filters?: FilterDisplayType;
      pagination?: PaginationType;
      search?: string;
    }>({
      query: (body) => ({
        url: JobsApiPathENUM.GET_JOBS,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
          page: body.pagination?.page,
          perPage: body.pagination?.perPage,
          search: body.search,
        },
      }),
      providesTags: ['Jobs'],
      transformResponse: (
        response: JobListItemType[],
        meta: PaginationType,
      ) : { data: JobListItemType[]; meta: PaginationType } => {
        return { data: response, meta };
      },
    }),

  }),
  overrideExisting: false,
});

export const {
  useLazyGetJobsQuery,
} = jobsApi;

export const getOneJob = async (id: number): Promise<JobDataType> => {
  return instance.get(`${JobsApiPathENUM.GET_JOBS}/${id}`);
};
