import React from 'react';
import clsx from 'clsx';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import StyledPagination from './Pagination.styles';
import { DOTS, usePagination } from './usePagination';

type PropsType = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
} & React.PropsWithChildren;

const Pagination: React.FC<PropsType> = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const isDisplayPagination = +currentPage === 0 || paginationRange!.length < 2;

  if (isDisplayPagination) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange![paginationRange!.length - 1];

  const isFirstPage = currentPage === 1;
  const isSelectedPage = (page: number) => {
    return page === currentPage;
  };
  const isLastPage = currentPage === lastPage;

  return (
    <StyledPagination className={className ?? ''}>
      <div>
        <ul
          className="pagination-container"
        >
          {paginationRange?.length ? paginationRange!.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return <li key={index} className="pagination-item dots">...</li>;
            }

            return (
              <li
                key={index}
                className={clsx(['pagination-item', { selected: isSelectedPage(pageNumber) }])}
                onClick={() => onPageChange(pageNumber)}
              >
                <h4>
                  {pageNumber}
                </h4>
              </li>
            );
          }) : null}
        </ul>

      </div>

      <div className="pagination-container__chevrons-area">
        <div
          className={clsx(['pagination-chevron', { disabled: isFirstPage }])}
          onClick={onPrevious}
        >
          <ChevronLeftRoundedIcon />
        </div>

        <div
          className={clsx(['pagination-chevron', { disabled: isLastPage }])}
          onClick={onNext}
        >
          <ChevronRightRoundedIcon />
        </div>
      </div>
    </StyledPagination>
  );
};

export default Pagination;
