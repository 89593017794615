import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import StyledButton from './HideButton.styles';

type PropsType = {
  isVisible?: boolean;
  toggleVisible?: (value: boolean) => void;
};

const HideButton:React.FC<PropsType> = (props) => {
  const setIsVisible = () => {
    if (!props.toggleVisible) { return; }

    props.toggleVisible(!props.isVisible);
  };

  return (
    <StyledButton
      type="button"
      onClick={setIsVisible}
    >
      <span>{props.isVisible ? 'Hide all' : 'Show all'}</span>
      {props.isVisible ? (
        <VisibilityIcon className="button__svg" />
      ) : (
        <VisibilityOffIcon className="button__svg" />
      )}
    </StyledButton>
  );
};

export default HideButton;
