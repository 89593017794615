import type { FilterDisplayType } from 'types/filtersType';
import { baseApi } from 'api/baseApiBuilder';
import { MethodENUM, StatisticApiPathENUM } from '../../../types/apiTypes/statisticApiTypes';
import type { LocationType } from '../../../types/apiTypes/statisticApiTypes';

const locationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocation: builder.query<LocationType, { filters?: FilterDisplayType }>({
      query: (body) => ({
        url: StatisticApiPathENUM.GET_LOCATION,
        method: MethodENUM.GET,
        params: {
          ...body.filters,
        },
      }),
      providesTags: ['Location'],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetLocationQuery } = locationApi;
