import { Dialog } from '@mui/material';
import styled from 'styled-components';

export default styled(Dialog)<{isBorderRounded?: boolean}>`
  display: flex;
  justify-content: flex-end;

  .MuiBackdrop-root {
    background-color: #3C404380;
    backdrop-filter: blur(1px);
  }

  .MuiPaper-root {
    margin: 0;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    max-width: 45vw;
    min-width: 45vw;
    border-radius: ${(props) => (props.isBorderRounded ? '20px 0 0 20px' : '0')};

    @media (max-width: 1315px) {
      min-width: 592px;
    }
  }

  .MuiDialogContent-root {
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
`;
