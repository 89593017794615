import type { FC } from 'react';
import { useAppSelector } from 'store/hooks';
import CrownIcon from 'assets/svg/CrownIcon';

interface ICrownIcon {
  className?: string;
  color?: string;
}

const CrownIconWrapper: FC<ICrownIcon> = ({ className, color }) => {
  const userStatus = useAppSelector((state) => state.userSlice.user.status);

  const colorSelected = color || (userStatus === 'paid' ? '#569DEC' : '#C8D0D9');

  return (
    <CrownIcon className={className} color={colorSelected} />
  );
};

export default CrownIconWrapper;
