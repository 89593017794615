import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query/react';
import { baseApi } from 'api/baseApiBuilder';
import userSlice from './user/userSlice';
import filterSlice from './filter/filterSlice';
import type { UserSliceType } from './user/userSliceType';
import type { FilterSliceType } from './filter/filterSliceType';

export type StoreType = {
  userSlice: UserSliceType;
  filterSlice: FilterSliceType;
};

const store = configureStore({
  reducer: {
    userSlice,
    filterSlice,
    [baseApi.reducerPath]: baseApi.reducer,
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatchType = typeof store.dispatch;
export type RootStateType = ReturnType<typeof store.getState>;

export default store;
