import React, { useMemo } from 'react';
import type { AxiosError } from 'axios';
import { useFormik } from 'formik';

import { changeInfoSchema } from 'types/validationSchemes';
import type { ValidationErrorResponseDataType } from 'types/apiTypes/errorTypes';
import { handleError, handleValidationError } from 'utils/errorHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import userThunks from 'store/user/userThunk';
import Button from 'components/Button';
import Paper from 'components/Paper';
import Input from 'components/Input';
import StyledInfoSection from './InfoSection.styles';

const InfoSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isLoading, user } = useAppSelector((state) => state.userSlice);

  const initialValues = useMemo(() => ({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  }), [user]);

  const formik = useFormik({
    initialValues,
    validationSchema: changeInfoSchema,
    onSubmit: async (values, { setFieldError }) => {
      try {
        await dispatch(userThunks.changeInfo({ ...values, id: user.userId })).unwrap();
      } catch (err) {
        handleError(err, {
          errors: {
            400: (axiosError: AxiosError<ValidationErrorResponseDataType>) => {
              handleValidationError(setFieldError, axiosError.response!.data.data);
            },
          },
        });
      }
    },
  });

  const isChangedFields = useMemo(() => {
    return formik.values.email === user.email &&
      formik.values.firstName === user.firstName &&
      formik.values.lastName === user.lastName;
  }, [formik.values, user]);

  return (
    <StyledInfoSection>
      <Paper className="container">
        <form onSubmit={formik.handleSubmit} className="form-section">
          <Input
            label="First Name"
            nameInput="firstName"
            onChangeValue={formik.handleChange}
            value={formik.values.firstName}
            errorText={formik.errors.firstName}
            isTouched={formik.touched.firstName}
            onBlur={formik.handleBlur}
          />

          <Input
            label="Second Name"
            nameInput="lastName"
            onChangeValue={formik.handleChange}
            value={formik.values.lastName}
            errorText={formik.errors.lastName}
            isTouched={formik.touched.lastName}
            onBlur={formik.handleBlur}
          />

          <Input
            label="Email"
            nameInput="email"
            onChangeValue={formik.handleChange}
            value={formik.values.email}
            errorText={formik.errors.email}
            isTouched={formik.touched.email}
            onBlur={formik.handleBlur}
          />

          <div className="form-section__button-area">
            <Button
              variant="outlined"
              type="reset"
              onClick={() => formik.resetForm({ values: initialValues })}
              disabled={isLoading}
            >
              Reset Changes
            </Button>

            <Button
              loading={isLoading}
              variant="contained"
              disabled={!formik.isValid || isChangedFields}
              type="submit"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </Paper>
    </StyledInfoSection>
  );
};

export default InfoSection;
