import React, { useEffect, useState } from 'react';
import type { Chart, LegendItem } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';

import { handleError } from 'utils/errorHandler';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setRequestSentFilters } from 'store/filter/filterSlice';
import { appliedFiltersSelector } from 'store/filter/filterSelector';
import { PeriodENUM } from 'types/filtersType';

import Paper from 'components/Paper';
import CustomLegend from 'components/CustomLegend';
import EmptyState from 'components/EmptyState/EmptyState';
import ChartControlButtons from '../ChartControlButtons/ChartControlButtons';
import StyledSection from './TrendsSection.styles';

import { options } from './optionsLine';
import { useTrends } from './useTrends';

const buttonsArray = [
  {
    title: 'weekly',
    value: PeriodENUM.WEEK,
  },
  {
    title: 'monthly',
    value: PeriodENUM.MONTH,
  },
];

type PropsType = {
  chartColors: string[];
};

const TrendsSection: React.FC<PropsType> = ({ chartColors }) => {
  const [isLoadingSentRequest, setLoadingSentRequest] = useState(false);
  const [legendArray, setLegendArray] = useState<LegendItem[] | undefined>([]);
  const [indexButtonPressed, setIndexButtonPressed] = useState<number>(0);
  const [isChartDataVisible, setIsChartDataVisible] = useState<boolean>(true);
  const [currentChart, setCurrentChart] = useState<Chart>();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(appliedFiltersSelector);
  const isRequestSent = useAppSelector((state) => state.filterSlice.isRequestSent);

  const {
    error,
    isError,
    isLoading,
    dataset,
    currentData,
    labels,
    triggerGetTrends,
  } = useTrends(buttonsArray[indexButtonPressed].value, chartColors);

  useEffect(() => {
    if (isError) {
      handleError(error, {});
    }
  }, [error, isError]);

  useEffect(() => {
    (async () => {
      setLoadingSentRequest(!currentData);
      await triggerGetTrends(filters, buttonsArray[indexButtonPressed].value);
      setLoadingSentRequest(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexButtonPressed]);

  useEffect(() => {
    (async () => {
      if (isRequestSent) {
        setLoadingSentRequest(true);
        await triggerGetTrends(filters, buttonsArray[indexButtonPressed].value);
        dispatch(setRequestSentFilters(false));
        setLoadingSentRequest(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, indexButtonPressed, isRequestSent, triggerGetTrends]);

  // fix cartjs render bug when swith loading chart
  useEffect(() => {
    if (!isLoading && !isRequestSent) { return; }

    setCurrentChart(undefined);
  }, [isLoading, isRequestSent]);

  const legendPlugin = (chart: Chart) => {
    setCurrentChart(chart);
    const list = chart.options.plugins?.legend?.labels?.generateLabels?.(chart);
    setLegendArray(list);
  };

  const updateIndexButtonPressed = (index: number) => {
    setIndexButtonPressed(index);
  };

  const toggleChartDataVisible = () => {
    setIsChartDataVisible(!isChartDataVisible);
  };

  return (
    <Paper
      title="Trends"
      chartControlButtons={(
        <ChartControlButtons
          indexButtonArray={buttonsArray}
          indexButtonPressed={indexButtonPressed}
          isNeedHideButton
          isChartDataVisible={isChartDataVisible}
          updateIndexButtonPressed={updateIndexButtonPressed}
          toggleChartDataVisible={toggleChartDataVisible}
        />
      )}
    >
      <StyledSection>
        {isLoading || isLoadingSentRequest ? (
            <div className="loading-section">
              <CircularProgress />
            </div>
        ) : (
          <div className="graph-section">
            {dataset?.length ? (
              <>
                <div className="graph-section__graph">
                  <Line
                    options={options}
                    data={{ labels, datasets: dataset }}
                    plugins={[{
                      id: 'htmlLegend',
                      afterUpdate: legendPlugin,
                    }]}
                  />
                </div>

                <div className="graph-section__legend">
                  <CustomLegend
                    id="legend-container-trends"
                    chart={currentChart}
                    legendArray={legendArray}
                    isChartDataVisible={isChartDataVisible}
                  />
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        )}
      </StyledSection>
    </Paper>
  );
};

export default TrendsSection;
