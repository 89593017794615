/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Slider as MSlider, type SliderProps } from '@mui/material';
import StyledSlider from './Slider.styles';

const Slider: React.FC<SliderProps> = ({ ...props }) => {
  return (
    <StyledSlider>
      <div className="info-area">
        <p className="button-text">{props.min}</p>
        <p className="button-text">{props.max}</p>
      </div>
      <MSlider {...props} valueLabelDisplay="on" disableSwap />
    </StyledSlider>
  );
};

export default Slider;
