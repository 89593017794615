import type { AxiosError } from 'axios';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import type { ValidationErrorType } from 'types/apiTypes/errorTypes';

export const handleValidationError = (
  setFieldError: (field: string, message?: string) => void,
  errors: ValidationErrorType[],
) => {
  errors.forEach((error) => {
    setFieldError(error.key, error.message);
  });
};

export const handleError = (err: unknown, params: {
  defaultMessage?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: Record<number, (err: AxiosError<any>) => void>;
  onUnexpected?: (err: unknown) => void;
}) => {
  if (!isAxiosError(err)) {
    if (params.onUnexpected) {
      return params.onUnexpected(err);
    }
    return toast.error(params.defaultMessage || 'Something went wrong', {
      position: 'bottom-left',
    });
  }
  let countErrors = 0;
  if (params.errors) {
    Object.entries(params.errors).forEach(([statusCode, action]) => {
      if (err.response?.status !== +statusCode) {
        return;
      }
      countErrors++;

      action(err);
    });
  }

  if (!countErrors) {
    return toast.error(err.response?.data.message, {
      position: 'bottom-left',
    });
  }
};
